import { number, object, string } from 'idonttrustlikethat'

const score = object({
  okta_id: string,
  rank: string,
  score: number,
  state: string.nullable(),
  username: string,
  firstname: string.nullable()
})

const scoreState = object({
  id: number,
  score: number.nullable(),
  state: string.nullable()
}).nullable()

const dataScienceScore = object({
  id: number,
  attributes: score
})

const scorePost = object({
  id: number,
  attributes: object({
    state: string
  })
})

const dataScienceScorePost = object({ data: scorePost })

type DataScienceScore = typeof dataScienceScore.T
type DataScienceScorePost = typeof dataScienceScorePost.T
type Score = typeof score.T
type ScoreState = typeof scoreState.T

export { dataScienceScore, dataScienceScorePost, scoreState }

export type { DataScienceScore, DataScienceScorePost, Score, ScoreState }
