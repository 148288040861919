import './dsc-card-top-teams.scss'
import type { PodiumClasses, PodiumPositionWithIcon } from 'types/podium'
import { DSCCard } from 'components/atoms/card/dsc-card'
import DSCCardTitle from 'components/atoms/card/dsc-card-title'
import { type DataScienceScore } from 'types/datascience-score'
import type { FC } from 'react'
import { ReactComponent as StarBronzeIcon } from 'assets/icons/svg/star-bronze.svg'
import { ReactComponent as StarGoldIcon } from 'assets/icons/svg/star-gold.svg'
import { ReactComponent as StarSilverIcon } from 'assets/icons/svg/star-silver.svg'
import { ReactComponent as TrophyIcon } from 'assets/icons/svg/trophy.svg'

interface DSCCardTopTeamsProps {
  datascienceScores: (DataScienceScore | null)[] | undefined
  onReload: () => void
}

const DSCCardTopTeams: FC<DSCCardTopTeamsProps> = ({
  datascienceScores,
  onReload
}: DSCCardTopTeamsProps) => {
  const scores: (DataScienceScore | null)[] = datascienceScores ?? []

  const emptyName = '------'
  const emptyScore = 0
  const scoreboardSize = 10

  if (!datascienceScores?.length || datascienceScores.length < 10) {
    while (scores.length < 10) {
      scores.push(null)
    }
  }

  const podium: PodiumPositionWithIcon[] = scores.slice(0, 3).map((score, index) => {
    let icon = <StarGoldIcon className="icon"></StarGoldIcon>
    let positionClass: PodiumClasses = 'gold'

    switch (index) {
      case 1:
        icon = <StarSilverIcon className="icon"></StarSilverIcon>
        positionClass = 'silver'
        break
      case 2:
        icon = <StarBronzeIcon className="icon"></StarBronzeIcon>
        positionClass = 'bronze'
        break
    }

    return {
      class: positionClass,
      icon,
      score
    }
  })

  return (
    <DSCCard
      {...{
        className: 'dsc-challenge-leaderboard'
      }}
    >
      <DSCCardTitle {...{ title: 'Top Scores', onReload }}>
        <TrophyIcon className="icon"></TrophyIcon>
      </DSCCardTitle>
      <section className="podium">
        <div className="top-3">
          {podium.map((position, index) => {
            return (
              <div
                {...{
                  className: position.class
                }}
              >
                <div className="dsc-star-container">
                  {position.icon}
                  <p className="dsc-big-number">#{index + 1}</p>
                </div>
                <h3 className="dsc-squad-title">
                  {position.score?.attributes?.firstname ?? emptyName}
                </h3>
                <div className="podium-step">
                  {position.score?.attributes?.score ?? emptyScore} pt
                </div>
              </div>
            )
          })}
        </div>
        <div className="dsc-leaderboard-team">{}</div>
      </section>
      {scores.slice(3, scoreboardSize).map((score, index) => {
        return (
          <div className="scoreboard-row" key={index + 3}>
            <p className="scoreboard-position">#{index + 4}</p>
            <p className="scoreboard-name">{score?.attributes?.username ?? emptyName}</p>
            <p className="scoreboard-score">
              {score?.attributes?.score ?? emptyScore} pt
            </p>
          </div>
        )
      })}
    </DSCCard>
  )
}

export { DSCCardTopTeams }
