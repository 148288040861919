import './dsc-card-title.scss'
import type { FC, PropsWithChildren } from 'react'
import { DSCRefreshButton } from '../button/dsc-refresh-button'

interface DSCCardTitleProps {
  classname?: string
  title: string
  onReload?: () => void
}

const DSCCardTitle: FC<PropsWithChildren<DSCCardTitleProps>> = ({
  classname,
  children,
  title,
  onReload
}) => {
  return (
    <section className={`head-card ${classname}`}>
      <div className="title-container">
        {children}
        <h2 className="t-deca">{title}</h2>
      </div>

      {onReload && <DSCRefreshButton {...{ onReload }} />}
    </section>
  )
}

export default DSCCardTitle
