import './dsc-score-user.scss'
import { type Score, type ScoreState } from 'types/datascience-score'
import { DSCCard } from 'components/atoms/card/dsc-card'
import DSCCardTitle from 'components/atoms/card/dsc-card-title'
import { DSCStatusBadge } from 'components/atoms/badge/dsc-status-badge'
import { type FC } from 'react'

import { ReactComponent as StarIcon } from 'assets/icons/svg/star.svg'

interface DSCScoreUserProps {
  lastScoreState: ScoreState
  score: Score | null
  slug: string
  onReload: () => void
}

const DSCScoreUser: FC<DSCScoreUserProps> = ({
  lastScoreState,
  score,
  slug,
  onReload
}: DSCScoreUserProps) => {
  return (
    <DSCCard
      {...{
        className: 'dsc-challenge-score',
        slug
      }}
    >
      <div>
        <DSCCardTitle
          {...{
            classname: 'small-bottom',
            title: score ? `Congratulations ${score.firstname}` : 'Your Score'
          }}
        >
          <StarIcon></StarIcon>
        </DSCCardTitle>
        {score ? (
          <>
            <h1 className="t-tera">#{score.rank}</h1>
            <p className="dsc-score bold">with highest score {score.score} pt</p>
          </>
        ) : (
          <p className="bold">
            After your participation your score will be displayed here
          </p>
        )}
      </div>
      {lastScoreState && <DSCStatusBadge {...{ score: lastScoreState, onReload }} />}
    </DSCCard>
  )
}

export { DSCScoreUser }
