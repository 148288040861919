import { useContext, useEffect, useMemo, useState } from 'react'
import type { Challenge } from 'types/datascience-challenge'
import { DragCTX } from 'components/atoms/drag-context/drag-context'
import { type ScoreState } from 'types/datascience-score'
import { checkDateChallenge } from 'utils/date'
import { fullUrl } from 'utils/image'
import { useOktaUser } from 'hooks/use-okta-user'

const useDSCChallenge = (challenge: Challenge) => {
  const { user } = useOktaUser()
  const [lastScoreState, setLastScoreState] = useState<ScoreState>()
  const { isFileDragged } = useContext(DragCTX)

  const indexChallenge = 'challenges0'
  const challengeStatus = useMemo(
    () =>
      checkDateChallenge(challenge.attributes.startDate, challenge.attributes.endDate),
    [challenge.attributes.startDate, challenge.attributes.endDate]
  )

  const myScore = useMemo(
    () =>
      challenge?.attributes?.datascience_scores?.data?.filter(
        value => value?.attributes.okta_id === user?.sub
      )?.[0],
    [challenge, user?.sub]
  )

  const urlImage = useMemo(
    () =>
      challenge.attributes?.logo?.data
        ? fullUrl(challenge.attributes.logo.data?.attributes?.url)
        : '/demo.jpg',
    [challenge.attributes.logo.data]
  )

  useEffect(() => {
    setLastScoreState(challenge?.attributes.last_score_state)
  }, [challenge.attributes.last_score_state])

  return {
    challenge,
    challengeStatus,
    indexChallenge,
    isFileDragged,
    lastScoreState,
    myScore,
    setLastScoreState,
    urlImage
  }
}

export { useDSCChallenge }
