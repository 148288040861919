import type { FC, MouseEventHandler } from 'react'
import { DSCSpinner } from '../spinner/dsc-spinner'
import { ParticipationSent } from './participation-sent'

interface UploadLoadingProps {
  loadingUploadParticipation: boolean
  resetUpload: MouseEventHandler<HTMLDivElement>
  uploadSuccessfull: boolean | null
}

const UploadLoading: FC<UploadLoadingProps> = ({
  loadingUploadParticipation,
  resetUpload,
  uploadSuccessfull
}) => {
  return uploadSuccessfull === null ? (
    <div className="participation-spinner">
      <DSCSpinner
        {...{
          isLoading: loadingUploadParticipation,
          isBlock: false,
          variant: 'normal',
          size: 'md'
        }}
      ></DSCSpinner>
    </div>
  ) : (
    <ParticipationSent
      {...{
        uploadSuccessfull,
        resetUpload
      }}
    />
  )
}

export { UploadLoading }
