import { object, string } from 'idonttrustlikethat'

const dataScienceChallengeHome = object({
  data: object({
    attributes: object({
      page_title: string,
      page_content: string
    })
  })
})

type DataScienceChallengeHome = typeof dataScienceChallengeHome.T

export type { DataScienceChallengeHome }
export { dataScienceChallengeHome }
