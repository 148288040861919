export const checkDateChallenge = (startDate: Date, endDate: Date): string => {
  const currentDate = new Date()

  if (startDate <= currentDate && currentDate <= endDate) {
    return 'In Progress'
  } else if (currentDate < startDate) {
    return 'Draft'
  } else {
    return 'Archived'
  }
}
