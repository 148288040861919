import { DSCHome } from 'components/templates/home/dsc-home'
import { DataFetcher } from 'components/templates/data-fetcher/data-fetcher'
import { SecuredPage } from 'components/templates/page/secured-page'
import { dataScienceChallengeHome } from 'types/datascience-home'
import { endpoints } from 'endpoint/endpoints'
import { getApiRoute } from 'utils/api-route'

const Home = () => {
  const route = getApiRoute(endpoints.dataScienceHome)
  const params = {
    fields: '*'
  }
  const validator = dataScienceChallengeHome

  return (
    <SecuredPage>
      <DataFetcher
        {...{
          route,
          params,
          validator
        }}
      >
        {data => <DSCHome {...{ home: data }}></DSCHome>}
      </DataFetcher>
    </SecuredPage>
  )
}

export { Home }
