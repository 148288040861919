import { object, string } from 'idonttrustlikethat'

export const oktaUser = object({
  email: string,
  family_name: string,
  given_name: string,
  locale: string,
  name: string,
  sub: string
}).nullable()

export type OKTAUser = typeof oktaUser.T
