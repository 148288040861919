import { array, isoDate, number, object, string } from 'idonttrustlikethat'
import { dataScienceScore, scoreState } from './datascience-score'
import { logo } from './logo-format'

const challenge = object({
  id: number,
  attributes: object({
    Title: string,
    datascience_scores: object({ data: array(dataScienceScore) }).nullable(),
    description: string,
    endDate: isoDate,
    last_score_state: scoreState,
    logo: logo,
    metricsParameters: object({ metric_name: string }).nullable(),
    slug: string,
    startDate: isoDate
  })
})

export const challenges = object({
  data: array(challenge)
})

const listChallenge = object({
  id: number,
  attributes: object({
    Title: string,
    startDate: isoDate,
    endDate: isoDate,
    slug: string
  })
})

export const listChallenges = object({
  data: array(listChallenge)
})

export type Challenge = typeof challenge.T
export type Challenges = typeof challenges.T
export type ListChallenge = typeof listChallenge.T
export type ListChallenges = typeof listChallenges.T
