import { number, object, string } from 'idonttrustlikethat'

const imageFormat = object({
  ext: string,
  url: string,
  hash: string,
  mime: string,
  name: string,
  path: string.nullable(),
  size: number,
  width: number,
  height: number
})

export const imageFormats = object({
  large: imageFormat.optional(),
  medium: imageFormat.optional(),
  small: imageFormat.optional(),
  thumbnail: imageFormat.optional()
})
