//api endpoints
export const endpoints = {
  dataScienceChallenges: '/datascience-challenges',
  dataScienceHome: '/datascience-challenge-home',
  dataScienceScores: '/datascience-scores',
  upload: '/upload',
  users: '/users',
  authLocal: '/auth/local',
  defaultGeneratedPermissions: '/users-permissions/permissions',
  listRoles: '/users-permissions/roles'
}
