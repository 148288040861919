import { NJCard, NJCardBody } from '@engie-group/fluid-design-system-react'
import type { DataScienceChallengeHome } from 'types/datascience-home'
import type { FC } from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
interface DSCHomeProps {
  home: DataScienceChallengeHome
}

const DSCHome: FC<DSCHomeProps> = ({ home }) => {
  const { page_title: title, page_content } = home.data.attributes
  return (
    <div
      {...{
        className: 'dsc-container'
      }}
    >
      <NJCard>
        <NJCardBody {...{ title }}>
          <Markdown
            {...{
              rehypePlugins: [rehypeRaw]
            }}
          >
            {page_content}
          </Markdown>
        </NJCardBody>
      </NJCard>
    </div>
  )
}

export { DSCHome }
