import '@engie-group/fluid-design-system/lib/components/button/index.css'
import type { FC, ReactNode } from 'react'
import { NJButton } from '@engie-group/fluid-design-system-react'

interface ButtonProps {
  className?: string
  label: string
  children?: ReactNode
}

const DSCButton: FC<ButtonProps> = ({ className, label, children }: ButtonProps) => (
  <NJButton {...{ className, label }}>{children}</NJButton>
)

export { DSCButton }
