import { type FC, type PropsWithChildren, createContext, useState } from 'react'

const DragCTX = createContext({
  isFileDragged: false,
  resetDrag: () => {}
})

const DragContext: FC<PropsWithChildren> = ({ children }) => {
  const [isFileDragged, setIsFileDragged] = useState<boolean>(false)

  const resetDrag = () => setIsFileDragged(false)

  const onDragEnter = () => {
    setIsFileDragged(true)
  }

  const onDragOver = () => {
    if (!isFileDragged) {
      onDragEnter()
    }
  }

  const onDragLeave: React.DragEventHandler<HTMLDivElement> = e => {
    if (e.screenX === 0 && e.screenY === 0) {
      resetDrag()
    }
  }

  return (
    <DragCTX.Provider
      {...{
        value: {
          isFileDragged,
          resetDrag
        }
      }}
    >
      <div
        {...{
          onDragEnter,
          onDragOver,
          onDragLeave
        }}
      >
        {children}
      </div>
    </DragCTX.Provider>
  )
}

export { DragCTX, DragContext }
