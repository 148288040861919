import { appEnv } from 'build/validation'

const fullUrl = (urlImage: string | undefined) => {
  return urlImage
    ? urlImage.startsWith('http')
      ? urlImage
      : `${appEnv.backendUrl}${urlImage}`
    : ''
}

export { fullUrl }
