import type {
  ChangeEventHandler,
  DragEventHandler,
  FC,
  LegacyRef,
  MouseEventHandler
} from 'react'
import DSCCardTitle from '../card/dsc-card-title'
import { ReactComponent as UploadIcon } from 'assets/icons/svg/upload.svg'

interface UploadFileContentCardProps {
  disabled?: boolean
  handleChange: ChangeEventHandler<HTMLInputElement>
  handleDrag: DragEventHandler<HTMLDivElement>
  handleDrop: DragEventHandler<HTMLDivElement>
  inputRef: LegacyRef<HTMLInputElement>
  isFileDragged: boolean
  onButtonClick: MouseEventHandler<HTMLButtonElement>
  startDate: Date
}

const UploadFileContentCard: FC<UploadFileContentCardProps> = ({
  disabled = false,
  handleChange,
  handleDrag,
  handleDrop,
  inputRef,
  isFileDragged,
  onButtonClick,
  startDate
}) => {
  return (
    <div
      {...{
        id: 'form-file-upload',
        className: isFileDragged ? 'drop-zone' : '',
        onDragEnter: handleDrag,
        onDragLeave: handleDrag,
        onDragOver: handleDrag,
        onDrop: handleDrop
      }}
    >
      {(!isFileDragged || disabled) && (
        <DSCCardTitle {...{ title: 'Your Participation' }} />
      )}
      {disabled ? (
        <p>Participation is not enabled until {startDate.toDateString()}</p>
      ) : (
        <>
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple={true}
            onChange={handleChange}
            disabled={disabled}
          />
          {!isFileDragged && (
            <>
              <p className="big-p ">Drag & drop or</p>
              <button
                className="upload-button p-24"
                onClick={onButtonClick}
                disabled={disabled}
              >
                browse
              </button>
            </>
          )}
          {isFileDragged && (
            <>
              <UploadIcon />
              <p>Drop your file Here</p>
            </>
          )}
        </>
      )}
    </div>
  )
}

export { UploadFileContentCard }
