import { type OKTAUser, oktaUser } from 'types/okta-user'
import { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'

const useOktaUser = () => {
  const { authState, oktaAuth } = useOktaAuth()

  const [user, setUser] = useState<OKTAUser>(null)

  const getUser = async () => {
    const rawUser = await oktaAuth.getUser()
    const userValidation = oktaUser.validate(rawUser)
    if (userValidation.ok) setUser(userValidation.value)
  }

  useEffect(() => {
    if (authState?.isAuthenticated) {
      getUser()
    }
  }, [authState?.isAuthenticated])

  return { authState, user }
}

export { useOktaUser }
