import './ds-challenge.scss'
import '@engie-group/fluid-design-system/lib/components/card/index.css'
import { type Challenge } from 'types/datascience-challenge'
import { DSCCard } from 'components/atoms/card/dsc-card'
import DSCCardTitle from 'components/atoms/card/dsc-card-title'
import { DSCCardTopTeams } from 'components/organisms/card/top-teams/dsc-card-top-teams'
import { DSCFileUpload } from 'components/atoms/file-upload/dsc-file-upload'
import { DSCScoreUser } from 'components/organisms/card/user-score/dsc-score-user'
import { type FC } from 'react'
import { ReactComponent as FlagIcon } from 'assets/icons/svg/flag.svg'
import Markdown from 'react-markdown'
import { ReactComponent as ODTBackgroundIcon } from 'assets/icons/svg/ODT-transparent.svg'
import { ReactComponent as ODTwhiteIcon } from 'assets/icons/svg/ODT-white.svg'
import { ReactComponent as TableChart } from 'assets/icons/svg/table_chart.svg'
import rehypeRaw from 'rehype-raw'
import { useDSCChallenge } from './hooks/use-dsc-challenge'

interface DSChallengeProps {
  className?: string
  challenge: Challenge
  onReload: () => void
}

const DSChallenge: FC<DSChallengeProps> = ({
  className,
  challenge,
  onReload
}: DSChallengeProps) => {
  const {
    challengeStatus,
    indexChallenge,
    isFileDragged,
    lastScoreState,
    myScore,
    setLastScoreState,
    urlImage
  } = useDSCChallenge(challenge)
  return (
    <div className="row dsc-container">
      <div className="row">
        <div
          {...{
            key: indexChallenge,
            className
          }}
        >
          <DSCCard
            {...{
              className: 'dsc-header-challenge'
            }}
          >
            <ODTBackgroundIcon
              width="300"
              height="180"
              className="dsc-icon"
            ></ODTBackgroundIcon>
            <ODTwhiteIcon className="dsc-icon"></ODTwhiteIcon>

            <h1 id="dsc-title">{challenge.attributes.Title}</h1>
          </DSCCard>
          <section className="dsc-grid">
            <div className="container-left">
              <DSCCard
                {...{
                  className: 'dsc-challenge-details'
                }}
              >
                <DSCCardTitle {...{ title: 'Challenge description' }}>
                  <FlagIcon className="icon"></FlagIcon>
                </DSCCardTitle>

                <section className="dsc-challenge-description">
                  <div>
                    {<img src={urlImage} className="dsc-image-challenge" alt="" />}
                    <Markdown {...{ rehypePlugins: [rehypeRaw] }}>
                      {challenge.attributes.description}
                    </Markdown>
                  </div>
                </section>
              </DSCCard>
              <section className="dsc-challenge-user-area">
                <DSCFileUpload
                  {...{
                    className: 'box',
                    challengeId: challenge.id,
                    disabled: challengeStatus !== 'In Progress',
                    isFileDragged,
                    setLastScoreState,
                    startDate: challenge.attributes.startDate
                  }}
                ></DSCFileUpload>
                <DSCCard
                  {...{
                    className: 'dsc-challenge-metrics box',
                    fixedWidth: true
                  }}
                >
                  <DSCCardTitle {...{ title: 'Metrics Selected' }}>
                    <TableChart></TableChart>
                  </DSCCardTitle>

                  <p className="big-p ">
                    {challenge.attributes.metricsParameters?.metric_name}
                  </p>
                </DSCCard>
                <DSCScoreUser
                  {...{
                    lastScoreState,
                    score: myScore?.attributes ?? null,
                    slug: challenge.attributes.slug,
                    onReload
                  }}
                ></DSCScoreUser>
              </section>
            </div>
            <div className="container-right">
              <DSCCardTopTeams
                {...{
                  datascienceScores: challenge.attributes.datascience_scores?.data,
                  onReload
                }}
              ></DSCCardTopTeams>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export { DSChallenge }
