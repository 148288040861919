import './App.scss'
import { LoginCallback, Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Challenge } from 'pages/challenge'
import { DSCFooter } from 'components/templates/footer/dsc-footer'
import { DSCHeader } from 'components/molecules/dsc-header/dsc-header'
import { Home } from 'pages/home'
import { ListChallenges } from 'pages/list-challenge'
import type { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext'
import { appEnv } from 'build/validation'

const oktaAuth = new OktaAuth({
  issuer: appEnv.oktaIssuerUrl,
  clientId: appEnv.oktaClientId,
  redirectUri: `${window.location.origin}/${appEnv.oktaRedirectUri}`
})

const App = () => {
  const navigate = useNavigate()
  const restoreOriginalUri: RestoreOriginalUriFunction = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true })
  }

  return (
    <Security
      {...{
        oktaAuth,
        restoreOriginalUri
      }}
    >
      <DSCHeader />

      <Routes>
        <Route
          {...{
            element: <Home />,
            path: '/'
          }}
        />
        <Route
          {...{
            element: <ListChallenges />,
            path: '/datascience-challenges'
          }}
        />
        <Route
          {...{
            element: <Challenge />,
            path: '/datascience-challenge/:slug'
          }}
        />
        <Route
          {...{
            Component: LoginCallback,
            path: 'implicit/callback'
          }}
        />
      </Routes>
      <DSCFooter />
    </Security>
  )
}

export { App }
