import { type FC, type PropsWithChildren, useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'

const SecuredComponent: FC<PropsWithChildren> = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth()

  useEffect(() => {
    if (authState !== null && !authState?.isAuthenticated) {
      oktaAuth.signInWithRedirect({
        responseType: ['token', 'id_token'],
        scopes: ['openid', 'email', 'profile']
      })
    }
  }, [authState?.isAuthenticated])

  return (
    <div
      {...{
        style: {
          display: 'flex',
          flexDirection: 'column',
          flexBasis: '100%'
        }
      }}
    >
      {authState?.isAuthenticated}
      {authState === null && <p>Loading..</p>}
      {authState?.isAuthenticated === false && <p>Redirecting</p>}
      {authState?.isAuthenticated === true && children}
    </div>
  )
}

export { SecuredComponent }
