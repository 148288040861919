import '@engie-group/fluid-design-system/lib/components/card/index.css'
import './dsc-card.scss'
import type { FC, PropsWithChildren, ReactNode } from 'react'
import { NJCard } from '@engie-group/fluid-design-system-react'

interface DSCCardProps {
  className?: string
  fixedWidth?: boolean
  id?: string
  image?: ReactNode
}

const DSCCard: FC<PropsWithChildren<DSCCardProps>> = ({
  className,
  children,
  fixedWidth,
  id,
  image
}) => {
  className += ` dsc-card ${fixedWidth ? 'fixed-width' : ''}`

  return <NJCard {...{ className, id, image }}>{children}</NJCard>
}

export { DSCCard }
