import './dsc-errors.scss'
import '@engie-group/fluid-design-system/lib/components/inline-message/index.css'
import { NJInlineMessage, NJLink } from '@engie-group/fluid-design-system-react'

const DSCErrors = () => (
  <div className="dsc-error">
    <NJInlineMessage onClose={function noRefCheck() {}} variant="error">
      <span data-child-name="njInlineMessageTitle">An error has occurred</span>
      <div>
        We are sorry, the service is temporarily unavailable. If the problem persists,{' '}
        <NJLink href="mailto:agora@engie.com" variant="high_contrast">
          contact us
        </NJLink>
      </div>
    </NJInlineMessage>
  </div>
)

export { DSCErrors }
