import type { FC, ReactNode } from 'react'
import { DragContext } from 'components/atoms/drag-context/drag-context'
import { SecuredComponent } from 'components/organisms/okta/secured-component'

interface SecuredPageProps {
  children: ReactNode
}

const SecuredPage: FC<SecuredPageProps> = ({ children }) => {
  return (
    <DragContext>
      <main id="content-wrap">
        <SecuredComponent>{children}</SecuredComponent>
      </main>
    </DragContext>
  )
}

export { SecuredPage }
