import { type AppEnv, envValidator } from './envValidator'
import { errorDebugString } from 'idonttrustlikethat'

const unsafeEnv = {
  backendUrl: process.env.REACT_APP_BACKEND_URL?.endsWith('/')
    ? process.env.REACT_APP_BACKEND_URL.substring(
        0,
        process.env.REACT_APP_BACKEND_URL.length - 1
      )
    : process.env.REACT_APP_BACKEND_URL,
  env: process.env.REACT_APP_NODE_ENV,
  frontendUrl: process.env.REACT_APP_FRONT_URL,
  isProd: JSON.parse((process.env.REACT_APP_IS_PROD ?? 'false').toLowerCase()),
  oktaClientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  oktaIssuerUrl: process.env.REACT_APP_OKTA_ISSUER_URL,
  oktaRedirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI
  //token: process.env.REACT_APP_TOKEN
}

// eslint-disable-next-line no-console
const result = envValidator.validate(unsafeEnv)
if (!result.ok) {
  throw new Error(errorDebugString(result.errors))
}

export const appEnv: AppEnv = result.value
