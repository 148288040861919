import '@engie-group/fluid-design-system/lib/components/badge/index.css'
import { type FC } from 'react'
import { NJBadge } from '@engie-group/fluid-design-system-react'
interface DSCBadgeProps {
  label: string
  size: 'md' | 'lg' | undefined
  emphasis: 'bold' | 'subtle' | 'minimal'
  variant: 'danger' | 'warning' | 'success' | 'information' | 'discovery'
}

const DSCBadge: FC<DSCBadgeProps> = ({
  label,
  size,
  emphasis,
  variant
}: DSCBadgeProps) => {
  return <NJBadge {...{ label, size, emphasis, variant }}>{label}</NJBadge>
}

export { DSCBadge }
