import './dsc-status-badge.scss'
import { type FC, useEffect, useState } from 'react'
import { NJBadge } from '@engie-group/fluid-design-system-react'
import { ReactComponent as RefreshIcon } from 'assets/icons/svg/refresh.svg'
import type { ScoreState } from 'types/datascience-score'
import type { TSemanticVariants } from '@engie-group/fluid-design-system-react/lib/global'

interface DSCStatusBadgeProps {
  score: ScoreState
  onReload: () => void
}

const DSCStatusBadge: FC<DSCStatusBadgeProps> = ({ score, onReload }) => {
  const [badgeStatus, setBadgeStatus] = useState<TSemanticVariants | undefined>()
  const [badgeContent, setBadgeContent] = useState<string>('No participation submitted')

  const getStatus = () => {
    switch (score?.state) {
      case 'created':
        setBadgeStatus('information')
        setBadgeContent('Waiting for score computation')
        break
      case 'started':
        setBadgeStatus('discovery')
        setBadgeContent('Computing your score')
        break
      case 'error':
        setBadgeStatus('danger')
        setBadgeContent('An error occured while computing your score')
        break
      case 'success':
        setBadgeStatus('information')
        setBadgeContent(`Last score: ${score?.score} pt`)
        break
      default:
        setBadgeStatus(undefined)
        setBadgeContent('No participation submitted')
        break
    }
  }

  useEffect(() => {
    getStatus()
  }, [score])

  return (
    <div
      {...{
        style: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }
      }}
    >
      <p>Last participation status :</p>
      <div className="container-badge-status">
        <NJBadge {...{ variant: badgeStatus }}>{badgeContent}</NJBadge>
        <div
          title="By clicking on this button, the scores will be updated."
          className="refresh-button-container"
        >
          <RefreshIcon onClick={onReload}></RefreshIcon>
        </div>
      </div>
    </div>
  )
}

export { DSCStatusBadge }
