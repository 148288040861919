import 'components/atoms/description/dsc-description.scss'
import './dsc-file-upload.scss'
import type { Dispatch, FC, SetStateAction } from 'react'
import { DSCCard } from '../card/dsc-card'
import type { ScoreState } from 'types/datascience-score'
import { UploadFileContentCard } from './upload-file-content-card'
import { UploadLoading } from './upload-loading'
import { useDSCFileUpload } from './hooks/use-dsc-file-upload'

interface DSCFileUploadProps {
  challengeId: number
  className: string
  disabled?: boolean
  isFileDragged: boolean
  setLastScoreState: Dispatch<SetStateAction<ScoreState>>
  startDate: Date
}

const DSCFileUpload: FC<DSCFileUploadProps> = ({
  challengeId,
  className,
  disabled,
  isFileDragged,
  setLastScoreState,
  startDate
}: DSCFileUploadProps) => {
  const {
    handleChange,
    handleDrag,
    handleDrop,
    inputRef,
    onButtonClick,
    dragPosition,
    loadingUploadParticipation,
    uploadSuccessfull,
    resetUpload
  } = useDSCFileUpload(challengeId, setLastScoreState, disabled)

  return (
    <DSCCard
      {...{
        id: '',
        className: `${className} label-file-upload dsc-challenge-participation ${dragPosition} ${
          isFileDragged ? ' centered-card' : ''
        } `,
        fixedWidth: true
      }}
    >
      {loadingUploadParticipation ? (
        <UploadLoading
          {...{
            loadingUploadParticipation,
            resetUpload,
            uploadSuccessfull
          }}
        />
      ) : (
        <UploadFileContentCard
          {...{
            disabled,
            handleChange,
            handleDrag,
            handleDrop,
            inputRef,
            isFileDragged,
            onButtonClick,
            startDate
          }}
        />
      )}
    </DSCCard>
  )
}

export { DSCFileUpload }
