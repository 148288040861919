import type { FC, MouseEventHandler } from 'react'
import { ReactComponent as CrossIcon } from 'assets/icons/svg/cross.svg'
import { DSCButton } from '../button/dsc-button'
import { ReactComponent as ValidateIcon } from 'assets/icons/svg/validate-icon.svg'

interface ParticipationSentProps {
  resetUpload: MouseEventHandler<HTMLDivElement>
  uploadSuccessfull: boolean | null
}

const ParticipationSent: FC<ParticipationSentProps> = ({
  resetUpload,
  uploadSuccessfull
}) => {
  return uploadSuccessfull ? (
    <div className="validation-container">
      <ValidateIcon className="validate-icon"></ValidateIcon>
      <p>Well done !</p>
      <div onClick={resetUpload}>
        <DSCButton {...{ label: 'Reload sending area' }}></DSCButton>
      </div>
    </div>
  ) : (
    <div className="error-container">
      <CrossIcon className="error-icon"></CrossIcon>
      <p>Sorry, an error has occurred during transmission.</p>
      <div onClick={resetUpload}>
        <DSCButton {...{ label: 'Reload sending area' }}></DSCButton>
      </div>
    </div>
  )
}

export { ParticipationSent }
