import './dsc-spinner.scss'
import '@engie-group/fluid-design-system/lib/components/spinner/index.css'
import type { FC } from 'react'
import { NJSpinner } from '@engie-group/fluid-design-system-react'

interface SpinnerProps {
  isLoading: boolean
  isBlock?: boolean
  variant: 'grey' | 'inverse' | 'normal'
  size: 'sm' | 'md' | 'lg' | 'xs' | 'xxs'
  className?: string
}

const DSCSpinner: FC<SpinnerProps> = ({
  isLoading,
  isBlock,
  variant,
  size,
  className
}: SpinnerProps) => (
  <div className="dsc-spinner">
    <NJSpinner {...{ isLoading, isBlock, variant, size, className }}></NJSpinner>
  </div>
)

export { DSCSpinner }
