/**
 * Do not edit directly
 * Generated on Thu, 06 Jul 2023 14:37:57 GMT
 */

export const Tokens = {
  "color": {
    "background": {
      "primary": {
        "_": {
          "light": "#ffffff",
          "dark": "#15191d"
        },
        "hovered": {
          "light": "#ebeef1",
          "dark": "#1f262b"
        },
        "focused": {
          "light": "#ebeef1",
          "dark": "#1f262b"
        },
        "pressed": {
          "light": "#ccd4da",
          "dark": "#2e3942"
        },
        "selected": {
          "light": "#e7eefc",
          "dark": "#12263b"
        }
      },
      "secondary": {
        "-": {
          "light": "#f6f8f9",
          "dark": "#1a1f23"
        },
        "hovered": {
          "light": "#ebeef1",
          "dark": "#1f262b"
        },
        "focused": {
          "light": "#ebeef1",
          "dark": "#1f262b"
        },
        "pressed": {
          "light": "#ccd4da",
          "dark": "#2e3942"
        },
        "transparent": {
          "_": {
            "light": "rgba(30, 80, 105, 0.04)",
            "dark": "rgba(188, 225, 229, 0.03)"
          },
          "hovered": {
            "light": "rgba(5, 43, 80, 0.08)",
            "dark": "rgba(164, 211, 229, 0.07)"
          },
          "focused": {
            "light": "rgba(5, 43, 80, 0.08)",
            "dark": "rgba(164, 211, 229, 0.07)"
          },
          "pressed": {
            "light": "rgba(0, 40, 70, 0.2)",
            "dark": "rgba(168, 213, 247, 0.17)"
          }
        }
      },
      "tertiary": {
        "-": {
          "light": "#ebeef1",
          "dark": "#1f262b"
        },
        "hovered": {
          "light": "#ccd4da",
          "dark": "#2e3942"
        },
        "focused": {
          "light": "#ccd4da",
          "dark": "#2e3942"
        },
        "pressed": {
          "light": "#9eadb8",
          "dark": "#425663"
        },
        "transparent": {
          "_": {
            "light": "rgba(5, 43, 80, 0.08)",
            "dark": "rgba(164, 211, 229, 0.07)"
          },
          "hovered": {
            "light": "rgba(0, 40, 70, 0.2)",
            "dark": "rgba(168, 213, 247, 0.17)"
          },
          "focused": {
            "light": "rgba(0, 40, 70, 0.2)",
            "dark": "rgba(168, 213, 247, 0.17)"
          },
          "pressed": {
            "light": "rgba(6, 45, 73, 0.39)",
            "dark": "rgba(166, 222, 255, 0.31)"
          }
        }
      },
      "neutral": {
        "_": {
          "light": "#60798b",
          "dark": "#718492"
        },
        "hovered": {
          "light": "#435c6e",
          "dark": "#97a5af"
        },
        "focused": {
          "light": "#435c6e",
          "dark": "#97a5af"
        },
        "pressed": {
          "light": "#334551",
          "dark": "#bcc4cb"
        }
      },
      "transparent": {
        "_": {
          "light": "transparent",
          "dark": "transparent"
        },
        "hovered": {
          "light": "rgba(5, 43, 80, 0.08)",
          "dark": "rgba(164, 211, 229, 0.07)"
        },
        "focused": {
          "light": "rgba(5, 43, 80, 0.08)",
          "dark": "rgba(164, 211, 229, 0.07)"
        },
        "pressed": {
          "light": "rgba(0, 40, 70, 0.2)",
          "dark": "rgba(168, 213, 247, 0.17)"
        },
        "selected": {
          "light": "rgba(15, 85, 225, 0.1)",
          "dark": "rgba(1, 112, 229, 0.15)"
        }
      },
      "inverse": {
        "_": {
          "light": "#ffffff",
          "dark": "#15191d"
        },
        "hovered": {
          "light": "#ebeef1",
          "dark": "#1f262b"
        },
        "focused": {
          "light": "#ebeef1",
          "dark": "#1f262b"
        },
        "pressed": {
          "light": "#ccd4da",
          "dark": "#2e3942"
        },
        "transparent": {
          "_": {
            "light": "transparent",
            "dark": "transparent"
          },
          "hovered": {
            "light": "rgba(168, 213, 247, 0.17)",
            "dark": "rgba(0, 40, 70, 0.2)"
          },
          "focused": {
            "light": "rgba(168, 213, 247, 0.17)",
            "dark": "rgba(0, 40, 70, 0.2)"
          },
          "pressed": {
            "light": "rgba(166, 222, 255, 0.31)",
            "dark": "rgba(6, 45, 73, 0.39)"
          }
        },
        "contrast": {
          "_": {
            "light": "rgba(164, 211, 229, 0.07)",
            "dark": "rgba(5, 43, 80, 0.08)"
          }
        }
      },
      "high_contrast": {
        "_": {
          "light": "#232d35",
          "dark": "#e2e5e9"
        }
      },
      "backdrop": {
        "_": {
          "light": "rgba(3, 42, 70, 0.54)",
          "dark": "rgba(168, 213, 247, 0.17)"
        }
      },
      "brand": {
        "_": {
          "light": "#007acd",
          "dark": "#3d85ca"
        },
        "secondary": {
          "light": "#f6f8fd",
          "dark": "#111f2e"
        },
        "tertiary": {
          "-": {
            "light": "#e7eefc",
            "dark": "#12263b"
          },
          "hovered": {
            "light": "#c0d4f9",
            "dark": "#0f3a5e"
          },
          "focused": {
            "light": "#c0d4f9",
            "dark": "#0f3a5e"
          },
          "pressed": {
            "light": "#7fadef",
            "dark": "#04568e"
          },
          "transparent": {
            "_": {
              "light": "rgba(15, 85, 225, 0.1)",
              "dark": "rgba(1, 112, 229, 0.15)"
            },
            "hovered": {
              "light": "rgba(3, 83, 231, 0.25)",
              "dark": "rgba(0, 139, 253, 0.29)"
            },
            "focused": {
              "light": "rgba(3, 83, 231, 0.25)",
              "dark": "rgba(0, 139, 253, 0.29)"
            },
            "pressed": {
              "light": "rgba(3, 83, 231, 0.25)",
              "dark": "rgba(0, 139, 253, 0.29)"
            }
          }
        },
        "hovered": {
          "light": "#005aa0",
          "dark": "#77a6e1"
        },
        "focused": {
          "light": "#005aa0",
          "dark": "#77a6e1"
        },
        "pressed": {
          "light": "#004478",
          "dark": "#a9c4ee"
        },
        "transparent": {
          "_": {
            "light": "transparent",
            "dark": "transparent"
          },
          "hovered": {
            "light": "rgba(15, 85, 225, 0.1)",
            "dark": "rgba(1, 112, 229, 0.15)"
          },
          "focused": {
            "light": "rgba(15, 85, 225, 0.1)",
            "dark": "rgba(1, 112, 229, 0.15)"
          },
          "pressed": {
            "light": "rgba(3, 83, 231, 0.25)",
            "dark": "rgba(0, 139, 253, 0.29)"
          }
        },
        "high_contrast": {
          "_": {
            "light": "#0b2d4e",
            "dark": "#dbe5f8"
          }
        }
      },
      "danger": {
        "_": {
          "light": "#db3735",
          "dark": "#dd534b"
        },
        "secondary": {
          "light": "#fff7f5",
          "dark": "#321613"
        },
        "tertiary": {
          "-": {
            "light": "#ffeae5",
            "dark": "#411a16"
          },
          "hovered": {
            "light": "#ffc5b9",
            "dark": "#63221e"
          },
          "focused": {
            "light": "#ffc5b9",
            "dark": "#63221e"
          },
          "pressed": {
            "light": "#ff8979",
            "dark": "#95302c"
          },
          "transparent": {
            "light": "rgba(255, 64, 19, 0.11)",
            "dark": "rgba(197, 29, 1, 0.25)"
          }
        },
        "hovered": {
          "light": "#aa2424",
          "dark": "#fa7e72"
        },
        "pressed": {
          "light": "#7f1d1b",
          "dark": "#ffaea3"
        },
        "focused": {
          "light": "#aa2424",
          "dark": "#fa7e72"
        },
        "transparent": {
          "_": {
            "light": "transparent",
            "dark": "transparent"
          },
          "hovered": {
            "light": "rgba(255, 64, 19, 0.11)",
            "dark": "rgba(197, 29, 1, 0.25)"
          },
          "focused": {
            "light": "rgba(255, 64, 19, 0.11)",
            "dark": "rgba(197, 29, 1, 0.25)"
          },
          "pressed": {
            "light": "rgba(255, 48, 5, 0.28)",
            "dark": "rgba(250, 51, 32, 0.34)"
          },
          "selected": {
            "light": "rgba(255, 64, 19, 0.11)",
            "dark": "rgba(197, 29, 1, 0.25)"
          }
        }
      },
      "success": {
        "_": {
          "light": "#008836",
          "dark": "#30934b"
        },
        "secondary": {
          "light": "#f3faf4",
          "dark": "#102313"
        },
        "tertiary": {
          "-": {
            "light": "#e2f3e3",
            "dark": "#112b16"
          },
          "hovered": {
            "light": "#aedfb3",
            "dark": "#12401e"
          },
          "focused": {
            "light": "#aedfb3",
            "dark": "#12401e"
          },
          "pressed": {
            "light": "#63be74",
            "dark": "#12602b"
          },
          "transparent": {
            "light": "rgba(13, 155, 22, 0.12)",
            "dark": "rgba(5, 97, 1, 0.25)"
          }
        },
        "hovered": {
          "light": "#006725",
          "dark": "#63b473"
        },
        "pressed": {
          "light": "#004d1c",
          "dark": "#99d0a0"
        },
        "transparent": {
          "_": {
            "light": "transparent",
            "dark": "transparent"
          },
          "hovered": {
            "light": "rgba(13, 155, 22, 0.12)",
            "dark": "rgba(5, 97, 1, 0.25)"
          },
          "focused": {
            "light": "rgba(13, 155, 22, 0.12)",
            "dark": "rgba(5, 97, 1, 0.25)"
          },
          "pressed": {
            "light": "rgba(2, 155, 18, 0.32)",
            "dark": "rgba(3, 254, 35, 0.17)"
          },
          "selected": {
            "light": "rgba(13, 155, 22, 0.12)",
            "dark": "rgba(5, 97, 1, 0.25)"
          }
        }
      },
      "warning": {
        "_": {
          "light": "#ff8c47",
          "dark": "#c3693a"
        },
        "secondary": {
          "light": "#fff5ed",
          "dark": "#2c1b12"
        },
        "tertiary": {
          "-": {
            "light": "#ffeada",
            "dark": "#372015"
          },
          "hovered": {
            "light": "#ffc89d",
            "dark": "#542f1b"
          },
          "focused": {
            "light": "#ffc89d",
            "dark": "#542f1b"
          },
          "pressed": {
            "light": "#ff8c47",
            "dark": "#7e4425"
          },
          "transparent": {
            "light": "rgba(255, 115, 8, 0.15)",
            "dark": "rgba(142, 50, 0, 0.28)"
          }
        },
        "hovered": {
          "light": "#d96929",
          "dark": "#f08750"
        },
        "pressed": {
          "light": "#bc5921",
          "dark": "#ffb182"
        },
        "transparent": {
          "_": {
            "light": "transparent",
            "dark": "transparent"
          },
          "hovered": {
            "light": "rgba(255, 115, 8, 0.15)",
            "dark": "rgba(142, 50, 0, 0.28)"
          },
          "focused": {
            "light": "rgba(255, 115, 8, 0.15)",
            "dark": "rgba(142, 50, 0, 0.28)"
          },
          "pressed": {
            "light": "rgba(255, 114, 4, 0.39)",
            "dark": "rgba(254, 106, 22, 0.27)"
          },
          "selected": {
            "light": "rgba(255, 115, 8, 0.15)",
            "dark": "rgba(142, 50, 0, 0.28)"
          }
        }
      },
      "discovery": {
        "_": {
          "light": "#9261b7",
          "dark": "#9971b8"
        },
        "secondary": {
          "light": "#faf7fc",
          "dark": "#201d22"
        },
        "tertiary": {
          "-": {
            "light": "#f2ecf7",
            "dark": "#28232c"
          },
          "hovered": {
            "light": "#deccec",
            "dark": "#41324e"
          },
          "focused": {
            "light": "#deccec",
            "dark": "#41324e"
          },
          "pressed": {
            "light": "#bf9ed9",
            "dark": "#694288"
          },
          "transparent": {
            "light": "rgba(93, 18, 155, 0.08)",
            "dark": "rgba(232, 136, 196, 0.09)"
          }
        },
        "hovered": {
          "light": "#a377c5",
          "dark": "#b697cf"
        },
        "pressed": {
          "light": "#9261b7",
          "dark": "#d0bbe1"
        },
        "transparent": {
          "_": {
            "light": "transparent",
            "dark": "transparent"
          },
          "hovered": {
            "light": "rgba(93, 18, 155, 0.08)",
            "dark": "rgba(232, 136, 196, 0.09)"
          },
          "focused": {
            "light": "rgba(93, 18, 155, 0.08)",
            "dark": "rgba(232, 136, 196, 0.09)"
          },
          "pressed": {
            "light": "rgba(90, 0, 160, 0.20)",
            "dark": "rgba(221, 139, 252, 0.22)"
          },
          "selected": {
            "light": "rgba(93, 18, 155, 0.08)",
            "dark": "rgba(232, 136, 196, 0.09)"
          }
        }
      },
      "information": {
        "_": {
          "light": "#007acd",
          "dark": "#3d85ca"
        },
        "secondary": {
          "light": "#f6f8fd",
          "dark": "#111f2e"
        },
        "tertiary": {
          "-": {
            "light": "#e7eefc",
            "dark": "#12263b"
          },
          "hovered": {
            "light": "#c0d4f9",
            "dark": "#0f3a5e"
          },
          "focused": {
            "light": "#c0d4f9",
            "dark": "#0f3a5e"
          },
          "pressed": {
            "light": "#7fadef",
            "dark": "#04568e"
          },
          "transparent": {
            "light": "rgba(15, 85, 225, 0.1)",
            "dark": "rgba(1, 112, 229, 0.15)"
          }
        },
        "hovered": {
          "light": "#3c8cde",
          "dark": "#77a6e1"
        },
        "pressed": {
          "light": "#007acd",
          "dark": "#a9c4ee"
        },
        "transparent": {
          "_": {
            "light": "transparent",
            "dark": "transparent"
          },
          "hovered": {
            "light": "rgba(15, 85, 225, 0.1)",
            "dark": "rgba(1, 112, 229, 0.15)"
          },
          "focused": {
            "light": "rgba(15, 85, 225, 0.1)",
            "dark": "rgba(1, 112, 229, 0.15)"
          },
          "pressed": {
            "light": "rgba(3, 83, 231, 0.25)",
            "dark": "rgba(0, 139, 253, 0.29)"
          },
          "selected": {
            "light": "rgba(15, 85, 225, 0.1)",
            "dark": "rgba(1, 112, 229, 0.15)"
          }
        }
      },
      "pink": {
        "_": {
          "light": "#e01b78",
          "dark": "#e44284"
        },
        "secondary": {
          "light": "#fff4f9",
          "dark": "#211d1e"
        },
        "tertiary": {
          "-": {
            "light": "#ffe9f3",
            "dark": "#2a2326"
          },
          "hovered": {
            "light": "#ffc1df",
            "dark": "#45333a"
          },
          "focused": {
            "light": "#ffc1df",
            "dark": "#45333a"
          },
          "pressed": {
            "light": "#ff80bd",
            "dark": "#7b4159"
          }
        },
        "hovered": {
          "light": "#963563",
          "dark": "#f779ad"
        },
        "pressed": {
          "light": "#5c3749",
          "dark": "#fbaccc"
        }
      },
      "red": {
        "_": {
          "light": "#db3735",
          "dark": "#dd534b"
        },
        "secondary": {
          "light": "#fff7f5",
          "dark": "#321613"
        },
        "tertiary": {
          "-": {
            "light": "#ffeae5",
            "dark": "#411a16"
          },
          "hovered": {
            "light": "#ffc5b9",
            "dark": "#63221e"
          },
          "focused": {
            "light": "#ffc5b9",
            "dark": "#63221e"
          },
          "pressed": {
            "light": "#ff8979",
            "dark": "#95302c"
          }
        },
        "hovered": {
          "light": "#aa2424",
          "dark": "#fa7e72"
        },
        "pressed": {
          "light": "#7f1d1b",
          "dark": "#ffaea3"
        }
      },
      "orange": {
        "_": {
          "light": "#bc5921",
          "dark": "#c3693a"
        },
        "secondary": {
          "light": "#fff5ed",
          "dark": "#2c1b12"
        },
        "tertiary": {
          "-": {
            "light": "#ffeada",
            "dark": "#372015"
          },
          "hovered": {
            "light": "#ffc89d",
            "dark": "#542f1b"
          },
          "focused": {
            "light": "#ffc89d",
            "dark": "#542f1b"
          },
          "pressed": {
            "light": "#ff8c47",
            "dark": "#7e4425"
          }
        },
        "hovered": {
          "light": "#8e441a",
          "dark": "#f08750"
        },
        "pressed": {
          "light": "#693315",
          "dark": "#ffb182"
        }
      },
      "yellow": {
        "_": {
          "light": "#ffcc59",
          "dark": "#c59d4a"
        },
        "secondary": {
          "light": "#fff7e0",
          "dark": "#251e13"
        },
        "tertiary": {
          "-": {
            "light": "#ffeeb4",
            "dark": "#2c2416"
          },
          "hovered": {
            "light": "#ffcc59",
            "dark": "#43361d"
          },
          "focused": {
            "light": "#ffcc59",
            "dark": "#43361d"
          },
          "pressed": {
            "light": "#d5a334",
            "dark": "#655027"
          }
        },
        "hovered": {
          "light": "#6f551d",
          "dark": "#c59d4a"
        },
        "pressed": {
          "light": "#533f18",
          "dark": "#eabc5f"
        }
      },
      "green": {
        "_": {
          "light": "#008836",
          "dark": "#30934b"
        },
        "secondary": {
          "light": "#f3faf4",
          "dark": "#102313"
        },
        "tertiary": {
          "-": {
            "light": "#e2f3e3",
            "dark": "#112b16"
          },
          "hovered": {
            "light": "#aedfb3",
            "dark": "#12401e"
          },
          "focused": {
            "light": "#aedfb3",
            "dark": "#12401e"
          },
          "pressed": {
            "light": "#63be74",
            "dark": "#12602b"
          }
        },
        "hovered": {
          "light": "#006725",
          "dark": "#63b473"
        },
        "pressed": {
          "light": "#004d1c",
          "dark": "#99d0a0"
        }
      },
      "grey": {
        "_": {
          "light": "#60798b",
          "dark": "#718492"
        },
        "secondary": {
          "light": "#f6f8f9",
          "dark": "#1a1f23"
        },
        "tertiary": {
          "-": {
            "light": "#ebeef1",
            "dark": "#1f262b"
          },
          "hovered": {
            "light": "#ccd4da",
            "dark": "#2e3942"
          },
          "focused": {
            "light": "#ccd4da",
            "dark": "#2e3942"
          },
          "pressed": {
            "light": "#9eadb8",
            "dark": "#425663"
          }
        },
        "hovered": {
          "light": "#435c6e",
          "dark": "#97a5af"
        },
        "pressed": {
          "light": "#334551",
          "dark": "#bcc4cb"
        }
      },
      "blue": {
        "_": {
          "light": "#007acd",
          "dark": "#3d85ca"
        },
        "secondary": {
          "light": "#f6f8fd",
          "dark": "#111f2e"
        },
        "tertiary": {
          "-": {
            "light": "#e7eefc",
            "dark": "#12263b"
          },
          "hovered": {
            "light": "#c0d4f9",
            "dark": "#0f3a5e"
          },
          "focused": {
            "light": "#c0d4f9",
            "dark": "#0f3a5e"
          },
          "pressed": {
            "light": "#7fadef",
            "dark": "#04568e"
          }
        },
        "hovered": {
          "light": "#005aa0",
          "dark": "#77a6e1"
        },
        "pressed": {
          "light": "#004478",
          "dark": "#a9c4ee"
        }
      },
      "ultramarine": {
        "_": {
          "light": "#6372b2",
          "dark": "#717fb5"
        },
        "secondary": {
          "light": "#f6f7fb",
          "dark": "#171d37"
        },
        "tertiary": {
          "-": {
            "light": "#eceef7",
            "dark": "#17224b"
          },
          "hovered": {
            "light": "#ccd1e9",
            "dark": "#25346e"
          },
          "focused": {
            "light": "#ccd1e9",
            "dark": "#25346e"
          },
          "pressed": {
            "light": "#9fa9d4",
            "dark": "#3f508e"
          }
        },
        "hovered": {
          "light": "#44549b",
          "dark": "#96a2cc"
        },
        "pressed": {
          "light": "#2b3d83",
          "dark": "#bbc2df"
        }
      },
      "purple": {
        "_": {
          "light": "#9261b7",
          "dark": "#9971b8"
        },
        "secondary": {
          "light": "#faf7fc",
          "dark": "#201d22"
        },
        "tertiary": {
          "-": {
            "light": "#f2ecf7",
            "dark": "#28232c"
          },
          "hovered": {
            "light": "#deccec",
            "dark": "#41324e"
          },
          "focused": {
            "light": "#deccec",
            "dark": "#41324e"
          },
          "pressed": {
            "light": "#bf9ed9",
            "dark": "#694288"
          }
        },
        "hovered": {
          "light": "#744299",
          "dark": "#b697cf"
        },
        "pressed": {
          "light": "#533669",
          "dark": "#d0bbe1"
        }
      },
      "teal": {
        "_": {
          "light": "#008480",
          "dark": "#328e8a"
        },
        "secondary": {
          "light": "#f1fafa",
          "dark": "#191f1f"
        },
        "tertiary": {
          "-": {
            "light": "#daf4f3",
            "dark": "#1d2727"
          },
          "hovered": {
            "light": "#95dfdd",
            "dark": "#243c3b"
          },
          "focused": {
            "light": "#95dfdd",
            "dark": "#243c3b"
          },
          "pressed": {
            "light": "#3dbcb8",
            "dark": "#275b59"
          }
        },
        "hovered": {
          "light": "#0a6361",
          "dark": "#58b1ad"
        },
        "pressed": {
          "light": "#154948",
          "dark": "#8bcfcb"
        }
      },
      "lime": {
        "_": {
          "light": "#6c7d02",
          "dark": "#78892a"
        },
        "secondary": {
          "light": "#f8fad5",
          "dark": "#1e2010"
        },
        "tertiary": {
          "-": {
            "light": "#eef5a8",
            "dark": "#242713"
          },
          "hovered": {
            "light": "#c7de43",
            "dark": "#353b18"
          },
          "focused": {
            "light": "#c7de43",
            "dark": "#353b18"
          },
          "pressed": {
            "light": "#9db603",
            "dark": "#4e581e"
          }
        },
        "hovered": {
          "light": "#525f0a",
          "dark": "#97ac37"
        },
        "pressed": {
          "light": "#3e460e",
          "dark": "#b7cd4f"
        }
      }
    },
    "base": {
      "brand": {
        "100": {
          "light": "#f6f8fd",
          "dark": "#111f2e"
        },
        "200": {
          "light": "#e7eefc",
          "dark": "#12263b"
        },
        "300": {
          "light": "#c0d4f9",
          "dark": "#0f3a5e"
        },
        "400": {
          "light": "#7fadef",
          "dark": "#04568e"
        },
        "500": {
          "light": "#3c8cde",
          "dark": "#1c73b6"
        },
        "600": {
          "light": "#007acd",
          "dark": "#3d85ca"
        },
        "700": {
          "light": "#005aa0",
          "dark": "#77a6e1"
        },
        "800": {
          "light": "#004478",
          "dark": "#a9c4ee"
        },
        "900": {
          "light": "#0b2d4e",
          "dark": "#dbe5f8"
        },
        "alpha": {
          "0": {
            "light": "transparent",
            "dark": "transparent"
          },
          "100": {
            "light": "rgba(30, 80, 205, 0.04)",
            "dark": "rgba(1, 55, 114, 0.2)"
          },
          "200": {
            "light": "rgba(15, 85, 225, 0.1)",
            "dark": "rgba(1, 112, 229, 0.15)"
          },
          "300": {
            "light": "rgba(3, 83, 231, 0.25)",
            "dark": "rgba(0, 139, 253, 0.29)"
          }
        }
      },
      "neutral": {
        "0": {
          "light": "#ffffff",
          "dark": "#15191d"
        },
        "100": {
          "light": "#f6f8f9",
          "dark": "#1a1f23"
        },
        "200": {
          "light": "#ebeef1",
          "dark": "#1f262b"
        },
        "300": {
          "light": "#ccd4da",
          "dark": "#2e3942"
        },
        "400": {
          "light": "#9eadb8",
          "dark": "#425663"
        },
        "500": {
          "light": "#778c9b",
          "dark": "#5c7181"
        },
        "600": {
          "light": "#60798b",
          "dark": "#718492"
        },
        "700": {
          "light": "#435c6e",
          "dark": "#97a5af"
        },
        "800": {
          "light": "#334551",
          "dark": "#bcc4cb"
        },
        "900": {
          "light": "#232d35",
          "dark": "#e2e5e9"
        },
        "1000": {
          "light": "#171d21",
          "dark": "#fafafb"
        },
        "alpha": {
          "0": {
            "light": "transparent",
            "dark": "transparent"
          },
          "100": {
            "light": "rgba(30, 80, 105, 0.04)",
            "dark": "rgba(188, 225, 229, 0.03)"
          },
          "200": {
            "light": "rgba(5, 43, 80, 0.08)",
            "dark": "rgba(164, 211, 229, 0.07)"
          },
          "300": {
            "light": "rgba(0, 40, 70, 0.2)",
            "dark": "rgba(168, 213, 247, 0.17)"
          },
          "400": {
            "light": "rgba(6, 45, 73, 0.39)",
            "dark": "rgba(166, 222, 255, 0.31)"
          },
          "500": {
            "light": "rgba(3, 42, 70, 0.54)",
            "dark": "rgba(179, 221, 251, 0.45)"
          }
        }
      },
      "danger": {
        "100": {
          "light": "#fff7f5",
          "dark": "#321613"
        },
        "200": {
          "light": "#ffeae5",
          "dark": "#411a16"
        },
        "300": {
          "light": "#ffc5b9",
          "dark": "#63221e"
        },
        "400": {
          "light": "#ff8979",
          "dark": "#95302c"
        },
        "500": {
          "light": "#f34e46",
          "dark": "#c3423c"
        },
        "600": {
          "light": "#db3735",
          "dark": "#dd534b"
        },
        "700": {
          "light": "#aa2424",
          "dark": "#fa7e72"
        },
        "800": {
          "light": "#7f1d1b",
          "dark": "#ffaea3"
        },
        "900": {
          "light": "#521814",
          "dark": "#ffddd7"
        },
        "alpha": {
          "0": {
            "light": "transparent",
            "dark": "transparent"
          },
          "100": {
            "light": "rgba(255, 55, 5, 0.04)",
            "dark": "rgba(104, 16, 0, 0.35)"
          },
          "200": {
            "light": "rgba(255, 64, 19, 0.11)",
            "dark": "rgba(197, 29, 1, 0.25)"
          },
          "300": {
            "light": "rgba(255, 48, 5, 0.28)",
            "dark": "rgba(250, 51, 32, 0.34)"
          }
        }
      },
      "success": {
        "100": {
          "light": "#f3faf4",
          "dark": "#102313"
        },
        "200": {
          "light": "#e2f3e3",
          "dark": "#112b16"
        },
        "300": {
          "light": "#aedfb3",
          "dark": "#12401e"
        },
        "400": {
          "light": "#63be74",
          "dark": "#12602b"
        },
        "500": {
          "light": "#199d47",
          "dark": "#1d7f3c"
        },
        "600": {
          "light": "#008836",
          "dark": "#30934b"
        },
        "700": {
          "light": "#006725",
          "dark": "#63b473"
        },
        "800": {
          "light": "#004d1c",
          "dark": "#99d0a0"
        },
        "900": {
          "light": "#0a3315",
          "dark": "#d4ebd6"
        },
        "alpha": {
          "0": {
            "light": "transparent",
            "dark": "transparent"
          },
          "100": {
            "light": "rgba(35, 155, 35, 0.05)",
            "dark": "rgba(7, 54, 0, 0.35)"
          },
          "200": {
            "light": "rgba(13, 155, 22, 0.12)",
            "dark": "rgba(5, 97, 1, 0.25)"
          },
          "300": {
            "light": "rgba(2, 155, 18, 0.32)",
            "dark": "rgba(3, 254, 35, 0.17)"
          }
        }
      },
      "warning": {
        "100": {
          "light": "#fff5ed",
          "dark": "#2c1b12"
        },
        "200": {
          "light": "#ffeada",
          "dark": "#372015"
        },
        "300": {
          "light": "#ffc89d",
          "dark": "#542f1b"
        },
        "400": {
          "light": "#ff8c47",
          "dark": "#7e4425"
        },
        "500": {
          "light": "#d96929",
          "dark": "#a85a31"
        },
        "600": {
          "light": "#bc5921",
          "dark": "#c3693a"
        },
        "700": {
          "light": "#8e441a",
          "dark": "#f08750"
        },
        "800": {
          "light": "#693315",
          "dark": "#ffb182"
        },
        "900": {
          "light": "#442312",
          "dark": "#ffdfc8"
        },
        "alpha": {
          "0": {
            "light": "transparent",
            "dark": "transparent"
          },
          "100": {
            "light": "rgba(255, 130, 30, 0.08)",
            "dark": "rgba(82, 30, 0, 0.38)"
          },
          "200": {
            "light": "rgba(255, 115, 8, 0.15)",
            "dark": "rgba(142, 50, 0, 0.28)"
          },
          "300": {
            "light": "rgba(255, 114, 4, 0.39)",
            "dark": "rgba(254, 106, 22, 0.27)"
          }
        }
      },
      "discovery": {
        "100": {
          "light": "#faf7fc",
          "dark": "#201d22"
        },
        "200": {
          "light": "#f2ecf7",
          "dark": "#28232c"
        },
        "300": {
          "light": "#deccec",
          "dark": "#41324e"
        },
        "400": {
          "light": "#bf9ed9",
          "dark": "#694288"
        },
        "500": {
          "light": "#a377c5",
          "dark": "#875da9"
        },
        "600": {
          "light": "#9261b7",
          "dark": "#9971b8"
        },
        "700": {
          "light": "#744299",
          "dark": "#b697cf"
        },
        "800": {
          "light": "#533669",
          "dark": "#d0bbe1"
        },
        "900": {
          "light": "#312838",
          "dark": "#ebe1f2"
        },
        "alpha": {
          "0": {
            "light": "transparent",
            "dark": "transparent"
          },
          "100": {
            "light": "rgba(130, 55, 180, 0.04)",
            "dark": "rgba(241, 105, 129, 0.05)"
          },
          "200": {
            "light": "rgba(93, 18, 155, 0.08)",
            "dark": "rgba(232, 136, 196, 0.09)"
          },
          "300": {
            "light": "rgba(90, 0, 160, 0.20)",
            "dark": "rgba(221, 139, 252, 0.22)"
          }
        }
      },
      "information": {
        "100": {
          "light": "#f6f8fd",
          "dark": "#111f2e"
        },
        "200": {
          "light": "#e7eefc",
          "dark": "#12263b"
        },
        "300": {
          "light": "#c0d4f9",
          "dark": "#0f3a5e"
        },
        "400": {
          "light": "#7fadef",
          "dark": "#04568e"
        },
        "500": {
          "light": "#3c8cde",
          "dark": "#1c73b6"
        },
        "600": {
          "light": "#007acd",
          "dark": "#3d85ca"
        },
        "700": {
          "light": "#005aa0",
          "dark": "#77a6e1"
        },
        "800": {
          "light": "#004478",
          "dark": "#a9c4ee"
        },
        "900": {
          "light": "#0b2d4e",
          "dark": "#dbe5f8"
        },
        "alpha": {
          "0": {
            "light": "transparent",
            "dark": "transparent"
          },
          "100": {
            "light": "rgba(30, 80, 205, 0.04)",
            "dark": "rgba(1, 55, 114, 0.2)"
          },
          "200": {
            "light": "rgba(15, 85, 225, 0.1)",
            "dark": "rgba(1, 112, 229, 0.15)"
          },
          "300": {
            "light": "rgba(3, 83, 231, 0.25)",
            "dark": "rgba(0, 139, 253, 0.29)"
          }
        }
      },
      "gradient": {
        "light": "linear-gradient(90deg, #00AAFF 0%, #23d2b5 100%)",
        "dark": "linear-gradient(90deg, #00AAFF 0%, #23d2b5 100%)"
      }
    },
    "border": {
      "minimal": {
        "light": "#ebeef1",
        "dark": "#1f262b"
      },
      "subtle": {
        "_": {
          "light": "#ccd4da",
          "dark": "#2e3942"
        },
        "transparent": {
          "light": "rgba(0, 40, 70, 0.2)",
          "dark": "rgba(168, 213, 247, 0.17)"
        }
      },
      "moderate": {
        "light": "#9eadb8",
        "dark": "#425663"
      },
      "bold": {
        "light": "#60798b",
        "dark": "#718492"
      },
      "strong": {
        "light": "#435c6e",
        "dark": "#97a5af"
      },
      "contrast": {
        "light": "#232d35",
        "dark": "#e2e5e9"
      },
      "hovered": {
        "light": "#007acd",
        "dark": "#3d85ca"
      },
      "focused": {
        "_": {
          "light": "#007acd",
          "dark": "#3d85ca"
        }
      },
      "brand": {
        "moderate": {
          "light": "#7fadef",
          "dark": "#04568e"
        },
        "bold": {
          "light": "#007acd",
          "dark": "#3d85ca"
        },
        "strong": {
          "light": "#005aa0",
          "dark": "#77a6e1"
        }
      },
      "inverse": {
        "_": {
          "light": "#ffffff",
          "dark": "#15191d"
        },
        "focused": {
          "light": "#ffffff",
          "dark": "#15191d"
        }
      },
      "danger": {
        "subtle": {
          "light": "#ffc5b9",
          "dark": "#63221e"
        },
        "moderate": {
          "light": "#ff8979",
          "dark": "#95302c"
        },
        "bold": {
          "light": "#db3735",
          "dark": "#dd534b"
        },
        "strong": {
          "light": "#aa2424",
          "dark": "#fa7e72"
        }
      },
      "warning": {
        "subtle": {
          "light": "#ffc89d",
          "dark": "#542f1b"
        },
        "moderate": {
          "light": "#ff8c47",
          "dark": "#7e4425"
        },
        "bold": {
          "light": "#bc5921",
          "dark": "#c3693a"
        },
        "strong": {
          "light": "#8e441a",
          "dark": "#f08750"
        }
      },
      "success": {
        "subtle": {
          "light": "#aedfb3",
          "dark": "#12401e"
        },
        "moderate": {
          "light": "#63be74",
          "dark": "#12602b"
        },
        "bold": {
          "light": "#008836",
          "dark": "#30934b"
        },
        "strong": {
          "light": "#006725",
          "dark": "#63b473"
        }
      },
      "discovery": {
        "subtle": {
          "light": "#deccec",
          "dark": "#41324e"
        },
        "moderate": {
          "light": "#bf9ed9",
          "dark": "#694288"
        },
        "bold": {
          "light": "#9261b7",
          "dark": "#9971b8"
        },
        "strong": {
          "light": "#744299",
          "dark": "#b697cf"
        }
      },
      "information": {
        "subtle": {
          "light": "#c0d4f9",
          "dark": "#0f3a5e"
        },
        "moderate": {
          "light": "#7fadef",
          "dark": "#04568e"
        },
        "bold": {
          "light": "#007acd",
          "dark": "#3d85ca"
        },
        "strong": {
          "light": "#005aa0",
          "dark": "#77a6e1"
        }
      },
      "pink": {
        "moderate": {
          "light": "#ff80bd",
          "dark": "#7b4159"
        },
        "bold": {
          "light": "#e01b78",
          "dark": "#e44284"
        },
        "strong": {
          "light": "#963563",
          "dark": "#f779ad"
        }
      },
      "red": {
        "moderate": {
          "light": "#ff8979",
          "dark": "#95302c"
        },
        "bold": {
          "light": "#db3735",
          "dark": "#dd534b"
        },
        "strong": {
          "light": "#aa2424",
          "dark": "#fa7e72"
        }
      },
      "orange": {
        "moderate": {
          "light": "#ff8c47",
          "dark": "#7e4425"
        },
        "bold": {
          "light": "#bc5921",
          "dark": "#c3693a"
        },
        "strong": {
          "light": "#8e441a",
          "dark": "#f08750"
        }
      },
      "yellow": {
        "moderate": {
          "light": "#d5a334",
          "dark": "#655027"
        },
        "bold": {
          "light": "#947023",
          "dark": "#9d7d39"
        },
        "strong": {
          "light": "#6f551d",
          "dark": "#c59d4a"
        }
      },
      "green": {
        "moderate": {
          "light": "#63be74",
          "dark": "#12602b"
        },
        "bold": {
          "light": "#008836",
          "dark": "#30934b"
        },
        "strong": {
          "light": "#006725",
          "dark": "#63b473"
        }
      },
      "grey": {
        "moderate": {
          "light": "#9eadb8",
          "dark": "#425663"
        },
        "bold": {
          "light": "#60798b",
          "dark": "#718492"
        },
        "strong": {
          "light": "#435c6e",
          "dark": "#97a5af"
        }
      },
      "blue": {
        "moderate": {
          "light": "#7fadef",
          "dark": "#04568e"
        },
        "bold": {
          "light": "#007acd",
          "dark": "#3d85ca"
        },
        "strong": {
          "light": "#005aa0",
          "dark": "#77a6e1"
        }
      },
      "ultramarine": {
        "moderate": {
          "light": "#9fa9d4",
          "dark": "#3f508e"
        },
        "bold": {
          "light": "#6372b2",
          "dark": "#717fb5"
        },
        "strong": {
          "light": "#44549b",
          "dark": "#96a2cc"
        }
      },
      "purple": {
        "moderate": {
          "light": "#bf9ed9",
          "dark": "#694288"
        },
        "bold": {
          "light": "#9261b7",
          "dark": "#9971b8"
        },
        "strong": {
          "light": "#744299",
          "dark": "#b697cf"
        }
      },
      "teal": {
        "moderate": {
          "light": "#3dbcb8",
          "dark": "#275b59"
        },
        "bold": {
          "light": "#008480",
          "dark": "#328e8a"
        },
        "strong": {
          "light": "#0a6361",
          "dark": "#58b1ad"
        }
      },
      "lime": {
        "moderate": {
          "light": "#9db603",
          "dark": "#4e581e"
        },
        "bold": {
          "light": "#6c7d02",
          "dark": "#78892a"
        },
        "strong": {
          "light": "#525f0a",
          "dark": "#97ac37"
        }
      }
    },
    "icon": {
      "tertiary": {
        "light": "#778c9b",
        "dark": "#5c7181"
      },
      "secondary": {
        "_": {
          "light": "#60798b",
          "dark": "#718492"
        },
        "hovered": {
          "light": "#435c6e",
          "dark": "#97a5af"
        },
        "focused": {
          "light": "#435c6e",
          "dark": "#97a5af"
        },
        "pressed": {
          "light": "#334551",
          "dark": "#bcc4cb"
        }
      },
      "primary": {
        "light": "#334551",
        "dark": "#bcc4cb"
      },
      "inverse": {
        "primary": {
          "light": "#ffffff",
          "dark": "#15191d"
        }
      },
      "brand": {
        "secondary": {
          "_": {
            "light": "#007acd",
            "dark": "#3d85ca"
          },
          "hovered": {
            "light": "#005aa0",
            "dark": "#77a6e1"
          },
          "focused": {
            "light": "#005aa0",
            "dark": "#77a6e1"
          },
          "pressed": {
            "light": "#004478",
            "dark": "#a9c4ee"
          }
        },
        "primary": {
          "light": "#004478",
          "dark": "#a9c4ee"
        }
      },
      "danger": {
        "secondary": {
          "_": {
            "light": "#db3735",
            "dark": "#dd534b"
          },
          "hovered": {
            "light": "#aa2424",
            "dark": "#fa7e72"
          },
          "focused": {
            "light": "#aa2424",
            "dark": "#fa7e72"
          },
          "pressed": {
            "light": "#7f1d1b",
            "dark": "#ffaea3"
          }
        },
        "primary": {
          "light": "#7f1d1b",
          "dark": "#ffaea3"
        }
      },
      "pink": {
        "secondary": {
          "_": {
            "light": "#e01b78",
            "dark": "#e44284"
          },
          "hovered": {
            "light": "#963563",
            "dark": "#f779ad"
          },
          "focused": {
            "light": "#963563",
            "dark": "#f779ad"
          },
          "pressed": {
            "light": "#5c3749",
            "dark": "#fbaccc"
          }
        },
        "primary": {
          "light": "#5c3749",
          "dark": "#fbaccc"
        }
      },
      "red": {
        "secondary": {
          "_": {
            "light": "#db3735",
            "dark": "#dd534b"
          },
          "hovered": {
            "light": "#aa2424",
            "dark": "#fa7e72"
          },
          "focused": {
            "light": "#aa2424",
            "dark": "#fa7e72"
          },
          "pressed": {
            "light": "#7f1d1b",
            "dark": "#ffaea3"
          }
        },
        "primary": {
          "light": "#7f1d1b",
          "dark": "#ffaea3"
        }
      },
      "orange": {
        "secondary": {
          "_": {
            "light": "#bc5921",
            "dark": "#c3693a"
          },
          "hovered": {
            "light": "#8e441a",
            "dark": "#f08750"
          },
          "focused": {
            "light": "#8e441a",
            "dark": "#f08750"
          },
          "pressed": {
            "light": "#693315",
            "dark": "#ffb182"
          }
        },
        "primary": {
          "light": "#693315",
          "dark": "#ffb182"
        }
      },
      "yellow": {
        "secondary": {
          "_": {
            "light": "#947023",
            "dark": "#9d7d39"
          },
          "hovered": {
            "light": "#6f551d",
            "dark": "#c59d4a"
          },
          "focused": {
            "light": "#6f551d",
            "dark": "#c59d4a"
          },
          "pressed": {
            "light": "#533f18",
            "dark": "#eabc5f"
          }
        },
        "primary": {
          "light": "#533f18",
          "dark": "#eabc5f"
        }
      },
      "green": {
        "secondary": {
          "_": {
            "light": "#008836",
            "dark": "#30934b"
          },
          "hovered": {
            "light": "#006725",
            "dark": "#63b473"
          },
          "focused": {
            "light": "#006725",
            "dark": "#63b473"
          },
          "pressed": {
            "light": "#004d1c",
            "dark": "#99d0a0"
          }
        },
        "primary": {
          "light": "#004d1c",
          "dark": "#99d0a0"
        }
      },
      "grey": {
        "secondary": {
          "_": {
            "light": "#60798b",
            "dark": "#718492"
          },
          "hovered": {
            "light": "#435c6e",
            "dark": "#97a5af"
          },
          "focused": {
            "light": "#435c6e",
            "dark": "#97a5af"
          },
          "pressed": {
            "light": "#334551",
            "dark": "#bcc4cb"
          }
        },
        "primary": {
          "light": "#334551",
          "dark": "#bcc4cb"
        }
      },
      "blue": {
        "secondary": {
          "_": {
            "light": "#007acd",
            "dark": "#3d85ca"
          },
          "hovered": {
            "light": "#005aa0",
            "dark": "#77a6e1"
          },
          "focused": {
            "light": "#005aa0",
            "dark": "#77a6e1"
          },
          "pressed": {
            "light": "#004478",
            "dark": "#a9c4ee"
          }
        },
        "primary": {
          "light": "#004478",
          "dark": "#a9c4ee"
        }
      },
      "ultramarine": {
        "secondary": {
          "_": {
            "light": "#6372b2",
            "dark": "#717fb5"
          },
          "hovered": {
            "light": "#44549b",
            "dark": "#96a2cc"
          },
          "focused": {
            "light": "#44549b",
            "dark": "#96a2cc"
          },
          "pressed": {
            "light": "#2b3d83",
            "dark": "#bbc2df"
          }
        },
        "primary": {
          "light": "#2b3d83",
          "dark": "#bbc2df"
        }
      },
      "purple": {
        "secondary": {
          "_": {
            "light": "#9261b7",
            "dark": "#9971b8"
          },
          "hovered": {
            "light": "#744299",
            "dark": "#b697cf"
          },
          "focused": {
            "light": "#744299",
            "dark": "#b697cf"
          },
          "pressed": {
            "light": "#533669",
            "dark": "#d0bbe1"
          }
        },
        "primary": {
          "light": "#533669",
          "dark": "#d0bbe1"
        }
      },
      "teal": {
        "secondary": {
          "_": {
            "light": "#008480",
            "dark": "#328e8a"
          },
          "hovered": {
            "light": "#0a6361",
            "dark": "#58b1ad"
          },
          "focused": {
            "light": "#0a6361",
            "dark": "#58b1ad"
          },
          "pressed": {
            "light": "#154948",
            "dark": "#8bcfcb"
          }
        },
        "primary": {
          "light": "#154948",
          "dark": "#8bcfcb"
        }
      },
      "lime": {
        "secondary": {
          "_": {
            "light": "#6c7d02",
            "dark": "#78892a"
          },
          "hovered": {
            "light": "#525f0a",
            "dark": "#97ac37"
          },
          "focused": {
            "light": "#525f0a",
            "dark": "#97ac37"
          },
          "pressed": {
            "light": "#3e460e",
            "dark": "#b7cd4f"
          }
        },
        "primary": {
          "light": "#3e460e",
          "dark": "#b7cd4f"
        }
      }
    },
    "palette": {
      "grey": {
        "0": {
          "light": "#ffffff",
          "dark": "#15191d"
        },
        "100": {
          "light": "#f6f8f9",
          "dark": "#1a1f23"
        },
        "200": {
          "light": "#ebeef1",
          "dark": "#1f262b"
        },
        "300": {
          "light": "#ccd4da",
          "dark": "#2e3942"
        },
        "400": {
          "light": "#9eadb8",
          "dark": "#425663"
        },
        "500": {
          "light": "#778c9b",
          "dark": "#5c7181"
        },
        "600": {
          "light": "#60798b",
          "dark": "#718492"
        },
        "700": {
          "light": "#435c6e",
          "dark": "#97a5af"
        },
        "800": {
          "light": "#334551",
          "dark": "#bcc4cb"
        },
        "900": {
          "light": "#232d35",
          "dark": "#e2e5e9"
        },
        "1000": {
          "light": "#171d21",
          "dark": "#fafafb"
        },
        "alpha": {
          "0": {
            "light": "transparent",
            "dark": "transparent"
          },
          "100": {
            "light": "rgba(30, 80, 105, 0.04)",
            "dark": "rgba(188, 225, 229, 0.03)"
          },
          "200": {
            "light": "rgba(5, 43, 80, 0.08)",
            "dark": "rgba(164, 211, 229, 0.07)"
          },
          "300": {
            "light": "rgba(0, 40, 70, 0.2)",
            "dark": "rgba(168, 213, 247, 0.17)"
          },
          "400": {
            "light": "rgba(6, 45, 73, 0.39)",
            "dark": "rgba(166, 222, 255, 0.31)"
          },
          "500": {
            "light": "rgba(3, 42, 70, 0.54)",
            "dark": "rgba(179, 221, 251, 0.45)"
          }
        }
      },
      "pink": {
        "100": {
          "light": "#fff4f9",
          "dark": "#211d1e"
        },
        "200": {
          "light": "#ffe9f3",
          "dark": "#2a2326"
        },
        "300": {
          "light": "#ffc1df",
          "dark": "#45333a"
        },
        "400": {
          "light": "#ff80bd",
          "dark": "#7b4159"
        },
        "500": {
          "light": "#fb3492",
          "dark": "#c13c72"
        },
        "600": {
          "light": "#e01b78",
          "dark": "#e44284"
        },
        "700": {
          "light": "#963563",
          "dark": "#f779ad"
        },
        "800": {
          "light": "#5c3749",
          "dark": "#fbaccc"
        },
        "900": {
          "light": "#34282e",
          "dark": "#fddcea"
        }
      },
      "red": {
        "100": {
          "light": "#fff7f5",
          "dark": "#321613"
        },
        "200": {
          "light": "#ffeae5",
          "dark": "#411a16"
        },
        "300": {
          "light": "#ffc5b9",
          "dark": "#63221e"
        },
        "400": {
          "light": "#ff8979",
          "dark": "#95302c"
        },
        "500": {
          "light": "#f34e46",
          "dark": "#c3423c"
        },
        "600": {
          "light": "#db3735",
          "dark": "#dd534b"
        },
        "700": {
          "light": "#aa2424",
          "dark": "#fa7e72"
        },
        "800": {
          "light": "#7f1d1b",
          "dark": "#ffaea3"
        },
        "900": {
          "light": "#521814",
          "dark": "#ffddd7"
        },
        "alpha": {
          "0": {
            "light": "transparent",
            "dark": "transparent"
          },
          "100": {
            "light": "rgba(255, 55, 5, 0.04)",
            "dark": "rgba(104, 16, 0, 0.35)"
          },
          "200": {
            "light": "rgba(255, 64, 19, 0.11)",
            "dark": "rgba(197, 29, 1, 0.25)"
          },
          "300": {
            "light": "rgba(255, 48, 5, 0.28)",
            "dark": "rgba(250, 51, 32, 0.34)"
          }
        }
      },
      "orange": {
        "100": {
          "light": "#fff5ed",
          "dark": "#2c1b12"
        },
        "200": {
          "light": "#ffeada",
          "dark": "#372015"
        },
        "300": {
          "light": "#ffc89d",
          "dark": "#542f1b"
        },
        "400": {
          "light": "#ff8c47",
          "dark": "#7e4425"
        },
        "500": {
          "light": "#d96929",
          "dark": "#a85a31"
        },
        "600": {
          "light": "#bc5921",
          "dark": "#c3693a"
        },
        "700": {
          "light": "#8e441a",
          "dark": "#f08750"
        },
        "800": {
          "light": "#693315",
          "dark": "#ffb182"
        },
        "900": {
          "light": "#442312",
          "dark": "#ffdfc8"
        },
        "alpha": {
          "0": {
            "light": "transparent",
            "dark": "transparent"
          },
          "100": {
            "light": "rgba(255, 130, 30, 0.08)",
            "dark": "rgba(82, 30, 0, 0.38)"
          },
          "200": {
            "light": "rgba(255, 115, 8, 0.15)",
            "dark": "rgba(142, 50, 0, 0.28)"
          },
          "300": {
            "light": "rgba(255, 114, 4, 0.39)",
            "dark": "rgba(254, 106, 22, 0.27)"
          }
        }
      },
      "yellow": {
        "100": {
          "light": "#fff7e0",
          "dark": "#251e13"
        },
        "200": {
          "light": "#ffeeb4",
          "dark": "#2c2416"
        },
        "300": {
          "light": "#ffcc59",
          "dark": "#43361d"
        },
        "400": {
          "light": "#d5a334",
          "dark": "#655027"
        },
        "500": {
          "light": "#ab8327",
          "dark": "#866b32"
        },
        "600": {
          "light": "#947023",
          "dark": "#9d7d39"
        },
        "700": {
          "light": "#6f551d",
          "dark": "#c59d4a"
        },
        "800": {
          "light": "#533f18",
          "dark": "#eabc5f"
        },
        "900": {
          "light": "#362a13",
          "dark": "#ffe299"
        }
      },
      "green": {
        "100": {
          "light": "#f3faf4",
          "dark": "#102313"
        },
        "200": {
          "light": "#e2f3e3",
          "dark": "#112b16"
        },
        "300": {
          "light": "#aedfb3",
          "dark": "#12401e"
        },
        "400": {
          "light": "#63be74",
          "dark": "#12602b"
        },
        "500": {
          "light": "#199d47",
          "dark": "#1d7f3c"
        },
        "600": {
          "light": "#008836",
          "dark": "#30934b"
        },
        "700": {
          "light": "#006725",
          "dark": "#63b473"
        },
        "800": {
          "light": "#004d1c",
          "dark": "#99d0a0"
        },
        "900": {
          "light": "#0a3315",
          "dark": "#d4ebd6"
        },
        "alpha": {
          "0": {
            "light": "transparent",
            "dark": "transparent"
          },
          "100": {
            "light": "rgba(35, 155, 35, 0.05)",
            "dark": "rgba(7, 54, 0, 0.35)"
          },
          "200": {
            "light": "rgba(13, 155, 22, 0.12)",
            "dark": "rgba(5, 97, 1, 0.25)"
          },
          "300": {
            "light": "rgba(2, 155, 18, 0.32)",
            "dark": "rgba(3, 254, 35, 0.17)"
          }
        }
      },
      "blue": {
        "100": {
          "light": "#f6f8fd",
          "dark": "#111f2e"
        },
        "200": {
          "light": "#e7eefc",
          "dark": "#12263b"
        },
        "300": {
          "light": "#c0d4f9",
          "dark": "#0f3a5e"
        },
        "400": {
          "light": "#7fadef",
          "dark": "#04568e"
        },
        "500": {
          "light": "#3c8cde",
          "dark": "#1c73b6"
        },
        "600": {
          "light": "#007acd",
          "dark": "#3d85ca"
        },
        "700": {
          "light": "#005aa0",
          "dark": "#77a6e1"
        },
        "800": {
          "light": "#004478",
          "dark": "#a9c4ee"
        },
        "900": {
          "light": "#0b2d4e",
          "dark": "#dbe5f8"
        },
        "alpha": {
          "0": {
            "light": "transparent",
            "dark": "transparent"
          },
          "100": {
            "light": "rgba(30, 80, 205, 0.04)",
            "dark": "rgba(1, 55, 114, 0.2)"
          },
          "200": {
            "light": "rgba(15, 85, 225, 0.1)",
            "dark": "rgba(1, 112, 229, 0.15)"
          },
          "300": {
            "light": "rgba(3, 83, 231, 0.25)",
            "dark": "rgba(0, 139, 253, 0.29)"
          }
        }
      },
      "ultramarine": {
        "100": {
          "light": "#f6f7fb",
          "dark": "#171d37"
        },
        "200": {
          "light": "#eceef7",
          "dark": "#17224b"
        },
        "300": {
          "light": "#ccd1e9",
          "dark": "#25346e"
        },
        "400": {
          "light": "#9fa9d4",
          "dark": "#3f508e"
        },
        "500": {
          "light": "#7986bf",
          "dark": "#5d6ca7"
        },
        "600": {
          "light": "#6372b2",
          "dark": "#717fb5"
        },
        "700": {
          "light": "#44549b",
          "dark": "#96a2cc"
        },
        "800": {
          "light": "#2b3d83",
          "dark": "#bbc2df"
        },
        "900": {
          "light": "#182663",
          "dark": "#e1e4f1"
        }
      },
      "purple": {
        "100": {
          "light": "#faf7fc",
          "dark": "#201d22"
        },
        "200": {
          "light": "#f2ecf7",
          "dark": "#28232c"
        },
        "300": {
          "light": "#deccec",
          "dark": "#41324e"
        },
        "400": {
          "light": "#bf9ed9",
          "dark": "#694288"
        },
        "500": {
          "light": "#a377c5",
          "dark": "#875da9"
        },
        "600": {
          "light": "#9261b7",
          "dark": "#9971b8"
        },
        "700": {
          "light": "#744299",
          "dark": "#b697cf"
        },
        "800": {
          "light": "#533669",
          "dark": "#d0bbe1"
        },
        "900": {
          "light": "#312838",
          "dark": "#ebe1f2"
        },
        "alpha": {
          "0": {
            "light": "transparent",
            "dark": "transparent"
          },
          "100": {
            "light": "rgba(130, 55, 180, 0.04)",
            "dark": "rgba(241, 105, 129, 0.05)"
          },
          "200": {
            "light": "rgba(93, 18, 155, 0.08)",
            "dark": "rgba(232, 136, 196, 0.09)"
          },
          "300": {
            "light": "rgba(90, 0, 160, 0.20)",
            "dark": "rgba(221, 139, 252, 0.22)"
          }
        }
      },
      "teal": {
        "100": {
          "light": "#f1fafa",
          "dark": "#191f1f"
        },
        "200": {
          "light": "#daf4f3",
          "dark": "#1d2727"
        },
        "300": {
          "light": "#95dfdd",
          "dark": "#243c3b"
        },
        "400": {
          "light": "#3dbcb8",
          "dark": "#275b59"
        },
        "500": {
          "light": "#0a9994",
          "dark": "#297a77"
        },
        "600": {
          "light": "#008480",
          "dark": "#328e8a"
        },
        "700": {
          "light": "#0a6361",
          "dark": "#58b1ad"
        },
        "800": {
          "light": "#154948",
          "dark": "#8bcfcb"
        },
        "900": {
          "light": "#192f2f",
          "dark": "#ccebea"
        }
      },
      "lime": {
        "100": {
          "light": "#f8fad5",
          "dark": "#1e2010"
        },
        "200": {
          "light": "#eef5a8",
          "dark": "#242713"
        },
        "300": {
          "light": "#c7de43",
          "dark": "#353b18"
        },
        "400": {
          "light": "#9db603",
          "dark": "#4e581e"
        },
        "500": {
          "light": "#7d9200",
          "dark": "#677525"
        },
        "600": {
          "light": "#6c7d02",
          "dark": "#78892a"
        },
        "700": {
          "light": "#525f0a",
          "dark": "#97ac37"
        },
        "800": {
          "light": "#3e460e",
          "dark": "#b7cd4f"
        },
        "900": {
          "light": "#2a2e0e",
          "dark": "#dfed90"
        }
      },
      "engie": {
        "blue": {
          "light": "#00AAFF",
          "dark": "#00AAFF"
        },
        "green": {
          "light": "#23d2b5",
          "dark": "#23d2b5"
        }
      }
    },
    "text": {
      "primary": {
        "_": {
          "light": "#171d21",
          "dark": "#fafafb"
        },
        "hovered": {
          "light": "#007acd",
          "dark": "#3d85ca"
        },
        "focused": {
          "light": "#007acd",
          "dark": "#3d85ca"
        },
        "pressed": {
          "light": "#005aa0",
          "dark": "#77a6e1"
        }
      },
      "secondary": {
        "light": "#334551",
        "dark": "#bcc4cb"
      },
      "tertiary": {
        "_": {
          "light": "#60798b",
          "dark": "#718492"
        },
        "hovered": {
          "light": "#007acd",
          "dark": "#3d85ca"
        },
        "focused": {
          "light": "#007acd",
          "dark": "#3d85ca"
        },
        "pressed": {
          "light": "#005aa0",
          "dark": "#77a6e1"
        }
      },
      "inverse": {
        "_": {
          "light": "#ffffff",
          "dark": "#15191d"
        },
        "hovered": {
          "light": "#ebeef1",
          "dark": "#1f262b"
        },
        "focused": {
          "light": "#ebeef1",
          "dark": "#1f262b"
        },
        "pressed": {
          "light": "#ccd4da",
          "dark": "#2e3942"
        }
      },
      "brand": {
        "primary": {
          "_": {
            "light": "#007acd",
            "dark": "#3d85ca"
          },
          "hovered": {
            "light": "#005aa0",
            "dark": "#77a6e1"
          },
          "focused": {
            "light": "#005aa0",
            "dark": "#77a6e1"
          },
          "pressed": {
            "light": "#004478",
            "dark": "#a9c4ee"
          }
        },
        "high_contrast": {
          "_": {
            "light": "#005aa0",
            "dark": "#77a6e1"
          },
          "hovered": {
            "light": "#004478",
            "dark": "#a9c4ee"
          },
          "focused": {
            "light": "#004478",
            "dark": "#a9c4ee"
          },
          "pressed": {
            "light": "#0b2d4e",
            "dark": "#dbe5f8"
          }
        }
      },
      "danger": {
        "primary": {
          "_": {
            "light": "#db3735",
            "dark": "#dd534b"
          },
          "hovered": {
            "light": "#aa2424",
            "dark": "#fa7e72"
          },
          "focused": {
            "light": "#aa2424",
            "dark": "#fa7e72"
          },
          "pressed": {
            "light": "#7f1d1b",
            "dark": "#ffaea3"
          }
        },
        "secondary": {
          "light": "#7f1d1b",
          "dark": "#ffaea3"
        },
        "high_contrast": {
          "_": {
            "light": "#aa2424",
            "dark": "#fa7e72"
          },
          "hovered": {
            "light": "#7f1d1b",
            "dark": "#ffaea3"
          },
          "focused": {
            "light": "#7f1d1b",
            "dark": "#ffaea3"
          },
          "pressed": {
            "light": "#521814",
            "dark": "#ffddd7"
          }
        }
      },
      "warning": {
        "primary": {
          "_": {
            "light": "#bc5921",
            "dark": "#c3693a"
          },
          "hovered": {
            "light": "#8e441a",
            "dark": "#f08750"
          },
          "focused": {
            "light": "#8e441a",
            "dark": "#f08750"
          },
          "pressed": {
            "light": "#693315",
            "dark": "#ffb182"
          }
        },
        "secondary": {
          "light": "#693315",
          "dark": "#ffb182"
        },
        "high_contrast": {
          "_": {
            "light": "#8e441a",
            "dark": "#f08750"
          },
          "hovered": {
            "light": "#693315",
            "dark": "#ffb182"
          },
          "focused": {
            "light": "#693315",
            "dark": "#ffb182"
          },
          "pressed": {
            "light": "#442312",
            "dark": "#ffdfc8"
          }
        }
      },
      "success": {
        "primary": {
          "_": {
            "light": "#008836",
            "dark": "#30934b"
          },
          "hovered": {
            "light": "#006725",
            "dark": "#63b473"
          },
          "focused": {
            "light": "#006725",
            "dark": "#63b473"
          },
          "pressed": {
            "light": "#004d1c",
            "dark": "#99d0a0"
          }
        },
        "secondary": {
          "light": "#004d1c",
          "dark": "#99d0a0"
        },
        "high_contrast": {
          "_": {
            "light": "#006725",
            "dark": "#63b473"
          },
          "hovered": {
            "light": "#004d1c",
            "dark": "#99d0a0"
          },
          "focused": {
            "light": "#004d1c",
            "dark": "#99d0a0"
          },
          "pressed": {
            "light": "#0a3315",
            "dark": "#d4ebd6"
          }
        }
      },
      "information": {
        "primary": {
          "_": {
            "light": "#007acd",
            "dark": "#3d85ca"
          },
          "hovered": {
            "light": "#005aa0",
            "dark": "#77a6e1"
          },
          "focused": {
            "light": "#005aa0",
            "dark": "#77a6e1"
          },
          "pressed": {
            "light": "#004478",
            "dark": "#a9c4ee"
          }
        },
        "secondary": {
          "light": "#004478",
          "dark": "#a9c4ee"
        },
        "high_contrast": {
          "_": {
            "light": "#005aa0",
            "dark": "#77a6e1"
          },
          "hovered": {
            "light": "#004478",
            "dark": "#a9c4ee"
          },
          "focused": {
            "light": "#004478",
            "dark": "#a9c4ee"
          },
          "pressed": {
            "light": "#0b2d4e",
            "dark": "#dbe5f8"
          }
        }
      },
      "discovery": {
        "primary": {
          "_": {
            "light": "#9261b7",
            "dark": "#9971b8"
          },
          "hovered": {
            "light": "#744299",
            "dark": "#b697cf"
          },
          "focused": {
            "light": "#744299",
            "dark": "#b697cf"
          },
          "pressed": {
            "light": "#533669",
            "dark": "#d0bbe1"
          }
        },
        "secondary": {
          "light": "#533669",
          "dark": "#d0bbe1"
        },
        "high_contrast": {
          "_": {
            "light": "#744299",
            "dark": "#b697cf"
          },
          "hovered": {
            "light": "#533669",
            "dark": "#d0bbe1"
          },
          "focused": {
            "light": "#533669",
            "dark": "#d0bbe1"
          },
          "pressed": {
            "light": "#312838",
            "dark": "#ebe1f2"
          }
        }
      },
      "pink": {
        "primary": {
          "_": {
            "light": "#e01b78",
            "dark": "#e44284"
          },
          "hovered": {
            "light": "#963563",
            "dark": "#f779ad"
          },
          "focused": {
            "light": "#963563",
            "dark": "#f779ad"
          },
          "pressed": {
            "light": "#5c3749",
            "dark": "#fbaccc"
          }
        }
      },
      "red": {
        "primary": {
          "_": {
            "light": "#db3735",
            "dark": "#dd534b"
          },
          "hovered": {
            "light": "#aa2424",
            "dark": "#fa7e72"
          },
          "focused": {
            "light": "#aa2424",
            "dark": "#fa7e72"
          },
          "pressed": {
            "light": "#7f1d1b",
            "dark": "#ffaea3"
          }
        }
      },
      "orange": {
        "primary": {
          "_": {
            "light": "#bc5921",
            "dark": "#c3693a"
          },
          "hovered": {
            "light": "#8e441a",
            "dark": "#f08750"
          },
          "focused": {
            "light": "#8e441a",
            "dark": "#f08750"
          },
          "pressed": {
            "light": "#693315",
            "dark": "#ffb182"
          }
        }
      },
      "yellow": {
        "primary": {
          "_": {
            "light": "#947023",
            "dark": "#9d7d39"
          },
          "hovered": {
            "light": "#6f551d",
            "dark": "#c59d4a"
          },
          "focused": {
            "light": "#6f551d",
            "dark": "#c59d4a"
          },
          "pressed": {
            "light": "#533f18",
            "dark": "#eabc5f"
          }
        }
      },
      "green": {
        "primary": {
          "_": {
            "light": "#008836",
            "dark": "#30934b"
          },
          "hovered": {
            "light": "#006725",
            "dark": "#63b473"
          },
          "focused": {
            "light": "#006725",
            "dark": "#63b473"
          },
          "pressed": {
            "light": "#004d1c",
            "dark": "#99d0a0"
          }
        }
      },
      "grey": {
        "primary": {
          "_": {
            "light": "#60798b",
            "dark": "#718492"
          },
          "hovered": {
            "light": "#435c6e",
            "dark": "#97a5af"
          },
          "focused": {
            "light": "#435c6e",
            "dark": "#97a5af"
          },
          "pressed": {
            "light": "#334551",
            "dark": "#bcc4cb"
          }
        }
      },
      "blue": {
        "primary": {
          "_": {
            "light": "#007acd",
            "dark": "#3d85ca"
          },
          "hovered": {
            "light": "#005aa0",
            "dark": "#77a6e1"
          },
          "focused": {
            "light": "#005aa0",
            "dark": "#77a6e1"
          },
          "pressed": {
            "light": "#004478",
            "dark": "#a9c4ee"
          }
        }
      },
      "ultramarine": {
        "primary": {
          "_": {
            "light": "#6372b2",
            "dark": "#717fb5"
          },
          "hovered": {
            "light": "#44549b",
            "dark": "#96a2cc"
          },
          "focused": {
            "light": "#44549b",
            "dark": "#96a2cc"
          },
          "pressed": {
            "light": "#2b3d83",
            "dark": "#bbc2df"
          }
        }
      },
      "purple": {
        "primary": {
          "_": {
            "light": "#9261b7",
            "dark": "#9971b8"
          },
          "hovered": {
            "light": "#744299",
            "dark": "#b697cf"
          },
          "focused": {
            "light": "#744299",
            "dark": "#b697cf"
          },
          "pressed": {
            "light": "#533669",
            "dark": "#d0bbe1"
          }
        }
      },
      "teal": {
        "primary": {
          "_": {
            "light": "#008480",
            "dark": "#328e8a"
          },
          "hovered": {
            "light": "#0a6361",
            "dark": "#58b1ad"
          },
          "focused": {
            "light": "#0a6361",
            "dark": "#58b1ad"
          },
          "pressed": {
            "light": "#154948",
            "dark": "#8bcfcb"
          }
        }
      },
      "lime": {
        "primary": {
          "_": {
            "light": "#6c7d02",
            "dark": "#78892a"
          },
          "hovered": {
            "light": "#525f0a",
            "dark": "#97ac37"
          },
          "focused": {
            "light": "#525f0a",
            "dark": "#97ac37"
          },
          "pressed": {
            "light": "#3e460e",
            "dark": "#b7cd4f"
          }
        }
      }
    }
  },
  "opacity": {
    "disabled": "0.35"
  },
  "component": {
    "avatar": {
      "size": {
        "sm": "2rem",
        "base": "3rem",
        "lg": "4rem",
        "xl": "6rem"
      }
    },
    "skeleton": {
      "color": {
        "background": {
          "gradient": {
            "light": "linear-gradient(to right, transparent 0%, rgba(23, 29, 33, .05) 50%, transparent 100%)",
            "dark": "linear-gradient(to right, transparent 0%, rgba(250, 250, 251, .05) 50%, transparent 100%)"
          }
        }
      }
    }
  },
  "elevation": {
    "dropdown": "1000",
    "sticky": "1020",
    "fixed": "1030",
    "modal-backdrop": "1040",
    "modal": "1050",
    "popover": "1060",
    "tooltip": "1070",
    "toast-container": "1080",
    "toast": "1080"
  },
  "font": {
    "family": {
      "sansSerif": "\"Lato\", -apple-system, \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\", \"Helvetica Neue\", \"Arial\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
      "monospace": "\"SFMono-Regular\", \"Menlo\", \"Monaco\", \"Consolas\", \"Liberation Mono\", \"Courier New\", monospace",
      "default": "\"Lato\", -apple-system, \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\", \"Helvetica Neue\", \"Arial\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\""
    },
    "weight": {
      "light": "300",
      "normal": "400",
      "bold": "700",
      "black": "900"
    },
    "line": {
      "height": {
        "default": "1.5",
        "sansSerif": "1.5"
      }
    }
  },
  "motion": {
    "bezier": {
      "fast-out-slow-in": "cubic-bezier(.4, 0, .2, 1)",
      "linear-out-slow-in": "cubic-bezier(0, 0, .2, 1)",
      "fast-out-linear-in": "cubic-bezier(.4, 0, 1, 1)"
    }
  },
  "shadow": {
    "level": {
      "2-dp": {
        "light": "0 1px 5px 0 rgba(0, 0, 0, .1), 0 2px 2px 0 rgba(0, 0, 0, .06), 0 3px 1px rgba(0, 0, 0, .06)",
        "dark": "0 3px 0 rgba(0, 0, 0, .06),  0 2px 2px rgba(0, 0, 0, .3),  0 1px 5px rgba(0, 0, 0, .3)"
      },
      "4-dp": {
        "light": "0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 5px rgba(0, 0, 0, 0.06), 0 1px 10px rgba(0, 0, 0, 0.06)",
        "dark": "0 1px 10px rgba(0, 0, 0, .3),  0 4px 5px rgba(0, 0, 0, .3),  0 2px 4px rgba(0, 0, 0, .35)"
      },
      "6-dp": {
        "light": "0 3px 5px rgba(0, 0, 0, 0.1), 0 6px 10px rgba(0, 0, 0, 0.06), 0 1px 18px rgba(0, 0, 0, 0.06)",
        "dark": "0 1px 18px rgba(0, 0, 0, .3),  0 6px 10px rgba(0, 0, 0, .3),  0 3px 5px rgba(0, 0, 0, .3)"
      },
      "8-dp": {
        "light": "0 5px 5px rgba(0, 0, 0, 0.1), 0 8px 10px rgba(0, 0, 0, 0.06), 0 3px 14px rgba(0, 0, 0, 0.06)",
        "dark": "0 3px 14px rgba(0, 0, 0, .3),  0 8px 10px rgba(0, 0, 0, .3),  0 5px 5px rgba(0, 0, 0, .3)"
      },
      "16-dp": {
        "light": "0 8px 10px rgba(0, 0, 0, 0.1), 0 16px 24px rgba(0, 0, 0, 0.06), 0 6px 30px rgba(0, 0, 0, 0.06)",
        "dark": "0 6px 30px rgba(0, 0, 0, .3),  0 16px 24px rgba(0, 0, 0, .3),  0 8px 10px rgba(0, 0, 0, .35)"
      },
      "24-dp": {
        "light": "0 11px 15px rgba(0, 0, 0, 0.1), 0 24px 38px rgba(0, 0, 0, 0.06), 0 9px 46px rgba(0, 0, 0, 0.06)",
        "dark": "0 9px 46px rgba(0, 0, 0, .3),  0 24px 38px rgba(0, 0, 0, .3),  0 11px 15px rgba(0, 0, 0, .3)"
      }
    }
  },
  "size": {
    "border": {
      "width": "1px",
      "radius": {
        "md": "0",
        "sm": "0"
      },
      "focus": {
        "width": "2px",
        "offset": "2px"
      }
    },
    "font": {
      "peta": "4.75rem",
      "tera": "3.375rem",
      "giga": "2.25rem",
      "mega": "1.75rem",
      "kilo": "1.5rem",
      "hecto": "1.25rem",
      "deca": "1.125rem",
      "base": "1rem",
      "deci": ".875rem",
      "centi": ".75rem"
    },
    "icon": {
      "sm": "16px",
      "md": "24px",
      "lg": "32px",
      "xl": "48px",
      "xxl": "64px",
      "3xl": "80px",
      "4xl": "96px",
      "5xl": "112px",
      "6xl": "128px"
    },
    "space": {
      "2": "0.125rem",
      "4": "0.25rem",
      "8": "0.5rem",
      "12": "0.75rem",
      "16": "1rem",
      "20": "1.25rem",
      "24": "1.5rem",
      "32": "2rem",
      "48": "3rem",
      "64": "4rem",
      "80": "5rem",
      "96": "6rem",
      "112": "7rem"
    }
  }
}
