import type { ChangeEvent, Dispatch, DragEvent, SetStateAction } from 'react'
import {
  type DataScienceScorePost,
  type ScoreState,
  dataScienceScorePost
} from 'types/datascience-score'
import { useContext, useRef, useState } from 'react'
import { DragCTX } from 'components/atoms/drag-context/drag-context'
import { endpoints } from 'endpoint/endpoints'
import { getApiRoute } from 'utils/api-route'
import { useOktaAuth } from '@okta/okta-react'
import { vfetch } from 'utils/fetch'

const useDSCFileUpload = (
  challengeId: number,
  setLastScoreState: Dispatch<SetStateAction<ScoreState>>,
  disabled = false
) => {
  const { authState } = useOktaAuth()
  const inputRef = useRef<HTMLInputElement>(null)
  const [dragPosition, setDragPosition] = useState('')
  const [loadingUploadParticipation, setLoadingUploadParticipation] = useState(false)
  const [uploadSuccessfull, setUploadSuccessfull] = useState<boolean | null>(null)
  const { resetDrag } = useContext(DragCTX)
  const getHeaders = () => ({
    Authorization: `Bearer ${authState?.accessToken?.accessToken}`
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return
    e.preventDefault()
    const eventTarget = e?.target as HTMLInputElement
    if (eventTarget?.files && eventTarget?.files[0]) {
      const files = eventTarget.files
      handleSubmission(files)
    }
  }

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    if (disabled) return
    setDragPosition(e.type)
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: DragEvent) => {
    if (disabled) return
    setDragPosition('')
    e.preventDefault()
    e.stopPropagation()
    if (e?.dataTransfer?.files && e.dataTransfer.files[0]) {
      handleSubmission(e.dataTransfer.files)
    }
    resetDrag()
  }

  const handleSubmission = async (participation: FileList) => {
    if (disabled) return
    const rawFile = participation[0]
    if (!rawFile) {
      throw new Error("File can't be sent")
    }

    await sendParticipation(rawFile)
  }

  const onButtonClick = () => {
    if (disabled) return
    if (inputRef.current != null) {
      inputRef.current.click()
    }
  }

  const sendParticipation = async (rawFile: File): Promise<DataScienceScorePost> => {
    setLoadingUploadParticipation(true)
    const date = Date.now()
    const filename = `${challengeId}_${date}_${rawFile.name}`
    const formattedFile = new File([rawFile], filename, {
      type: rawFile.type,
      lastModified: rawFile.lastModified
    })

    const formData = new FormData()
    formData.append('path', 'input')
    formData.append(`files.submit_file`, formattedFile as Blob)
    formData.append(
      'data',
      JSON.stringify({
        data: {
          datascience_challenge: challengeId,
          score: -1,
          private_score: -1
        }
      })
    )

    const res = await vfetch(
      getApiRoute(endpoints.dataScienceScores),
      { ok: dataScienceScorePost },
      {
        method: 'POST',
        headers: getHeaders(),
        body: formData
      }
    )

    if (!res.ok) {
      const message = 'Upload failed'
      alert(message)
      // setLoadingUploadParticipation(false)
      setUploadSuccessfull(false)
      throw new Error(message)
    }
    setUploadSuccessfull(true)
    setLastScoreState({
      id: res.value.data.id,
      state: res.value.data.attributes.state
    })
    // setLoadingUploadParticipation(false)
    return res.value
  }

  const resetUpload = () => {
    setLoadingUploadParticipation(false)
    setUploadSuccessfull(null)
  }

  return {
    resetUpload,
    uploadSuccessfull,
    dragPosition,
    loadingUploadParticipation,
    inputRef,
    handleChange,
    handleDrag,
    handleDrop,
    onButtonClick
  }
}

export { useDSCFileUpload }
