import { DSCListChallenges } from 'components/organisms/list-challenges/dsc-list-challenges'
import { DataFetcher } from 'components/templates/data-fetcher/data-fetcher'
import { SecuredPage } from 'components/templates/page/secured-page'
import { endpoints } from 'endpoint/endpoints'
import { getApiRoute } from 'utils/api-route'
import { listChallenges } from 'types/datascience-challenge'
import { useOktaAuth } from '@okta/okta-react'
import { useParams } from 'react-router-dom'

const ListChallenges = () => {
  const { authState } = useOktaAuth()
  const { slug } = useParams()
  const route = getApiRoute(endpoints.dataScienceChallenges)
  const params = {
    filters: {
      slug: {
        $eq: slug
      }
    },
    fields: ['Title', 'startDate', 'endDate', 'slug']
  }
  const validator = listChallenges

  return (
    <SecuredPage>
      <DataFetcher
        {...{
          accessToken: authState?.accessToken?.accessToken,
          route,
          params,
          validator
        }}
      >
        {data => <DSCListChallenges {...{ challenges: data }}></DSCListChallenges>}
      </DataFetcher>
    </SecuredPage>
  )
}

export { ListChallenges }
