import './data-fetcher.scss'
import { type FC, useEffect, useState } from 'react'
import { type VFetchResult, vfetch } from 'utils/fetch'
import { DSCErrors } from 'components/atoms/errors/dsc-errors'
import { DSCSpinner } from 'components/atoms/spinner/dsc-spinner'
import type { Validator } from 'idonttrustlikethat'
import qs from 'qs'
import { useOktaAuth } from '@okta/okta-react'

interface Props<T> {
  accessToken?: string
  route: string
  params?: object
  validator: Validator<T>
  children: FC<T>
}

export const DataFetcher = <T extends object>({
  children,
  route,
  params,
  validator
}: Props<T>) => {
  const { authState } = useOktaAuth()
  const [data, setData] = useState<VFetchResult<T>>()
  const [isLoading, setIsLoading] = useState(false)

  const getData = async () => {
    if (isLoading) return
    setIsLoading(true)
    let routeQuery = ''
    const headers = { Authorization: `Bearer ${authState?.accessToken?.accessToken}` }

    if (params) {
      const convertedParameters = qs.stringify(params, {
        encodeValuesOnly: true
      })
      routeQuery += '?' + convertedParameters
    }
    const res = await vfetch(route + routeQuery, { ok: validator }, { headers })
    setData(res)
    setIsLoading(false)
  }

  useEffect(() => {
    if (authState?.accessToken?.accessToken) getData()
  }, [authState?.accessToken?.accessToken])

  if (!data) {
    return (
      <div className="main-spinner">
        <DSCSpinner
          {...{ isLoading: true, isBlock: false, variant: 'normal', size: 'lg' }}
        ></DSCSpinner>
      </div>
    )
  }
  if (data && !data.ok) {
    console.error(data)
    return <DSCErrors />
  }

  return children(data.value, () => {
    getData()
  })
}
