import './dsc-header.scss'
import { NJNavbar, NJNavbarItem } from '@engie-group/fluid-design-system-react'
import { useLocation, useNavigate } from 'react-router-dom'
import DatascienChallengeLogo from 'assets/images/png/datascience-challenge.png'
import { type FC } from 'react'

const Routes: { [key: string]: string } = {
  Home: '/',
  Challenges: '/datascience-challenges'
}

const DSCHeader: FC = () => {
  const _navigate = useNavigate()
  const location = useLocation()

  const navigate = (route: string) => {
    if (route !== location.pathname) {
      _navigate(route)
    }
  }

  return (
    <header className="app-header">
      <NJNavbar
        activeItemId={location.pathname}
        logo={{
          href: '',
          image: <img src={DatascienChallengeLogo} height={37} />,
          onClick: () => {
            navigate('/')
          }
        }}
        onClickItem={navigate}
      >
        {Object.keys(Routes).map((key: string) => (
          <NJNavbarItem
            {...{
              key,
              id: Routes[key] ?? '',
              href: Routes[key]
            }}
          >
            {key}
          </NJNavbarItem>
        ))}
      </NJNavbar>
    </header>
  )
}

export { DSCHeader }
