import { isoDate, number, object, string } from 'idonttrustlikethat'
import { imageFormats } from './image-format'

export const logo = object({
  data: object({
    id: number,
    attributes: object({
      name: string,
      alternativeText: string.nullable(),
      caption: string.nullable(),
      width: number,
      height: number,
      formats: imageFormats,
      hash: string,
      ext: string,
      mime: string,
      size: number,
      url: string,
      previewUrl: string.nullable(),
      provider: string,
      provider_metadata: string.nullable(),
      createdAt: isoDate,
      updatedAt: isoDate
    })
  }).nullable()
})
