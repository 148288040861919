import type { FC } from 'react'
import { ReactComponent as RefreshIcon } from 'assets/icons/svg/refresh.svg'
interface DSCRefreshButtonProps {
  onReload: () => void
}

const DSCRefreshButton: FC<DSCRefreshButtonProps> = ({ onReload }) => {
  return (
    <div
      title="By clicking on this button, the scores will be updated."
      className="refresh-button-container"
    >
      <RefreshIcon onClick={onReload}></RefreshIcon>
    </div>
  )
}

export { DSCRefreshButton }
