import '@engie-group/fluid-design-system/lib/components/grid/index.css'
import { DSChallenge } from 'components/templates/challenge/ds-challenge'
import { DataFetcher } from 'components/templates/data-fetcher/data-fetcher'
import { SecuredPage } from 'components/templates/page/secured-page'
import { challenges } from 'types/datascience-challenge'
import { endpoints } from 'endpoint/endpoints'
import { getApiRoute } from 'utils/api-route'
import { useOktaAuth } from '@okta/okta-react'
import { useParams } from 'react-router-dom'

const Challenge = () => {
  const { authState } = useOktaAuth()

  const { slug } = useParams()

  const route = getApiRoute(endpoints.dataScienceChallenges)

  const params = {
    filters: {
      slug: {
        $eq: slug
      }
    },
    fields: [
      'Title',
      'description',
      'logo',
      'metricsParameters',
      'startDate',
      'endDate',
      'slug'
    ],
    populate: {
      logo: { populate: '*' }
    }
  }
  const validator = challenges

  return (
    <SecuredPage>
      <DataFetcher
        {...{
          accessToken: authState?.accessToken?.accessToken,
          route,
          params,
          validator
        }}
      >
        {(data, onReload) =>
          data?.data?.[0] ? (
            <DSChallenge
              {...{
                challenge: data.data[0],
                onReload
              }}
            ></DSChallenge>
          ) : (
            <></>
          )
        }
      </DataFetcher>
    </SecuredPage>
  )
}

export { Challenge }
