export const njColorBackgroundPrimaryLight = "#ffffff";
export const njColorBackgroundPrimaryDark = "#15191d";
export const njColorBackgroundPrimaryHoveredLight = "#ebeef1";
export const njColorBackgroundPrimaryHoveredDark = "#1f262b";
export const njColorBackgroundPrimaryFocusedLight = "#ebeef1";
export const njColorBackgroundPrimaryFocusedDark = "#1f262b";
export const njColorBackgroundPrimaryPressedLight = "#ccd4da";
export const njColorBackgroundPrimaryPressedDark = "#2e3942";
export const njColorBackgroundPrimarySelectedLight = "#e7eefc";
export const njColorBackgroundPrimarySelectedDark = "#12263b";
export const njColorBackgroundSecondaryLight = "#f6f8f9";
export const njColorBackgroundSecondaryDark = "#1a1f23";
export const njColorBackgroundSecondaryHoveredLight = "#ebeef1";
export const njColorBackgroundSecondaryHoveredDark = "#1f262b";
export const njColorBackgroundSecondaryFocusedLight = "#ebeef1";
export const njColorBackgroundSecondaryFocusedDark = "#1f262b";
export const njColorBackgroundSecondaryPressedLight = "#ccd4da";
export const njColorBackgroundSecondaryPressedDark = "#2e3942";
export const njColorBackgroundSecondaryTransparentLight = "rgba(30, 80, 105, 0.04)";
export const njColorBackgroundSecondaryTransparentDark = "rgba(188, 225, 229, 0.03)";
export const njColorBackgroundSecondaryTransparentHoveredLight = "rgba(5, 43, 80, 0.08)";
export const njColorBackgroundSecondaryTransparentHoveredDark = "rgba(164, 211, 229, 0.07)";
export const njColorBackgroundSecondaryTransparentFocusedLight = "rgba(5, 43, 80, 0.08)";
export const njColorBackgroundSecondaryTransparentFocusedDark = "rgba(164, 211, 229, 0.07)";
export const njColorBackgroundSecondaryTransparentPressedLight = "rgba(0, 40, 70, 0.2)";
export const njColorBackgroundSecondaryTransparentPressedDark = "rgba(168, 213, 247, 0.17)";
export const njColorBackgroundTertiaryLight = "#ebeef1";
export const njColorBackgroundTertiaryDark = "#1f262b";
export const njColorBackgroundTertiaryHoveredLight = "#ccd4da";
export const njColorBackgroundTertiaryHoveredDark = "#2e3942";
export const njColorBackgroundTertiaryFocusedLight = "#ccd4da";
export const njColorBackgroundTertiaryFocusedDark = "#2e3942";
export const njColorBackgroundTertiaryPressedLight = "#9eadb8";
export const njColorBackgroundTertiaryPressedDark = "#425663";
export const njColorBackgroundTertiaryTransparentLight = "rgba(5, 43, 80, 0.08)";
export const njColorBackgroundTertiaryTransparentDark = "rgba(164, 211, 229, 0.07)";
export const njColorBackgroundTertiaryTransparentHoveredLight = "rgba(0, 40, 70, 0.2)";
export const njColorBackgroundTertiaryTransparentHoveredDark = "rgba(168, 213, 247, 0.17)";
export const njColorBackgroundTertiaryTransparentFocusedLight = "rgba(0, 40, 70, 0.2)";
export const njColorBackgroundTertiaryTransparentFocusedDark = "rgba(168, 213, 247, 0.17)";
export const njColorBackgroundTertiaryTransparentPressedLight = "rgba(6, 45, 73, 0.39)";
export const njColorBackgroundTertiaryTransparentPressedDark = "rgba(166, 222, 255, 0.31)";
export const njColorBackgroundNeutralLight = "#60798b";
export const njColorBackgroundNeutralDark = "#718492";
export const njColorBackgroundNeutralHoveredLight = "#435c6e";
export const njColorBackgroundNeutralHoveredDark = "#97a5af";
export const njColorBackgroundNeutralFocusedLight = "#435c6e";
export const njColorBackgroundNeutralFocusedDark = "#97a5af";
export const njColorBackgroundNeutralPressedLight = "#334551";
export const njColorBackgroundNeutralPressedDark = "#bcc4cb";
export const njColorBackgroundTransparentLight = "transparent";
export const njColorBackgroundTransparentDark = "transparent";
export const njColorBackgroundTransparentHoveredLight = "rgba(5, 43, 80, 0.08)";
export const njColorBackgroundTransparentHoveredDark = "rgba(164, 211, 229, 0.07)";
export const njColorBackgroundTransparentFocusedLight = "rgba(5, 43, 80, 0.08)";
export const njColorBackgroundTransparentFocusedDark = "rgba(164, 211, 229, 0.07)";
export const njColorBackgroundTransparentPressedLight = "rgba(0, 40, 70, 0.2)";
export const njColorBackgroundTransparentPressedDark = "rgba(168, 213, 247, 0.17)";
export const njColorBackgroundTransparentSelectedLight = "rgba(15, 85, 225, 0.1)";
export const njColorBackgroundTransparentSelectedDark = "rgba(1, 112, 229, 0.15)";
export const njColorBackgroundInverseLight = "#ffffff";
export const njColorBackgroundInverseDark = "#15191d";
export const njColorBackgroundInverseHoveredLight = "#ebeef1";
export const njColorBackgroundInverseHoveredDark = "#1f262b";
export const njColorBackgroundInverseFocusedLight = "#ebeef1";
export const njColorBackgroundInverseFocusedDark = "#1f262b";
export const njColorBackgroundInversePressedLight = "#ccd4da";
export const njColorBackgroundInversePressedDark = "#2e3942";
export const njColorBackgroundInverseTransparentLight = "transparent";
export const njColorBackgroundInverseTransparentDark = "transparent";
export const njColorBackgroundInverseTransparentHoveredLight = "rgba(168, 213, 247, 0.17)";
export const njColorBackgroundInverseTransparentHoveredDark = "rgba(0, 40, 70, 0.2)";
export const njColorBackgroundInverseTransparentFocusedLight = "rgba(168, 213, 247, 0.17)";
export const njColorBackgroundInverseTransparentFocusedDark = "rgba(0, 40, 70, 0.2)";
export const njColorBackgroundInverseTransparentPressedLight = "rgba(166, 222, 255, 0.31)";
export const njColorBackgroundInverseTransparentPressedDark = "rgba(6, 45, 73, 0.39)";
export const njColorBackgroundInverseContrastLight = "rgba(164, 211, 229, 0.07)";
export const njColorBackgroundInverseContrastDark = "rgba(5, 43, 80, 0.08)";
export const njColorBackgroundHighContrastLight = "#232d35";
export const njColorBackgroundHighContrastDark = "#e2e5e9";
export const njColorBackgroundBackdropLight = "rgba(3, 42, 70, 0.54)";
export const njColorBackgroundBackdropDark = "rgba(168, 213, 247, 0.17)";
export const njColorBackgroundBrandLight = "#007acd";
export const njColorBackgroundBrandDark = "#3d85ca";
export const njColorBackgroundBrandSecondaryLight = "#f6f8fd";
export const njColorBackgroundBrandSecondaryDark = "#111f2e";
export const njColorBackgroundBrandTertiaryLight = "#e7eefc";
export const njColorBackgroundBrandTertiaryDark = "#12263b";
export const njColorBackgroundBrandTertiaryHoveredLight = "#c0d4f9";
export const njColorBackgroundBrandTertiaryHoveredDark = "#0f3a5e";
export const njColorBackgroundBrandTertiaryFocusedLight = "#c0d4f9";
export const njColorBackgroundBrandTertiaryFocusedDark = "#0f3a5e";
export const njColorBackgroundBrandTertiaryPressedLight = "#7fadef";
export const njColorBackgroundBrandTertiaryPressedDark = "#04568e";
export const njColorBackgroundBrandTertiaryTransparentLight = "rgba(15, 85, 225, 0.1)";
export const njColorBackgroundBrandTertiaryTransparentDark = "rgba(1, 112, 229, 0.15)";
export const njColorBackgroundBrandTertiaryTransparentHoveredLight = "rgba(3, 83, 231, 0.25)";
export const njColorBackgroundBrandTertiaryTransparentHoveredDark = "rgba(0, 139, 253, 0.29)";
export const njColorBackgroundBrandTertiaryTransparentFocusedLight = "rgba(3, 83, 231, 0.25)";
export const njColorBackgroundBrandTertiaryTransparentFocusedDark = "rgba(0, 139, 253, 0.29)";
export const njColorBackgroundBrandTertiaryTransparentPressedLight = "rgba(3, 83, 231, 0.25)";
export const njColorBackgroundBrandTertiaryTransparentPressedDark = "rgba(0, 139, 253, 0.29)";
export const njColorBackgroundBrandHoveredLight = "#005aa0";
export const njColorBackgroundBrandHoveredDark = "#77a6e1";
export const njColorBackgroundBrandFocusedLight = "#005aa0";
export const njColorBackgroundBrandFocusedDark = "#77a6e1";
export const njColorBackgroundBrandPressedLight = "#004478";
export const njColorBackgroundBrandPressedDark = "#a9c4ee";
export const njColorBackgroundBrandTransparentLight = "transparent";
export const njColorBackgroundBrandTransparentDark = "transparent";
export const njColorBackgroundBrandTransparentHoveredLight = "rgba(15, 85, 225, 0.1)";
export const njColorBackgroundBrandTransparentHoveredDark = "rgba(1, 112, 229, 0.15)";
export const njColorBackgroundBrandTransparentFocusedLight = "rgba(15, 85, 225, 0.1)";
export const njColorBackgroundBrandTransparentFocusedDark = "rgba(1, 112, 229, 0.15)";
export const njColorBackgroundBrandTransparentPressedLight = "rgba(3, 83, 231, 0.25)";
export const njColorBackgroundBrandTransparentPressedDark = "rgba(0, 139, 253, 0.29)";
export const njColorBackgroundBrandHighContrastLight = "#0b2d4e";
export const njColorBackgroundBrandHighContrastDark = "#dbe5f8";
export const njColorBackgroundDangerLight = "#db3735";
export const njColorBackgroundDangerDark = "#dd534b";
export const njColorBackgroundDangerSecondaryLight = "#fff7f5";
export const njColorBackgroundDangerSecondaryDark = "#321613";
export const njColorBackgroundDangerTertiaryLight = "#ffeae5";
export const njColorBackgroundDangerTertiaryDark = "#411a16";
export const njColorBackgroundDangerTertiaryHoveredLight = "#ffc5b9";
export const njColorBackgroundDangerTertiaryHoveredDark = "#63221e";
export const njColorBackgroundDangerTertiaryFocusedLight = "#ffc5b9";
export const njColorBackgroundDangerTertiaryFocusedDark = "#63221e";
export const njColorBackgroundDangerTertiaryPressedLight = "#ff8979";
export const njColorBackgroundDangerTertiaryPressedDark = "#95302c";
export const njColorBackgroundDangerTertiaryTransparentLight = "rgba(255, 64, 19, 0.11)";
export const njColorBackgroundDangerTertiaryTransparentDark = "rgba(197, 29, 1, 0.25)";
export const njColorBackgroundDangerHoveredLight = "#aa2424";
export const njColorBackgroundDangerHoveredDark = "#fa7e72";
export const njColorBackgroundDangerPressedLight = "#7f1d1b";
export const njColorBackgroundDangerPressedDark = "#ffaea3";
export const njColorBackgroundDangerFocusedLight = "#aa2424";
export const njColorBackgroundDangerFocusedDark = "#fa7e72";
export const njColorBackgroundDangerTransparentLight = "transparent";
export const njColorBackgroundDangerTransparentDark = "transparent";
export const njColorBackgroundDangerTransparentHoveredLight = "rgba(255, 64, 19, 0.11)";
export const njColorBackgroundDangerTransparentHoveredDark = "rgba(197, 29, 1, 0.25)";
export const njColorBackgroundDangerTransparentFocusedLight = "rgba(255, 64, 19, 0.11)";
export const njColorBackgroundDangerTransparentFocusedDark = "rgba(197, 29, 1, 0.25)";
export const njColorBackgroundDangerTransparentPressedLight = "rgba(255, 48, 5, 0.28)";
export const njColorBackgroundDangerTransparentPressedDark = "rgba(250, 51, 32, 0.34)";
export const njColorBackgroundDangerTransparentSelectedLight = "rgba(255, 64, 19, 0.11)";
export const njColorBackgroundDangerTransparentSelectedDark = "rgba(197, 29, 1, 0.25)";
export const njColorBackgroundSuccessLight = "#008836";
export const njColorBackgroundSuccessDark = "#30934b";
export const njColorBackgroundSuccessSecondaryLight = "#f3faf4";
export const njColorBackgroundSuccessSecondaryDark = "#102313";
export const njColorBackgroundSuccessTertiaryLight = "#e2f3e3";
export const njColorBackgroundSuccessTertiaryDark = "#112b16";
export const njColorBackgroundSuccessTertiaryHoveredLight = "#aedfb3";
export const njColorBackgroundSuccessTertiaryHoveredDark = "#12401e";
export const njColorBackgroundSuccessTertiaryFocusedLight = "#aedfb3";
export const njColorBackgroundSuccessTertiaryFocusedDark = "#12401e";
export const njColorBackgroundSuccessTertiaryPressedLight = "#63be74";
export const njColorBackgroundSuccessTertiaryPressedDark = "#12602b";
export const njColorBackgroundSuccessTertiaryTransparentLight = "rgba(13, 155, 22, 0.12)";
export const njColorBackgroundSuccessTertiaryTransparentDark = "rgba(5, 97, 1, 0.25)";
export const njColorBackgroundSuccessHoveredLight = "#006725";
export const njColorBackgroundSuccessHoveredDark = "#63b473";
export const njColorBackgroundSuccessPressedLight = "#004d1c";
export const njColorBackgroundSuccessPressedDark = "#99d0a0";
export const njColorBackgroundSuccessTransparentLight = "transparent";
export const njColorBackgroundSuccessTransparentDark = "transparent";
export const njColorBackgroundSuccessTransparentHoveredLight = "rgba(13, 155, 22, 0.12)";
export const njColorBackgroundSuccessTransparentHoveredDark = "rgba(5, 97, 1, 0.25)";
export const njColorBackgroundSuccessTransparentFocusedLight = "rgba(13, 155, 22, 0.12)";
export const njColorBackgroundSuccessTransparentFocusedDark = "rgba(5, 97, 1, 0.25)";
export const njColorBackgroundSuccessTransparentPressedLight = "rgba(2, 155, 18, 0.32)";
export const njColorBackgroundSuccessTransparentPressedDark = "rgba(3, 254, 35, 0.17)";
export const njColorBackgroundSuccessTransparentSelectedLight = "rgba(13, 155, 22, 0.12)";
export const njColorBackgroundSuccessTransparentSelectedDark = "rgba(5, 97, 1, 0.25)";
export const njColorBackgroundWarningLight = "#ff8c47";
export const njColorBackgroundWarningDark = "#c3693a";
export const njColorBackgroundWarningSecondaryLight = "#fff5ed";
export const njColorBackgroundWarningSecondaryDark = "#2c1b12";
export const njColorBackgroundWarningTertiaryLight = "#ffeada";
export const njColorBackgroundWarningTertiaryDark = "#372015";
export const njColorBackgroundWarningTertiaryHoveredLight = "#ffc89d";
export const njColorBackgroundWarningTertiaryHoveredDark = "#542f1b";
export const njColorBackgroundWarningTertiaryFocusedLight = "#ffc89d";
export const njColorBackgroundWarningTertiaryFocusedDark = "#542f1b";
export const njColorBackgroundWarningTertiaryPressedLight = "#ff8c47";
export const njColorBackgroundWarningTertiaryPressedDark = "#7e4425";
export const njColorBackgroundWarningTertiaryTransparentLight = "rgba(255, 115, 8, 0.15)";
export const njColorBackgroundWarningTertiaryTransparentDark = "rgba(142, 50, 0, 0.28)";
export const njColorBackgroundWarningHoveredLight = "#d96929";
export const njColorBackgroundWarningHoveredDark = "#f08750";
export const njColorBackgroundWarningPressedLight = "#bc5921";
export const njColorBackgroundWarningPressedDark = "#ffb182";
export const njColorBackgroundWarningTransparentLight = "transparent";
export const njColorBackgroundWarningTransparentDark = "transparent";
export const njColorBackgroundWarningTransparentHoveredLight = "rgba(255, 115, 8, 0.15)";
export const njColorBackgroundWarningTransparentHoveredDark = "rgba(142, 50, 0, 0.28)";
export const njColorBackgroundWarningTransparentFocusedLight = "rgba(255, 115, 8, 0.15)";
export const njColorBackgroundWarningTransparentFocusedDark = "rgba(142, 50, 0, 0.28)";
export const njColorBackgroundWarningTransparentPressedLight = "rgba(255, 114, 4, 0.39)";
export const njColorBackgroundWarningTransparentPressedDark = "rgba(254, 106, 22, 0.27)";
export const njColorBackgroundWarningTransparentSelectedLight = "rgba(255, 115, 8, 0.15)";
export const njColorBackgroundWarningTransparentSelectedDark = "rgba(142, 50, 0, 0.28)";
export const njColorBackgroundDiscoveryLight = "#9261b7";
export const njColorBackgroundDiscoveryDark = "#9971b8";
export const njColorBackgroundDiscoverySecondaryLight = "#faf7fc";
export const njColorBackgroundDiscoverySecondaryDark = "#201d22";
export const njColorBackgroundDiscoveryTertiaryLight = "#f2ecf7";
export const njColorBackgroundDiscoveryTertiaryDark = "#28232c";
export const njColorBackgroundDiscoveryTertiaryHoveredLight = "#deccec";
export const njColorBackgroundDiscoveryTertiaryHoveredDark = "#41324e";
export const njColorBackgroundDiscoveryTertiaryFocusedLight = "#deccec";
export const njColorBackgroundDiscoveryTertiaryFocusedDark = "#41324e";
export const njColorBackgroundDiscoveryTertiaryPressedLight = "#bf9ed9";
export const njColorBackgroundDiscoveryTertiaryPressedDark = "#694288";
export const njColorBackgroundDiscoveryTertiaryTransparentLight = "rgba(93, 18, 155, 0.08)";
export const njColorBackgroundDiscoveryTertiaryTransparentDark = "rgba(232, 136, 196, 0.09)";
export const njColorBackgroundDiscoveryHoveredLight = "#a377c5";
export const njColorBackgroundDiscoveryHoveredDark = "#b697cf";
export const njColorBackgroundDiscoveryPressedLight = "#9261b7";
export const njColorBackgroundDiscoveryPressedDark = "#d0bbe1";
export const njColorBackgroundDiscoveryTransparentLight = "transparent";
export const njColorBackgroundDiscoveryTransparentDark = "transparent";
export const njColorBackgroundDiscoveryTransparentHoveredLight = "rgba(93, 18, 155, 0.08)";
export const njColorBackgroundDiscoveryTransparentHoveredDark = "rgba(232, 136, 196, 0.09)";
export const njColorBackgroundDiscoveryTransparentFocusedLight = "rgba(93, 18, 155, 0.08)";
export const njColorBackgroundDiscoveryTransparentFocusedDark = "rgba(232, 136, 196, 0.09)";
export const njColorBackgroundDiscoveryTransparentPressedLight = "rgba(90, 0, 160, 0.20)";
export const njColorBackgroundDiscoveryTransparentPressedDark = "rgba(221, 139, 252, 0.22)";
export const njColorBackgroundDiscoveryTransparentSelectedLight = "rgba(93, 18, 155, 0.08)";
export const njColorBackgroundDiscoveryTransparentSelectedDark = "rgba(232, 136, 196, 0.09)";
export const njColorBackgroundInformationLight = "#007acd";
export const njColorBackgroundInformationDark = "#3d85ca";
export const njColorBackgroundInformationSecondaryLight = "#f6f8fd";
export const njColorBackgroundInformationSecondaryDark = "#111f2e";
export const njColorBackgroundInformationTertiaryLight = "#e7eefc";
export const njColorBackgroundInformationTertiaryDark = "#12263b";
export const njColorBackgroundInformationTertiaryHoveredLight = "#c0d4f9";
export const njColorBackgroundInformationTertiaryHoveredDark = "#0f3a5e";
export const njColorBackgroundInformationTertiaryFocusedLight = "#c0d4f9";
export const njColorBackgroundInformationTertiaryFocusedDark = "#0f3a5e";
export const njColorBackgroundInformationTertiaryPressedLight = "#7fadef";
export const njColorBackgroundInformationTertiaryPressedDark = "#04568e";
export const njColorBackgroundInformationTertiaryTransparentLight = "rgba(15, 85, 225, 0.1)";
export const njColorBackgroundInformationTertiaryTransparentDark = "rgba(1, 112, 229, 0.15)";
export const njColorBackgroundInformationHoveredLight = "#3c8cde";
export const njColorBackgroundInformationHoveredDark = "#77a6e1";
export const njColorBackgroundInformationPressedLight = "#007acd";
export const njColorBackgroundInformationPressedDark = "#a9c4ee";
export const njColorBackgroundInformationTransparentLight = "transparent";
export const njColorBackgroundInformationTransparentDark = "transparent";
export const njColorBackgroundInformationTransparentHoveredLight = "rgba(15, 85, 225, 0.1)";
export const njColorBackgroundInformationTransparentHoveredDark = "rgba(1, 112, 229, 0.15)";
export const njColorBackgroundInformationTransparentFocusedLight = "rgba(15, 85, 225, 0.1)";
export const njColorBackgroundInformationTransparentFocusedDark = "rgba(1, 112, 229, 0.15)";
export const njColorBackgroundInformationTransparentPressedLight = "rgba(3, 83, 231, 0.25)";
export const njColorBackgroundInformationTransparentPressedDark = "rgba(0, 139, 253, 0.29)";
export const njColorBackgroundInformationTransparentSelectedLight = "rgba(15, 85, 225, 0.1)";
export const njColorBackgroundInformationTransparentSelectedDark = "rgba(1, 112, 229, 0.15)";
export const njColorBackgroundPinkLight = "#e01b78";
export const njColorBackgroundPinkDark = "#e44284";
export const njColorBackgroundPinkSecondaryLight = "#fff4f9";
export const njColorBackgroundPinkSecondaryDark = "#211d1e";
export const njColorBackgroundPinkTertiaryLight = "#ffe9f3";
export const njColorBackgroundPinkTertiaryDark = "#2a2326";
export const njColorBackgroundPinkTertiaryHoveredLight = "#ffc1df";
export const njColorBackgroundPinkTertiaryHoveredDark = "#45333a";
export const njColorBackgroundPinkTertiaryFocusedLight = "#ffc1df";
export const njColorBackgroundPinkTertiaryFocusedDark = "#45333a";
export const njColorBackgroundPinkTertiaryPressedLight = "#ff80bd";
export const njColorBackgroundPinkTertiaryPressedDark = "#7b4159";
export const njColorBackgroundPinkHoveredLight = "#963563";
export const njColorBackgroundPinkHoveredDark = "#f779ad";
export const njColorBackgroundPinkPressedLight = "#5c3749";
export const njColorBackgroundPinkPressedDark = "#fbaccc";
export const njColorBackgroundRedLight = "#db3735";
export const njColorBackgroundRedDark = "#dd534b";
export const njColorBackgroundRedSecondaryLight = "#fff7f5";
export const njColorBackgroundRedSecondaryDark = "#321613";
export const njColorBackgroundRedTertiaryLight = "#ffeae5";
export const njColorBackgroundRedTertiaryDark = "#411a16";
export const njColorBackgroundRedTertiaryHoveredLight = "#ffc5b9";
export const njColorBackgroundRedTertiaryHoveredDark = "#63221e";
export const njColorBackgroundRedTertiaryFocusedLight = "#ffc5b9";
export const njColorBackgroundRedTertiaryFocusedDark = "#63221e";
export const njColorBackgroundRedTertiaryPressedLight = "#ff8979";
export const njColorBackgroundRedTertiaryPressedDark = "#95302c";
export const njColorBackgroundRedHoveredLight = "#aa2424";
export const njColorBackgroundRedHoveredDark = "#fa7e72";
export const njColorBackgroundRedPressedLight = "#7f1d1b";
export const njColorBackgroundRedPressedDark = "#ffaea3";
export const njColorBackgroundOrangeLight = "#bc5921";
export const njColorBackgroundOrangeDark = "#c3693a";
export const njColorBackgroundOrangeSecondaryLight = "#fff5ed";
export const njColorBackgroundOrangeSecondaryDark = "#2c1b12";
export const njColorBackgroundOrangeTertiaryLight = "#ffeada";
export const njColorBackgroundOrangeTertiaryDark = "#372015";
export const njColorBackgroundOrangeTertiaryHoveredLight = "#ffc89d";
export const njColorBackgroundOrangeTertiaryHoveredDark = "#542f1b";
export const njColorBackgroundOrangeTertiaryFocusedLight = "#ffc89d";
export const njColorBackgroundOrangeTertiaryFocusedDark = "#542f1b";
export const njColorBackgroundOrangeTertiaryPressedLight = "#ff8c47";
export const njColorBackgroundOrangeTertiaryPressedDark = "#7e4425";
export const njColorBackgroundOrangeHoveredLight = "#8e441a";
export const njColorBackgroundOrangeHoveredDark = "#f08750";
export const njColorBackgroundOrangePressedLight = "#693315";
export const njColorBackgroundOrangePressedDark = "#ffb182";
export const njColorBackgroundYellowLight = "#ffcc59";
export const njColorBackgroundYellowDark = "#c59d4a";
export const njColorBackgroundYellowSecondaryLight = "#fff7e0";
export const njColorBackgroundYellowSecondaryDark = "#251e13";
export const njColorBackgroundYellowTertiaryLight = "#ffeeb4";
export const njColorBackgroundYellowTertiaryDark = "#2c2416";
export const njColorBackgroundYellowTertiaryHoveredLight = "#ffcc59";
export const njColorBackgroundYellowTertiaryHoveredDark = "#43361d";
export const njColorBackgroundYellowTertiaryFocusedLight = "#ffcc59";
export const njColorBackgroundYellowTertiaryFocusedDark = "#43361d";
export const njColorBackgroundYellowTertiaryPressedLight = "#d5a334";
export const njColorBackgroundYellowTertiaryPressedDark = "#655027";
export const njColorBackgroundYellowHoveredLight = "#6f551d";
export const njColorBackgroundYellowHoveredDark = "#c59d4a";
export const njColorBackgroundYellowPressedLight = "#533f18";
export const njColorBackgroundYellowPressedDark = "#eabc5f";
export const njColorBackgroundGreenLight = "#008836";
export const njColorBackgroundGreenDark = "#30934b";
export const njColorBackgroundGreenSecondaryLight = "#f3faf4";
export const njColorBackgroundGreenSecondaryDark = "#102313";
export const njColorBackgroundGreenTertiaryLight = "#e2f3e3";
export const njColorBackgroundGreenTertiaryDark = "#112b16";
export const njColorBackgroundGreenTertiaryHoveredLight = "#aedfb3";
export const njColorBackgroundGreenTertiaryHoveredDark = "#12401e";
export const njColorBackgroundGreenTertiaryFocusedLight = "#aedfb3";
export const njColorBackgroundGreenTertiaryFocusedDark = "#12401e";
export const njColorBackgroundGreenTertiaryPressedLight = "#63be74";
export const njColorBackgroundGreenTertiaryPressedDark = "#12602b";
export const njColorBackgroundGreenHoveredLight = "#006725";
export const njColorBackgroundGreenHoveredDark = "#63b473";
export const njColorBackgroundGreenPressedLight = "#004d1c";
export const njColorBackgroundGreenPressedDark = "#99d0a0";
export const njColorBackgroundGreyLight = "#60798b";
export const njColorBackgroundGreyDark = "#718492";
export const njColorBackgroundGreySecondaryLight = "#f6f8f9";
export const njColorBackgroundGreySecondaryDark = "#1a1f23";
export const njColorBackgroundGreyTertiaryLight = "#ebeef1";
export const njColorBackgroundGreyTertiaryDark = "#1f262b";
export const njColorBackgroundGreyTertiaryHoveredLight = "#ccd4da";
export const njColorBackgroundGreyTertiaryHoveredDark = "#2e3942";
export const njColorBackgroundGreyTertiaryFocusedLight = "#ccd4da";
export const njColorBackgroundGreyTertiaryFocusedDark = "#2e3942";
export const njColorBackgroundGreyTertiaryPressedLight = "#9eadb8";
export const njColorBackgroundGreyTertiaryPressedDark = "#425663";
export const njColorBackgroundGreyHoveredLight = "#435c6e";
export const njColorBackgroundGreyHoveredDark = "#97a5af";
export const njColorBackgroundGreyPressedLight = "#334551";
export const njColorBackgroundGreyPressedDark = "#bcc4cb";
export const njColorBackgroundBlueLight = "#007acd";
export const njColorBackgroundBlueDark = "#3d85ca";
export const njColorBackgroundBlueSecondaryLight = "#f6f8fd";
export const njColorBackgroundBlueSecondaryDark = "#111f2e";
export const njColorBackgroundBlueTertiaryLight = "#e7eefc";
export const njColorBackgroundBlueTertiaryDark = "#12263b";
export const njColorBackgroundBlueTertiaryHoveredLight = "#c0d4f9";
export const njColorBackgroundBlueTertiaryHoveredDark = "#0f3a5e";
export const njColorBackgroundBlueTertiaryFocusedLight = "#c0d4f9";
export const njColorBackgroundBlueTertiaryFocusedDark = "#0f3a5e";
export const njColorBackgroundBlueTertiaryPressedLight = "#7fadef";
export const njColorBackgroundBlueTertiaryPressedDark = "#04568e";
export const njColorBackgroundBlueHoveredLight = "#005aa0";
export const njColorBackgroundBlueHoveredDark = "#77a6e1";
export const njColorBackgroundBluePressedLight = "#004478";
export const njColorBackgroundBluePressedDark = "#a9c4ee";
export const njColorBackgroundUltramarineLight = "#6372b2";
export const njColorBackgroundUltramarineDark = "#717fb5";
export const njColorBackgroundUltramarineSecondaryLight = "#f6f7fb";
export const njColorBackgroundUltramarineSecondaryDark = "#171d37";
export const njColorBackgroundUltramarineTertiaryLight = "#eceef7";
export const njColorBackgroundUltramarineTertiaryDark = "#17224b";
export const njColorBackgroundUltramarineTertiaryHoveredLight = "#ccd1e9";
export const njColorBackgroundUltramarineTertiaryHoveredDark = "#25346e";
export const njColorBackgroundUltramarineTertiaryFocusedLight = "#ccd1e9";
export const njColorBackgroundUltramarineTertiaryFocusedDark = "#25346e";
export const njColorBackgroundUltramarineTertiaryPressedLight = "#9fa9d4";
export const njColorBackgroundUltramarineTertiaryPressedDark = "#3f508e";
export const njColorBackgroundUltramarineHoveredLight = "#44549b";
export const njColorBackgroundUltramarineHoveredDark = "#96a2cc";
export const njColorBackgroundUltramarinePressedLight = "#2b3d83";
export const njColorBackgroundUltramarinePressedDark = "#bbc2df";
export const njColorBackgroundPurpleLight = "#9261b7";
export const njColorBackgroundPurpleDark = "#9971b8";
export const njColorBackgroundPurpleSecondaryLight = "#faf7fc";
export const njColorBackgroundPurpleSecondaryDark = "#201d22";
export const njColorBackgroundPurpleTertiaryLight = "#f2ecf7";
export const njColorBackgroundPurpleTertiaryDark = "#28232c";
export const njColorBackgroundPurpleTertiaryHoveredLight = "#deccec";
export const njColorBackgroundPurpleTertiaryHoveredDark = "#41324e";
export const njColorBackgroundPurpleTertiaryFocusedLight = "#deccec";
export const njColorBackgroundPurpleTertiaryFocusedDark = "#41324e";
export const njColorBackgroundPurpleTertiaryPressedLight = "#bf9ed9";
export const njColorBackgroundPurpleTertiaryPressedDark = "#694288";
export const njColorBackgroundPurpleHoveredLight = "#744299";
export const njColorBackgroundPurpleHoveredDark = "#b697cf";
export const njColorBackgroundPurplePressedLight = "#533669";
export const njColorBackgroundPurplePressedDark = "#d0bbe1";
export const njColorBackgroundTealLight = "#008480";
export const njColorBackgroundTealDark = "#328e8a";
export const njColorBackgroundTealSecondaryLight = "#f1fafa";
export const njColorBackgroundTealSecondaryDark = "#191f1f";
export const njColorBackgroundTealTertiaryLight = "#daf4f3";
export const njColorBackgroundTealTertiaryDark = "#1d2727";
export const njColorBackgroundTealTertiaryHoveredLight = "#95dfdd";
export const njColorBackgroundTealTertiaryHoveredDark = "#243c3b";
export const njColorBackgroundTealTertiaryFocusedLight = "#95dfdd";
export const njColorBackgroundTealTertiaryFocusedDark = "#243c3b";
export const njColorBackgroundTealTertiaryPressedLight = "#3dbcb8";
export const njColorBackgroundTealTertiaryPressedDark = "#275b59";
export const njColorBackgroundTealHoveredLight = "#0a6361";
export const njColorBackgroundTealHoveredDark = "#58b1ad";
export const njColorBackgroundTealPressedLight = "#154948";
export const njColorBackgroundTealPressedDark = "#8bcfcb";
export const njColorBackgroundLimeLight = "#6c7d02";
export const njColorBackgroundLimeDark = "#78892a";
export const njColorBackgroundLimeSecondaryLight = "#f8fad5";
export const njColorBackgroundLimeSecondaryDark = "#1e2010";
export const njColorBackgroundLimeTertiaryLight = "#eef5a8";
export const njColorBackgroundLimeTertiaryDark = "#242713";
export const njColorBackgroundLimeTertiaryHoveredLight = "#c7de43";
export const njColorBackgroundLimeTertiaryHoveredDark = "#353b18";
export const njColorBackgroundLimeTertiaryFocusedLight = "#c7de43";
export const njColorBackgroundLimeTertiaryFocusedDark = "#353b18";
export const njColorBackgroundLimeTertiaryPressedLight = "#9db603";
export const njColorBackgroundLimeTertiaryPressedDark = "#4e581e";
export const njColorBackgroundLimeHoveredLight = "#525f0a";
export const njColorBackgroundLimeHoveredDark = "#97ac37";
export const njColorBackgroundLimePressedLight = "#3e460e";
export const njColorBackgroundLimePressedDark = "#b7cd4f";
export const njColorBaseBrand100Light = "#f6f8fd";
export const njColorBaseBrand100Dark = "#111f2e";
export const njColorBaseBrand200Light = "#e7eefc";
export const njColorBaseBrand200Dark = "#12263b";
export const njColorBaseBrand300Light = "#c0d4f9";
export const njColorBaseBrand300Dark = "#0f3a5e";
export const njColorBaseBrand400Light = "#7fadef";
export const njColorBaseBrand400Dark = "#04568e";
export const njColorBaseBrand500Light = "#3c8cde";
export const njColorBaseBrand500Dark = "#1c73b6";
export const njColorBaseBrand600Light = "#007acd";
export const njColorBaseBrand600Dark = "#3d85ca";
export const njColorBaseBrand700Light = "#005aa0";
export const njColorBaseBrand700Dark = "#77a6e1";
export const njColorBaseBrand800Light = "#004478";
export const njColorBaseBrand800Dark = "#a9c4ee";
export const njColorBaseBrand900Light = "#0b2d4e";
export const njColorBaseBrand900Dark = "#dbe5f8";
export const njColorBaseBrandAlpha0Light = "transparent";
export const njColorBaseBrandAlpha0Dark = "transparent";
export const njColorBaseBrandAlpha100Light = "rgba(30, 80, 205, 0.04)";
export const njColorBaseBrandAlpha100Dark = "rgba(1, 55, 114, 0.2)";
export const njColorBaseBrandAlpha200Light = "rgba(15, 85, 225, 0.1)";
export const njColorBaseBrandAlpha200Dark = "rgba(1, 112, 229, 0.15)";
export const njColorBaseBrandAlpha300Light = "rgba(3, 83, 231, 0.25)";
export const njColorBaseBrandAlpha300Dark = "rgba(0, 139, 253, 0.29)";
export const njColorBaseNeutral0Light = "#ffffff";
export const njColorBaseNeutral0Dark = "#15191d";
export const njColorBaseNeutral100Light = "#f6f8f9";
export const njColorBaseNeutral100Dark = "#1a1f23";
export const njColorBaseNeutral200Light = "#ebeef1";
export const njColorBaseNeutral200Dark = "#1f262b";
export const njColorBaseNeutral300Light = "#ccd4da";
export const njColorBaseNeutral300Dark = "#2e3942";
export const njColorBaseNeutral400Light = "#9eadb8";
export const njColorBaseNeutral400Dark = "#425663";
export const njColorBaseNeutral500Light = "#778c9b";
export const njColorBaseNeutral500Dark = "#5c7181";
export const njColorBaseNeutral600Light = "#60798b";
export const njColorBaseNeutral600Dark = "#718492";
export const njColorBaseNeutral700Light = "#435c6e";
export const njColorBaseNeutral700Dark = "#97a5af";
export const njColorBaseNeutral800Light = "#334551";
export const njColorBaseNeutral800Dark = "#bcc4cb";
export const njColorBaseNeutral900Light = "#232d35";
export const njColorBaseNeutral900Dark = "#e2e5e9";
export const njColorBaseNeutral1000Light = "#171d21";
export const njColorBaseNeutral1000Dark = "#fafafb";
export const njColorBaseNeutralAlpha0Light = "transparent";
export const njColorBaseNeutralAlpha0Dark = "transparent";
export const njColorBaseNeutralAlpha100Light = "rgba(30, 80, 105, 0.04)";
export const njColorBaseNeutralAlpha100Dark = "rgba(188, 225, 229, 0.03)";
export const njColorBaseNeutralAlpha200Light = "rgba(5, 43, 80, 0.08)";
export const njColorBaseNeutralAlpha200Dark = "rgba(164, 211, 229, 0.07)";
export const njColorBaseNeutralAlpha300Light = "rgba(0, 40, 70, 0.2)";
export const njColorBaseNeutralAlpha300Dark = "rgba(168, 213, 247, 0.17)";
export const njColorBaseNeutralAlpha400Light = "rgba(6, 45, 73, 0.39)";
export const njColorBaseNeutralAlpha400Dark = "rgba(166, 222, 255, 0.31)";
export const njColorBaseNeutralAlpha500Light = "rgba(3, 42, 70, 0.54)";
export const njColorBaseNeutralAlpha500Dark = "rgba(179, 221, 251, 0.45)";
export const njColorBaseDanger100Light = "#fff7f5";
export const njColorBaseDanger100Dark = "#321613";
export const njColorBaseDanger200Light = "#ffeae5";
export const njColorBaseDanger200Dark = "#411a16";
export const njColorBaseDanger300Light = "#ffc5b9";
export const njColorBaseDanger300Dark = "#63221e";
export const njColorBaseDanger400Light = "#ff8979";
export const njColorBaseDanger400Dark = "#95302c";
export const njColorBaseDanger500Light = "#f34e46";
export const njColorBaseDanger500Dark = "#c3423c";
export const njColorBaseDanger600Light = "#db3735";
export const njColorBaseDanger600Dark = "#dd534b";
export const njColorBaseDanger700Light = "#aa2424";
export const njColorBaseDanger700Dark = "#fa7e72";
export const njColorBaseDanger800Light = "#7f1d1b";
export const njColorBaseDanger800Dark = "#ffaea3";
export const njColorBaseDanger900Light = "#521814";
export const njColorBaseDanger900Dark = "#ffddd7";
export const njColorBaseDangerAlpha0Light = "transparent";
export const njColorBaseDangerAlpha0Dark = "transparent";
export const njColorBaseDangerAlpha100Light = "rgba(255, 55, 5, 0.04)";
export const njColorBaseDangerAlpha100Dark = "rgba(104, 16, 0, 0.35)";
export const njColorBaseDangerAlpha200Light = "rgba(255, 64, 19, 0.11)";
export const njColorBaseDangerAlpha200Dark = "rgba(197, 29, 1, 0.25)";
export const njColorBaseDangerAlpha300Light = "rgba(255, 48, 5, 0.28)";
export const njColorBaseDangerAlpha300Dark = "rgba(250, 51, 32, 0.34)";
export const njColorBaseSuccess100Light = "#f3faf4";
export const njColorBaseSuccess100Dark = "#102313";
export const njColorBaseSuccess200Light = "#e2f3e3";
export const njColorBaseSuccess200Dark = "#112b16";
export const njColorBaseSuccess300Light = "#aedfb3";
export const njColorBaseSuccess300Dark = "#12401e";
export const njColorBaseSuccess400Light = "#63be74";
export const njColorBaseSuccess400Dark = "#12602b";
export const njColorBaseSuccess500Light = "#199d47";
export const njColorBaseSuccess500Dark = "#1d7f3c";
export const njColorBaseSuccess600Light = "#008836";
export const njColorBaseSuccess600Dark = "#30934b";
export const njColorBaseSuccess700Light = "#006725";
export const njColorBaseSuccess700Dark = "#63b473";
export const njColorBaseSuccess800Light = "#004d1c";
export const njColorBaseSuccess800Dark = "#99d0a0";
export const njColorBaseSuccess900Light = "#0a3315";
export const njColorBaseSuccess900Dark = "#d4ebd6";
export const njColorBaseSuccessAlpha0Light = "transparent";
export const njColorBaseSuccessAlpha0Dark = "transparent";
export const njColorBaseSuccessAlpha100Light = "rgba(35, 155, 35, 0.05)";
export const njColorBaseSuccessAlpha100Dark = "rgba(7, 54, 0, 0.35)";
export const njColorBaseSuccessAlpha200Light = "rgba(13, 155, 22, 0.12)";
export const njColorBaseSuccessAlpha200Dark = "rgba(5, 97, 1, 0.25)";
export const njColorBaseSuccessAlpha300Light = "rgba(2, 155, 18, 0.32)";
export const njColorBaseSuccessAlpha300Dark = "rgba(3, 254, 35, 0.17)";
export const njColorBaseWarning100Light = "#fff5ed";
export const njColorBaseWarning100Dark = "#2c1b12";
export const njColorBaseWarning200Light = "#ffeada";
export const njColorBaseWarning200Dark = "#372015";
export const njColorBaseWarning300Light = "#ffc89d";
export const njColorBaseWarning300Dark = "#542f1b";
export const njColorBaseWarning400Light = "#ff8c47";
export const njColorBaseWarning400Dark = "#7e4425";
export const njColorBaseWarning500Light = "#d96929";
export const njColorBaseWarning500Dark = "#a85a31";
export const njColorBaseWarning600Light = "#bc5921";
export const njColorBaseWarning600Dark = "#c3693a";
export const njColorBaseWarning700Light = "#8e441a";
export const njColorBaseWarning700Dark = "#f08750";
export const njColorBaseWarning800Light = "#693315";
export const njColorBaseWarning800Dark = "#ffb182";
export const njColorBaseWarning900Light = "#442312";
export const njColorBaseWarning900Dark = "#ffdfc8";
export const njColorBaseWarningAlpha0Light = "transparent";
export const njColorBaseWarningAlpha0Dark = "transparent";
export const njColorBaseWarningAlpha100Light = "rgba(255, 130, 30, 0.08)";
export const njColorBaseWarningAlpha100Dark = "rgba(82, 30, 0, 0.38)";
export const njColorBaseWarningAlpha200Light = "rgba(255, 115, 8, 0.15)";
export const njColorBaseWarningAlpha200Dark = "rgba(142, 50, 0, 0.28)";
export const njColorBaseWarningAlpha300Light = "rgba(255, 114, 4, 0.39)";
export const njColorBaseWarningAlpha300Dark = "rgba(254, 106, 22, 0.27)";
export const njColorBaseDiscovery100Light = "#faf7fc";
export const njColorBaseDiscovery100Dark = "#201d22";
export const njColorBaseDiscovery200Light = "#f2ecf7";
export const njColorBaseDiscovery200Dark = "#28232c";
export const njColorBaseDiscovery300Light = "#deccec";
export const njColorBaseDiscovery300Dark = "#41324e";
export const njColorBaseDiscovery400Light = "#bf9ed9";
export const njColorBaseDiscovery400Dark = "#694288";
export const njColorBaseDiscovery500Light = "#a377c5";
export const njColorBaseDiscovery500Dark = "#875da9";
export const njColorBaseDiscovery600Light = "#9261b7";
export const njColorBaseDiscovery600Dark = "#9971b8";
export const njColorBaseDiscovery700Light = "#744299";
export const njColorBaseDiscovery700Dark = "#b697cf";
export const njColorBaseDiscovery800Light = "#533669";
export const njColorBaseDiscovery800Dark = "#d0bbe1";
export const njColorBaseDiscovery900Light = "#312838";
export const njColorBaseDiscovery900Dark = "#ebe1f2";
export const njColorBaseDiscoveryAlpha0Light = "transparent";
export const njColorBaseDiscoveryAlpha0Dark = "transparent";
export const njColorBaseDiscoveryAlpha100Light = "rgba(130, 55, 180, 0.04)";
export const njColorBaseDiscoveryAlpha100Dark = "rgba(241, 105, 129, 0.05)";
export const njColorBaseDiscoveryAlpha200Light = "rgba(93, 18, 155, 0.08)";
export const njColorBaseDiscoveryAlpha200Dark = "rgba(232, 136, 196, 0.09)";
export const njColorBaseDiscoveryAlpha300Light = "rgba(90, 0, 160, 0.20)";
export const njColorBaseDiscoveryAlpha300Dark = "rgba(221, 139, 252, 0.22)";
export const njColorBaseInformation100Light = "#f6f8fd";
export const njColorBaseInformation100Dark = "#111f2e";
export const njColorBaseInformation200Light = "#e7eefc";
export const njColorBaseInformation200Dark = "#12263b";
export const njColorBaseInformation300Light = "#c0d4f9";
export const njColorBaseInformation300Dark = "#0f3a5e";
export const njColorBaseInformation400Light = "#7fadef";
export const njColorBaseInformation400Dark = "#04568e";
export const njColorBaseInformation500Light = "#3c8cde";
export const njColorBaseInformation500Dark = "#1c73b6";
export const njColorBaseInformation600Light = "#007acd";
export const njColorBaseInformation600Dark = "#3d85ca";
export const njColorBaseInformation700Light = "#005aa0";
export const njColorBaseInformation700Dark = "#77a6e1";
export const njColorBaseInformation800Light = "#004478";
export const njColorBaseInformation800Dark = "#a9c4ee";
export const njColorBaseInformation900Light = "#0b2d4e";
export const njColorBaseInformation900Dark = "#dbe5f8";
export const njColorBaseInformationAlpha0Light = "transparent";
export const njColorBaseInformationAlpha0Dark = "transparent";
export const njColorBaseInformationAlpha100Light = "rgba(30, 80, 205, 0.04)";
export const njColorBaseInformationAlpha100Dark = "rgba(1, 55, 114, 0.2)";
export const njColorBaseInformationAlpha200Light = "rgba(15, 85, 225, 0.1)";
export const njColorBaseInformationAlpha200Dark = "rgba(1, 112, 229, 0.15)";
export const njColorBaseInformationAlpha300Light = "rgba(3, 83, 231, 0.25)";
export const njColorBaseInformationAlpha300Dark = "rgba(0, 139, 253, 0.29)";
export const njColorBaseGradientLight = "linear-gradient(90deg, #00AAFF 0%, #23d2b5 100%)";
export const njColorBaseGradientDark = "linear-gradient(90deg, #00AAFF 0%, #23d2b5 100%)";
export const njColorBorderMinimalLight = "#ebeef1";
export const njColorBorderMinimalDark = "#1f262b";
export const njColorBorderSubtleLight = "#ccd4da";
export const njColorBorderSubtleDark = "#2e3942";
export const njColorBorderSubtleTransparentLight = "rgba(0, 40, 70, 0.2)";
export const njColorBorderSubtleTransparentDark = "rgba(168, 213, 247, 0.17)";
export const njColorBorderModerateLight = "#9eadb8";
export const njColorBorderModerateDark = "#425663";
export const njColorBorderBoldLight = "#60798b";
export const njColorBorderBoldDark = "#718492";
export const njColorBorderStrongLight = "#435c6e";
export const njColorBorderStrongDark = "#97a5af";
export const njColorBorderContrastLight = "#232d35";
export const njColorBorderContrastDark = "#e2e5e9";
export const njColorBorderHoveredLight = "#007acd";
export const njColorBorderHoveredDark = "#3d85ca";
export const njColorBorderFocusedLight = "#007acd";
export const njColorBorderFocusedDark = "#3d85ca";
export const njColorBorderBrandModerateLight = "#7fadef";
export const njColorBorderBrandModerateDark = "#04568e";
export const njColorBorderBrandBoldLight = "#007acd";
export const njColorBorderBrandBoldDark = "#3d85ca";
export const njColorBorderBrandStrongLight = "#005aa0";
export const njColorBorderBrandStrongDark = "#77a6e1";
export const njColorBorderInverseLight = "#ffffff";
export const njColorBorderInverseDark = "#15191d";
export const njColorBorderInverseFocusedLight = "#ffffff";
export const njColorBorderInverseFocusedDark = "#15191d";
export const njColorBorderDangerSubtleLight = "#ffc5b9";
export const njColorBorderDangerSubtleDark = "#63221e";
export const njColorBorderDangerModerateLight = "#ff8979";
export const njColorBorderDangerModerateDark = "#95302c";
export const njColorBorderDangerBoldLight = "#db3735";
export const njColorBorderDangerBoldDark = "#dd534b";
export const njColorBorderDangerStrongLight = "#aa2424";
export const njColorBorderDangerStrongDark = "#fa7e72";
export const njColorBorderWarningSubtleLight = "#ffc89d";
export const njColorBorderWarningSubtleDark = "#542f1b";
export const njColorBorderWarningModerateLight = "#ff8c47";
export const njColorBorderWarningModerateDark = "#7e4425";
export const njColorBorderWarningBoldLight = "#bc5921";
export const njColorBorderWarningBoldDark = "#c3693a";
export const njColorBorderWarningStrongLight = "#8e441a";
export const njColorBorderWarningStrongDark = "#f08750";
export const njColorBorderSuccessSubtleLight = "#aedfb3";
export const njColorBorderSuccessSubtleDark = "#12401e";
export const njColorBorderSuccessModerateLight = "#63be74";
export const njColorBorderSuccessModerateDark = "#12602b";
export const njColorBorderSuccessBoldLight = "#008836";
export const njColorBorderSuccessBoldDark = "#30934b";
export const njColorBorderSuccessStrongLight = "#006725";
export const njColorBorderSuccessStrongDark = "#63b473";
export const njColorBorderDiscoverySubtleLight = "#deccec";
export const njColorBorderDiscoverySubtleDark = "#41324e";
export const njColorBorderDiscoveryModerateLight = "#bf9ed9";
export const njColorBorderDiscoveryModerateDark = "#694288";
export const njColorBorderDiscoveryBoldLight = "#9261b7";
export const njColorBorderDiscoveryBoldDark = "#9971b8";
export const njColorBorderDiscoveryStrongLight = "#744299";
export const njColorBorderDiscoveryStrongDark = "#b697cf";
export const njColorBorderInformationSubtleLight = "#c0d4f9";
export const njColorBorderInformationSubtleDark = "#0f3a5e";
export const njColorBorderInformationModerateLight = "#7fadef";
export const njColorBorderInformationModerateDark = "#04568e";
export const njColorBorderInformationBoldLight = "#007acd";
export const njColorBorderInformationBoldDark = "#3d85ca";
export const njColorBorderInformationStrongLight = "#005aa0";
export const njColorBorderInformationStrongDark = "#77a6e1";
export const njColorBorderPinkModerateLight = "#ff80bd";
export const njColorBorderPinkModerateDark = "#7b4159";
export const njColorBorderPinkBoldLight = "#e01b78";
export const njColorBorderPinkBoldDark = "#e44284";
export const njColorBorderPinkStrongLight = "#963563";
export const njColorBorderPinkStrongDark = "#f779ad";
export const njColorBorderRedModerateLight = "#ff8979";
export const njColorBorderRedModerateDark = "#95302c";
export const njColorBorderRedBoldLight = "#db3735";
export const njColorBorderRedBoldDark = "#dd534b";
export const njColorBorderRedStrongLight = "#aa2424";
export const njColorBorderRedStrongDark = "#fa7e72";
export const njColorBorderOrangeModerateLight = "#ff8c47";
export const njColorBorderOrangeModerateDark = "#7e4425";
export const njColorBorderOrangeBoldLight = "#bc5921";
export const njColorBorderOrangeBoldDark = "#c3693a";
export const njColorBorderOrangeStrongLight = "#8e441a";
export const njColorBorderOrangeStrongDark = "#f08750";
export const njColorBorderYellowModerateLight = "#d5a334";
export const njColorBorderYellowModerateDark = "#655027";
export const njColorBorderYellowBoldLight = "#947023";
export const njColorBorderYellowBoldDark = "#9d7d39";
export const njColorBorderYellowStrongLight = "#6f551d";
export const njColorBorderYellowStrongDark = "#c59d4a";
export const njColorBorderGreenModerateLight = "#63be74";
export const njColorBorderGreenModerateDark = "#12602b";
export const njColorBorderGreenBoldLight = "#008836";
export const njColorBorderGreenBoldDark = "#30934b";
export const njColorBorderGreenStrongLight = "#006725";
export const njColorBorderGreenStrongDark = "#63b473";
export const njColorBorderGreyModerateLight = "#9eadb8";
export const njColorBorderGreyModerateDark = "#425663";
export const njColorBorderGreyBoldLight = "#60798b";
export const njColorBorderGreyBoldDark = "#718492";
export const njColorBorderGreyStrongLight = "#435c6e";
export const njColorBorderGreyStrongDark = "#97a5af";
export const njColorBorderBlueModerateLight = "#7fadef";
export const njColorBorderBlueModerateDark = "#04568e";
export const njColorBorderBlueBoldLight = "#007acd";
export const njColorBorderBlueBoldDark = "#3d85ca";
export const njColorBorderBlueStrongLight = "#005aa0";
export const njColorBorderBlueStrongDark = "#77a6e1";
export const njColorBorderUltramarineModerateLight = "#9fa9d4";
export const njColorBorderUltramarineModerateDark = "#3f508e";
export const njColorBorderUltramarineBoldLight = "#6372b2";
export const njColorBorderUltramarineBoldDark = "#717fb5";
export const njColorBorderUltramarineStrongLight = "#44549b";
export const njColorBorderUltramarineStrongDark = "#96a2cc";
export const njColorBorderPurpleModerateLight = "#bf9ed9";
export const njColorBorderPurpleModerateDark = "#694288";
export const njColorBorderPurpleBoldLight = "#9261b7";
export const njColorBorderPurpleBoldDark = "#9971b8";
export const njColorBorderPurpleStrongLight = "#744299";
export const njColorBorderPurpleStrongDark = "#b697cf";
export const njColorBorderTealModerateLight = "#3dbcb8";
export const njColorBorderTealModerateDark = "#275b59";
export const njColorBorderTealBoldLight = "#008480";
export const njColorBorderTealBoldDark = "#328e8a";
export const njColorBorderTealStrongLight = "#0a6361";
export const njColorBorderTealStrongDark = "#58b1ad";
export const njColorBorderLimeModerateLight = "#9db603";
export const njColorBorderLimeModerateDark = "#4e581e";
export const njColorBorderLimeBoldLight = "#6c7d02";
export const njColorBorderLimeBoldDark = "#78892a";
export const njColorBorderLimeStrongLight = "#525f0a";
export const njColorBorderLimeStrongDark = "#97ac37";
export const njColorIconTertiaryLight = "#778c9b";
export const njColorIconTertiaryDark = "#5c7181";
export const njColorIconSecondaryLight = "#60798b";
export const njColorIconSecondaryDark = "#718492";
export const njColorIconSecondaryHoveredLight = "#435c6e";
export const njColorIconSecondaryHoveredDark = "#97a5af";
export const njColorIconSecondaryFocusedLight = "#435c6e";
export const njColorIconSecondaryFocusedDark = "#97a5af";
export const njColorIconSecondaryPressedLight = "#334551";
export const njColorIconSecondaryPressedDark = "#bcc4cb";
export const njColorIconPrimaryLight = "#334551";
export const njColorIconPrimaryDark = "#bcc4cb";
export const njColorIconInversePrimaryLight = "#ffffff";
export const njColorIconInversePrimaryDark = "#15191d";
export const njColorIconBrandSecondaryLight = "#007acd";
export const njColorIconBrandSecondaryDark = "#3d85ca";
export const njColorIconBrandSecondaryHoveredLight = "#005aa0";
export const njColorIconBrandSecondaryHoveredDark = "#77a6e1";
export const njColorIconBrandSecondaryFocusedLight = "#005aa0";
export const njColorIconBrandSecondaryFocusedDark = "#77a6e1";
export const njColorIconBrandSecondaryPressedLight = "#004478";
export const njColorIconBrandSecondaryPressedDark = "#a9c4ee";
export const njColorIconBrandPrimaryLight = "#004478";
export const njColorIconBrandPrimaryDark = "#a9c4ee";
export const njColorIconDangerSecondaryLight = "#db3735";
export const njColorIconDangerSecondaryDark = "#dd534b";
export const njColorIconDangerSecondaryHoveredLight = "#aa2424";
export const njColorIconDangerSecondaryHoveredDark = "#fa7e72";
export const njColorIconDangerSecondaryFocusedLight = "#aa2424";
export const njColorIconDangerSecondaryFocusedDark = "#fa7e72";
export const njColorIconDangerSecondaryPressedLight = "#7f1d1b";
export const njColorIconDangerSecondaryPressedDark = "#ffaea3";
export const njColorIconDangerPrimaryLight = "#7f1d1b";
export const njColorIconDangerPrimaryDark = "#ffaea3";
export const njColorIconPinkSecondaryLight = "#e01b78";
export const njColorIconPinkSecondaryDark = "#e44284";
export const njColorIconPinkSecondaryHoveredLight = "#963563";
export const njColorIconPinkSecondaryHoveredDark = "#f779ad";
export const njColorIconPinkSecondaryFocusedLight = "#963563";
export const njColorIconPinkSecondaryFocusedDark = "#f779ad";
export const njColorIconPinkSecondaryPressedLight = "#5c3749";
export const njColorIconPinkSecondaryPressedDark = "#fbaccc";
export const njColorIconPinkPrimaryLight = "#5c3749";
export const njColorIconPinkPrimaryDark = "#fbaccc";
export const njColorIconRedSecondaryLight = "#db3735";
export const njColorIconRedSecondaryDark = "#dd534b";
export const njColorIconRedSecondaryHoveredLight = "#aa2424";
export const njColorIconRedSecondaryHoveredDark = "#fa7e72";
export const njColorIconRedSecondaryFocusedLight = "#aa2424";
export const njColorIconRedSecondaryFocusedDark = "#fa7e72";
export const njColorIconRedSecondaryPressedLight = "#7f1d1b";
export const njColorIconRedSecondaryPressedDark = "#ffaea3";
export const njColorIconRedPrimaryLight = "#7f1d1b";
export const njColorIconRedPrimaryDark = "#ffaea3";
export const njColorIconOrangeSecondaryLight = "#bc5921";
export const njColorIconOrangeSecondaryDark = "#c3693a";
export const njColorIconOrangeSecondaryHoveredLight = "#8e441a";
export const njColorIconOrangeSecondaryHoveredDark = "#f08750";
export const njColorIconOrangeSecondaryFocusedLight = "#8e441a";
export const njColorIconOrangeSecondaryFocusedDark = "#f08750";
export const njColorIconOrangeSecondaryPressedLight = "#693315";
export const njColorIconOrangeSecondaryPressedDark = "#ffb182";
export const njColorIconOrangePrimaryLight = "#693315";
export const njColorIconOrangePrimaryDark = "#ffb182";
export const njColorIconYellowSecondaryLight = "#947023";
export const njColorIconYellowSecondaryDark = "#9d7d39";
export const njColorIconYellowSecondaryHoveredLight = "#6f551d";
export const njColorIconYellowSecondaryHoveredDark = "#c59d4a";
export const njColorIconYellowSecondaryFocusedLight = "#6f551d";
export const njColorIconYellowSecondaryFocusedDark = "#c59d4a";
export const njColorIconYellowSecondaryPressedLight = "#533f18";
export const njColorIconYellowSecondaryPressedDark = "#eabc5f";
export const njColorIconYellowPrimaryLight = "#533f18";
export const njColorIconYellowPrimaryDark = "#eabc5f";
export const njColorIconGreenSecondaryLight = "#008836";
export const njColorIconGreenSecondaryDark = "#30934b";
export const njColorIconGreenSecondaryHoveredLight = "#006725";
export const njColorIconGreenSecondaryHoveredDark = "#63b473";
export const njColorIconGreenSecondaryFocusedLight = "#006725";
export const njColorIconGreenSecondaryFocusedDark = "#63b473";
export const njColorIconGreenSecondaryPressedLight = "#004d1c";
export const njColorIconGreenSecondaryPressedDark = "#99d0a0";
export const njColorIconGreenPrimaryLight = "#004d1c";
export const njColorIconGreenPrimaryDark = "#99d0a0";
export const njColorIconGreySecondaryLight = "#60798b";
export const njColorIconGreySecondaryDark = "#718492";
export const njColorIconGreySecondaryHoveredLight = "#435c6e";
export const njColorIconGreySecondaryHoveredDark = "#97a5af";
export const njColorIconGreySecondaryFocusedLight = "#435c6e";
export const njColorIconGreySecondaryFocusedDark = "#97a5af";
export const njColorIconGreySecondaryPressedLight = "#334551";
export const njColorIconGreySecondaryPressedDark = "#bcc4cb";
export const njColorIconGreyPrimaryLight = "#334551";
export const njColorIconGreyPrimaryDark = "#bcc4cb";
export const njColorIconBlueSecondaryLight = "#007acd";
export const njColorIconBlueSecondaryDark = "#3d85ca";
export const njColorIconBlueSecondaryHoveredLight = "#005aa0";
export const njColorIconBlueSecondaryHoveredDark = "#77a6e1";
export const njColorIconBlueSecondaryFocusedLight = "#005aa0";
export const njColorIconBlueSecondaryFocusedDark = "#77a6e1";
export const njColorIconBlueSecondaryPressedLight = "#004478";
export const njColorIconBlueSecondaryPressedDark = "#a9c4ee";
export const njColorIconBluePrimaryLight = "#004478";
export const njColorIconBluePrimaryDark = "#a9c4ee";
export const njColorIconUltramarineSecondaryLight = "#6372b2";
export const njColorIconUltramarineSecondaryDark = "#717fb5";
export const njColorIconUltramarineSecondaryHoveredLight = "#44549b";
export const njColorIconUltramarineSecondaryHoveredDark = "#96a2cc";
export const njColorIconUltramarineSecondaryFocusedLight = "#44549b";
export const njColorIconUltramarineSecondaryFocusedDark = "#96a2cc";
export const njColorIconUltramarineSecondaryPressedLight = "#2b3d83";
export const njColorIconUltramarineSecondaryPressedDark = "#bbc2df";
export const njColorIconUltramarinePrimaryLight = "#2b3d83";
export const njColorIconUltramarinePrimaryDark = "#bbc2df";
export const njColorIconPurpleSecondaryLight = "#9261b7";
export const njColorIconPurpleSecondaryDark = "#9971b8";
export const njColorIconPurpleSecondaryHoveredLight = "#744299";
export const njColorIconPurpleSecondaryHoveredDark = "#b697cf";
export const njColorIconPurpleSecondaryFocusedLight = "#744299";
export const njColorIconPurpleSecondaryFocusedDark = "#b697cf";
export const njColorIconPurpleSecondaryPressedLight = "#533669";
export const njColorIconPurpleSecondaryPressedDark = "#d0bbe1";
export const njColorIconPurplePrimaryLight = "#533669";
export const njColorIconPurplePrimaryDark = "#d0bbe1";
export const njColorIconTealSecondaryLight = "#008480";
export const njColorIconTealSecondaryDark = "#328e8a";
export const njColorIconTealSecondaryHoveredLight = "#0a6361";
export const njColorIconTealSecondaryHoveredDark = "#58b1ad";
export const njColorIconTealSecondaryFocusedLight = "#0a6361";
export const njColorIconTealSecondaryFocusedDark = "#58b1ad";
export const njColorIconTealSecondaryPressedLight = "#154948";
export const njColorIconTealSecondaryPressedDark = "#8bcfcb";
export const njColorIconTealPrimaryLight = "#154948";
export const njColorIconTealPrimaryDark = "#8bcfcb";
export const njColorIconLimeSecondaryLight = "#6c7d02";
export const njColorIconLimeSecondaryDark = "#78892a";
export const njColorIconLimeSecondaryHoveredLight = "#525f0a";
export const njColorIconLimeSecondaryHoveredDark = "#97ac37";
export const njColorIconLimeSecondaryFocusedLight = "#525f0a";
export const njColorIconLimeSecondaryFocusedDark = "#97ac37";
export const njColorIconLimeSecondaryPressedLight = "#3e460e";
export const njColorIconLimeSecondaryPressedDark = "#b7cd4f";
export const njColorIconLimePrimaryLight = "#3e460e";
export const njColorIconLimePrimaryDark = "#b7cd4f";
export const njColorPaletteGrey0Light = "#ffffff";
export const njColorPaletteGrey0Dark = "#15191d";
export const njColorPaletteGrey100Light = "#f6f8f9";
export const njColorPaletteGrey100Dark = "#1a1f23";
export const njColorPaletteGrey200Light = "#ebeef1";
export const njColorPaletteGrey200Dark = "#1f262b";
export const njColorPaletteGrey300Light = "#ccd4da";
export const njColorPaletteGrey300Dark = "#2e3942";
export const njColorPaletteGrey400Light = "#9eadb8";
export const njColorPaletteGrey400Dark = "#425663";
export const njColorPaletteGrey500Light = "#778c9b";
export const njColorPaletteGrey500Dark = "#5c7181";
export const njColorPaletteGrey600Light = "#60798b";
export const njColorPaletteGrey600Dark = "#718492";
export const njColorPaletteGrey700Light = "#435c6e";
export const njColorPaletteGrey700Dark = "#97a5af";
export const njColorPaletteGrey800Light = "#334551";
export const njColorPaletteGrey800Dark = "#bcc4cb";
export const njColorPaletteGrey900Light = "#232d35";
export const njColorPaletteGrey900Dark = "#e2e5e9";
export const njColorPaletteGrey1000Light = "#171d21";
export const njColorPaletteGrey1000Dark = "#fafafb";
export const njColorPaletteGreyAlpha0Light = "transparent";
export const njColorPaletteGreyAlpha0Dark = "transparent";
export const njColorPaletteGreyAlpha100Light = "rgba(30, 80, 105, 0.04)";
export const njColorPaletteGreyAlpha100Dark = "rgba(188, 225, 229, 0.03)";
export const njColorPaletteGreyAlpha200Light = "rgba(5, 43, 80, 0.08)";
export const njColorPaletteGreyAlpha200Dark = "rgba(164, 211, 229, 0.07)";
export const njColorPaletteGreyAlpha300Light = "rgba(0, 40, 70, 0.2)";
export const njColorPaletteGreyAlpha300Dark = "rgba(168, 213, 247, 0.17)";
export const njColorPaletteGreyAlpha400Light = "rgba(6, 45, 73, 0.39)";
export const njColorPaletteGreyAlpha400Dark = "rgba(166, 222, 255, 0.31)";
export const njColorPaletteGreyAlpha500Light = "rgba(3, 42, 70, 0.54)";
export const njColorPaletteGreyAlpha500Dark = "rgba(179, 221, 251, 0.45)";
export const njColorPalettePink100Light = "#fff4f9";
export const njColorPalettePink100Dark = "#211d1e";
export const njColorPalettePink200Light = "#ffe9f3";
export const njColorPalettePink200Dark = "#2a2326";
export const njColorPalettePink300Light = "#ffc1df";
export const njColorPalettePink300Dark = "#45333a";
export const njColorPalettePink400Light = "#ff80bd";
export const njColorPalettePink400Dark = "#7b4159";
export const njColorPalettePink500Light = "#fb3492";
export const njColorPalettePink500Dark = "#c13c72";
export const njColorPalettePink600Light = "#e01b78";
export const njColorPalettePink600Dark = "#e44284";
export const njColorPalettePink700Light = "#963563";
export const njColorPalettePink700Dark = "#f779ad";
export const njColorPalettePink800Light = "#5c3749";
export const njColorPalettePink800Dark = "#fbaccc";
export const njColorPalettePink900Light = "#34282e";
export const njColorPalettePink900Dark = "#fddcea";
export const njColorPaletteRed100Light = "#fff7f5";
export const njColorPaletteRed100Dark = "#321613";
export const njColorPaletteRed200Light = "#ffeae5";
export const njColorPaletteRed200Dark = "#411a16";
export const njColorPaletteRed300Light = "#ffc5b9";
export const njColorPaletteRed300Dark = "#63221e";
export const njColorPaletteRed400Light = "#ff8979";
export const njColorPaletteRed400Dark = "#95302c";
export const njColorPaletteRed500Light = "#f34e46";
export const njColorPaletteRed500Dark = "#c3423c";
export const njColorPaletteRed600Light = "#db3735";
export const njColorPaletteRed600Dark = "#dd534b";
export const njColorPaletteRed700Light = "#aa2424";
export const njColorPaletteRed700Dark = "#fa7e72";
export const njColorPaletteRed800Light = "#7f1d1b";
export const njColorPaletteRed800Dark = "#ffaea3";
export const njColorPaletteRed900Light = "#521814";
export const njColorPaletteRed900Dark = "#ffddd7";
export const njColorPaletteRedAlpha0Light = "transparent";
export const njColorPaletteRedAlpha0Dark = "transparent";
export const njColorPaletteRedAlpha100Light = "rgba(255, 55, 5, 0.04)";
export const njColorPaletteRedAlpha100Dark = "rgba(104, 16, 0, 0.35)";
export const njColorPaletteRedAlpha200Light = "rgba(255, 64, 19, 0.11)";
export const njColorPaletteRedAlpha200Dark = "rgba(197, 29, 1, 0.25)";
export const njColorPaletteRedAlpha300Light = "rgba(255, 48, 5, 0.28)";
export const njColorPaletteRedAlpha300Dark = "rgba(250, 51, 32, 0.34)";
export const njColorPaletteOrange100Light = "#fff5ed";
export const njColorPaletteOrange100Dark = "#2c1b12";
export const njColorPaletteOrange200Light = "#ffeada";
export const njColorPaletteOrange200Dark = "#372015";
export const njColorPaletteOrange300Light = "#ffc89d";
export const njColorPaletteOrange300Dark = "#542f1b";
export const njColorPaletteOrange400Light = "#ff8c47";
export const njColorPaletteOrange400Dark = "#7e4425";
export const njColorPaletteOrange500Light = "#d96929";
export const njColorPaletteOrange500Dark = "#a85a31";
export const njColorPaletteOrange600Light = "#bc5921";
export const njColorPaletteOrange600Dark = "#c3693a";
export const njColorPaletteOrange700Light = "#8e441a";
export const njColorPaletteOrange700Dark = "#f08750";
export const njColorPaletteOrange800Light = "#693315";
export const njColorPaletteOrange800Dark = "#ffb182";
export const njColorPaletteOrange900Light = "#442312";
export const njColorPaletteOrange900Dark = "#ffdfc8";
export const njColorPaletteOrangeAlpha0Light = "transparent";
export const njColorPaletteOrangeAlpha0Dark = "transparent";
export const njColorPaletteOrangeAlpha100Light = "rgba(255, 130, 30, 0.08)";
export const njColorPaletteOrangeAlpha100Dark = "rgba(82, 30, 0, 0.38)";
export const njColorPaletteOrangeAlpha200Light = "rgba(255, 115, 8, 0.15)";
export const njColorPaletteOrangeAlpha200Dark = "rgba(142, 50, 0, 0.28)";
export const njColorPaletteOrangeAlpha300Light = "rgba(255, 114, 4, 0.39)";
export const njColorPaletteOrangeAlpha300Dark = "rgba(254, 106, 22, 0.27)";
export const njColorPaletteYellow100Light = "#fff7e0";
export const njColorPaletteYellow100Dark = "#251e13";
export const njColorPaletteYellow200Light = "#ffeeb4";
export const njColorPaletteYellow200Dark = "#2c2416";
export const njColorPaletteYellow300Light = "#ffcc59";
export const njColorPaletteYellow300Dark = "#43361d";
export const njColorPaletteYellow400Light = "#d5a334";
export const njColorPaletteYellow400Dark = "#655027";
export const njColorPaletteYellow500Light = "#ab8327";
export const njColorPaletteYellow500Dark = "#866b32";
export const njColorPaletteYellow600Light = "#947023";
export const njColorPaletteYellow600Dark = "#9d7d39";
export const njColorPaletteYellow700Light = "#6f551d";
export const njColorPaletteYellow700Dark = "#c59d4a";
export const njColorPaletteYellow800Light = "#533f18";
export const njColorPaletteYellow800Dark = "#eabc5f";
export const njColorPaletteYellow900Light = "#362a13";
export const njColorPaletteYellow900Dark = "#ffe299";
export const njColorPaletteGreen100Light = "#f3faf4";
export const njColorPaletteGreen100Dark = "#102313";
export const njColorPaletteGreen200Light = "#e2f3e3";
export const njColorPaletteGreen200Dark = "#112b16";
export const njColorPaletteGreen300Light = "#aedfb3";
export const njColorPaletteGreen300Dark = "#12401e";
export const njColorPaletteGreen400Light = "#63be74";
export const njColorPaletteGreen400Dark = "#12602b";
export const njColorPaletteGreen500Light = "#199d47";
export const njColorPaletteGreen500Dark = "#1d7f3c";
export const njColorPaletteGreen600Light = "#008836";
export const njColorPaletteGreen600Dark = "#30934b";
export const njColorPaletteGreen700Light = "#006725";
export const njColorPaletteGreen700Dark = "#63b473";
export const njColorPaletteGreen800Light = "#004d1c";
export const njColorPaletteGreen800Dark = "#99d0a0";
export const njColorPaletteGreen900Light = "#0a3315";
export const njColorPaletteGreen900Dark = "#d4ebd6";
export const njColorPaletteGreenAlpha0Light = "transparent";
export const njColorPaletteGreenAlpha0Dark = "transparent";
export const njColorPaletteGreenAlpha100Light = "rgba(35, 155, 35, 0.05)";
export const njColorPaletteGreenAlpha100Dark = "rgba(7, 54, 0, 0.35)";
export const njColorPaletteGreenAlpha200Light = "rgba(13, 155, 22, 0.12)";
export const njColorPaletteGreenAlpha200Dark = "rgba(5, 97, 1, 0.25)";
export const njColorPaletteGreenAlpha300Light = "rgba(2, 155, 18, 0.32)";
export const njColorPaletteGreenAlpha300Dark = "rgba(3, 254, 35, 0.17)";
export const njColorPaletteBlue100Light = "#f6f8fd";
export const njColorPaletteBlue100Dark = "#111f2e";
export const njColorPaletteBlue200Light = "#e7eefc";
export const njColorPaletteBlue200Dark = "#12263b";
export const njColorPaletteBlue300Light = "#c0d4f9";
export const njColorPaletteBlue300Dark = "#0f3a5e";
export const njColorPaletteBlue400Light = "#7fadef";
export const njColorPaletteBlue400Dark = "#04568e";
export const njColorPaletteBlue500Light = "#3c8cde";
export const njColorPaletteBlue500Dark = "#1c73b6";
export const njColorPaletteBlue600Light = "#007acd";
export const njColorPaletteBlue600Dark = "#3d85ca";
export const njColorPaletteBlue700Light = "#005aa0";
export const njColorPaletteBlue700Dark = "#77a6e1";
export const njColorPaletteBlue800Light = "#004478";
export const njColorPaletteBlue800Dark = "#a9c4ee";
export const njColorPaletteBlue900Light = "#0b2d4e";
export const njColorPaletteBlue900Dark = "#dbe5f8";
export const njColorPaletteBlueAlpha0Light = "transparent";
export const njColorPaletteBlueAlpha0Dark = "transparent";
export const njColorPaletteBlueAlpha100Light = "rgba(30, 80, 205, 0.04)";
export const njColorPaletteBlueAlpha100Dark = "rgba(1, 55, 114, 0.2)";
export const njColorPaletteBlueAlpha200Light = "rgba(15, 85, 225, 0.1)";
export const njColorPaletteBlueAlpha200Dark = "rgba(1, 112, 229, 0.15)";
export const njColorPaletteBlueAlpha300Light = "rgba(3, 83, 231, 0.25)";
export const njColorPaletteBlueAlpha300Dark = "rgba(0, 139, 253, 0.29)";
export const njColorPaletteUltramarine100Light = "#f6f7fb";
export const njColorPaletteUltramarine100Dark = "#171d37";
export const njColorPaletteUltramarine200Light = "#eceef7";
export const njColorPaletteUltramarine200Dark = "#17224b";
export const njColorPaletteUltramarine300Light = "#ccd1e9";
export const njColorPaletteUltramarine300Dark = "#25346e";
export const njColorPaletteUltramarine400Light = "#9fa9d4";
export const njColorPaletteUltramarine400Dark = "#3f508e";
export const njColorPaletteUltramarine500Light = "#7986bf";
export const njColorPaletteUltramarine500Dark = "#5d6ca7";
export const njColorPaletteUltramarine600Light = "#6372b2";
export const njColorPaletteUltramarine600Dark = "#717fb5";
export const njColorPaletteUltramarine700Light = "#44549b";
export const njColorPaletteUltramarine700Dark = "#96a2cc";
export const njColorPaletteUltramarine800Light = "#2b3d83";
export const njColorPaletteUltramarine800Dark = "#bbc2df";
export const njColorPaletteUltramarine900Light = "#182663";
export const njColorPaletteUltramarine900Dark = "#e1e4f1";
export const njColorPalettePurple100Light = "#faf7fc";
export const njColorPalettePurple100Dark = "#201d22";
export const njColorPalettePurple200Light = "#f2ecf7";
export const njColorPalettePurple200Dark = "#28232c";
export const njColorPalettePurple300Light = "#deccec";
export const njColorPalettePurple300Dark = "#41324e";
export const njColorPalettePurple400Light = "#bf9ed9";
export const njColorPalettePurple400Dark = "#694288";
export const njColorPalettePurple500Light = "#a377c5";
export const njColorPalettePurple500Dark = "#875da9";
export const njColorPalettePurple600Light = "#9261b7";
export const njColorPalettePurple600Dark = "#9971b8";
export const njColorPalettePurple700Light = "#744299";
export const njColorPalettePurple700Dark = "#b697cf";
export const njColorPalettePurple800Light = "#533669";
export const njColorPalettePurple800Dark = "#d0bbe1";
export const njColorPalettePurple900Light = "#312838";
export const njColorPalettePurple900Dark = "#ebe1f2";
export const njColorPalettePurpleAlpha0Light = "transparent";
export const njColorPalettePurpleAlpha0Dark = "transparent";
export const njColorPalettePurpleAlpha100Light = "rgba(130, 55, 180, 0.04)";
export const njColorPalettePurpleAlpha100Dark = "rgba(241, 105, 129, 0.05)";
export const njColorPalettePurpleAlpha200Light = "rgba(93, 18, 155, 0.08)";
export const njColorPalettePurpleAlpha200Dark = "rgba(232, 136, 196, 0.09)";
export const njColorPalettePurpleAlpha300Light = "rgba(90, 0, 160, 0.20)";
export const njColorPalettePurpleAlpha300Dark = "rgba(221, 139, 252, 0.22)";
export const njColorPaletteTeal100Light = "#f1fafa";
export const njColorPaletteTeal100Dark = "#191f1f";
export const njColorPaletteTeal200Light = "#daf4f3";
export const njColorPaletteTeal200Dark = "#1d2727";
export const njColorPaletteTeal300Light = "#95dfdd";
export const njColorPaletteTeal300Dark = "#243c3b";
export const njColorPaletteTeal400Light = "#3dbcb8";
export const njColorPaletteTeal400Dark = "#275b59";
export const njColorPaletteTeal500Light = "#0a9994";
export const njColorPaletteTeal500Dark = "#297a77";
export const njColorPaletteTeal600Light = "#008480";
export const njColorPaletteTeal600Dark = "#328e8a";
export const njColorPaletteTeal700Light = "#0a6361";
export const njColorPaletteTeal700Dark = "#58b1ad";
export const njColorPaletteTeal800Light = "#154948";
export const njColorPaletteTeal800Dark = "#8bcfcb";
export const njColorPaletteTeal900Light = "#192f2f";
export const njColorPaletteTeal900Dark = "#ccebea";
export const njColorPaletteLime100Light = "#f8fad5";
export const njColorPaletteLime100Dark = "#1e2010";
export const njColorPaletteLime200Light = "#eef5a8";
export const njColorPaletteLime200Dark = "#242713";
export const njColorPaletteLime300Light = "#c7de43";
export const njColorPaletteLime300Dark = "#353b18";
export const njColorPaletteLime400Light = "#9db603";
export const njColorPaletteLime400Dark = "#4e581e";
export const njColorPaletteLime500Light = "#7d9200";
export const njColorPaletteLime500Dark = "#677525";
export const njColorPaletteLime600Light = "#6c7d02";
export const njColorPaletteLime600Dark = "#78892a";
export const njColorPaletteLime700Light = "#525f0a";
export const njColorPaletteLime700Dark = "#97ac37";
export const njColorPaletteLime800Light = "#3e460e";
export const njColorPaletteLime800Dark = "#b7cd4f";
export const njColorPaletteLime900Light = "#2a2e0e";
export const njColorPaletteLime900Dark = "#dfed90";
export const njColorPaletteEngieBlueLight = "#00AAFF";
export const njColorPaletteEngieBlueDark = "#00AAFF";
export const njColorPaletteEngieGreenLight = "#23d2b5";
export const njColorPaletteEngieGreenDark = "#23d2b5";
export const njColorTextPrimaryLight = "#171d21";
export const njColorTextPrimaryDark = "#fafafb";
export const njColorTextPrimaryHoveredLight = "#007acd";
export const njColorTextPrimaryHoveredDark = "#3d85ca";
export const njColorTextPrimaryFocusedLight = "#007acd";
export const njColorTextPrimaryFocusedDark = "#3d85ca";
export const njColorTextPrimaryPressedLight = "#005aa0";
export const njColorTextPrimaryPressedDark = "#77a6e1";
export const njColorTextSecondaryLight = "#334551";
export const njColorTextSecondaryDark = "#bcc4cb";
export const njColorTextTertiaryLight = "#60798b";
export const njColorTextTertiaryDark = "#718492";
export const njColorTextTertiaryHoveredLight = "#007acd";
export const njColorTextTertiaryHoveredDark = "#3d85ca";
export const njColorTextTertiaryFocusedLight = "#007acd";
export const njColorTextTertiaryFocusedDark = "#3d85ca";
export const njColorTextTertiaryPressedLight = "#005aa0";
export const njColorTextTertiaryPressedDark = "#77a6e1";
export const njColorTextInverseLight = "#ffffff";
export const njColorTextInverseDark = "#15191d";
export const njColorTextInverseHoveredLight = "#ebeef1";
export const njColorTextInverseHoveredDark = "#1f262b";
export const njColorTextInverseFocusedLight = "#ebeef1";
export const njColorTextInverseFocusedDark = "#1f262b";
export const njColorTextInversePressedLight = "#ccd4da";
export const njColorTextInversePressedDark = "#2e3942";
export const njColorTextBrandPrimaryLight = "#007acd";
export const njColorTextBrandPrimaryDark = "#3d85ca";
export const njColorTextBrandPrimaryHoveredLight = "#005aa0";
export const njColorTextBrandPrimaryHoveredDark = "#77a6e1";
export const njColorTextBrandPrimaryFocusedLight = "#005aa0";
export const njColorTextBrandPrimaryFocusedDark = "#77a6e1";
export const njColorTextBrandPrimaryPressedLight = "#004478";
export const njColorTextBrandPrimaryPressedDark = "#a9c4ee";
export const njColorTextBrandHighContrastLight = "#005aa0";
export const njColorTextBrandHighContrastDark = "#77a6e1";
export const njColorTextBrandHighContrastHoveredLight = "#004478";
export const njColorTextBrandHighContrastHoveredDark = "#a9c4ee";
export const njColorTextBrandHighContrastFocusedLight = "#004478";
export const njColorTextBrandHighContrastFocusedDark = "#a9c4ee";
export const njColorTextBrandHighContrastPressedLight = "#0b2d4e";
export const njColorTextBrandHighContrastPressedDark = "#dbe5f8";
export const njColorTextDangerPrimaryLight = "#db3735";
export const njColorTextDangerPrimaryDark = "#dd534b";
export const njColorTextDangerPrimaryHoveredLight = "#aa2424";
export const njColorTextDangerPrimaryHoveredDark = "#fa7e72";
export const njColorTextDangerPrimaryFocusedLight = "#aa2424";
export const njColorTextDangerPrimaryFocusedDark = "#fa7e72";
export const njColorTextDangerPrimaryPressedLight = "#7f1d1b";
export const njColorTextDangerPrimaryPressedDark = "#ffaea3";
export const njColorTextDangerSecondaryLight = "#7f1d1b";
export const njColorTextDangerSecondaryDark = "#ffaea3";
export const njColorTextDangerHighContrastLight = "#aa2424";
export const njColorTextDangerHighContrastDark = "#fa7e72";
export const njColorTextDangerHighContrastHoveredLight = "#7f1d1b";
export const njColorTextDangerHighContrastHoveredDark = "#ffaea3";
export const njColorTextDangerHighContrastFocusedLight = "#7f1d1b";
export const njColorTextDangerHighContrastFocusedDark = "#ffaea3";
export const njColorTextDangerHighContrastPressedLight = "#521814";
export const njColorTextDangerHighContrastPressedDark = "#ffddd7";
export const njColorTextWarningPrimaryLight = "#bc5921";
export const njColorTextWarningPrimaryDark = "#c3693a";
export const njColorTextWarningPrimaryHoveredLight = "#8e441a";
export const njColorTextWarningPrimaryHoveredDark = "#f08750";
export const njColorTextWarningPrimaryFocusedLight = "#8e441a";
export const njColorTextWarningPrimaryFocusedDark = "#f08750";
export const njColorTextWarningPrimaryPressedLight = "#693315";
export const njColorTextWarningPrimaryPressedDark = "#ffb182";
export const njColorTextWarningSecondaryLight = "#693315";
export const njColorTextWarningSecondaryDark = "#ffb182";
export const njColorTextWarningHighContrastLight = "#8e441a";
export const njColorTextWarningHighContrastDark = "#f08750";
export const njColorTextWarningHighContrastHoveredLight = "#693315";
export const njColorTextWarningHighContrastHoveredDark = "#ffb182";
export const njColorTextWarningHighContrastFocusedLight = "#693315";
export const njColorTextWarningHighContrastFocusedDark = "#ffb182";
export const njColorTextWarningHighContrastPressedLight = "#442312";
export const njColorTextWarningHighContrastPressedDark = "#ffdfc8";
export const njColorTextSuccessPrimaryLight = "#008836";
export const njColorTextSuccessPrimaryDark = "#30934b";
export const njColorTextSuccessPrimaryHoveredLight = "#006725";
export const njColorTextSuccessPrimaryHoveredDark = "#63b473";
export const njColorTextSuccessPrimaryFocusedLight = "#006725";
export const njColorTextSuccessPrimaryFocusedDark = "#63b473";
export const njColorTextSuccessPrimaryPressedLight = "#004d1c";
export const njColorTextSuccessPrimaryPressedDark = "#99d0a0";
export const njColorTextSuccessSecondaryLight = "#004d1c";
export const njColorTextSuccessSecondaryDark = "#99d0a0";
export const njColorTextSuccessHighContrastLight = "#006725";
export const njColorTextSuccessHighContrastDark = "#63b473";
export const njColorTextSuccessHighContrastHoveredLight = "#004d1c";
export const njColorTextSuccessHighContrastHoveredDark = "#99d0a0";
export const njColorTextSuccessHighContrastFocusedLight = "#004d1c";
export const njColorTextSuccessHighContrastFocusedDark = "#99d0a0";
export const njColorTextSuccessHighContrastPressedLight = "#0a3315";
export const njColorTextSuccessHighContrastPressedDark = "#d4ebd6";
export const njColorTextInformationPrimaryLight = "#007acd";
export const njColorTextInformationPrimaryDark = "#3d85ca";
export const njColorTextInformationPrimaryHoveredLight = "#005aa0";
export const njColorTextInformationPrimaryHoveredDark = "#77a6e1";
export const njColorTextInformationPrimaryFocusedLight = "#005aa0";
export const njColorTextInformationPrimaryFocusedDark = "#77a6e1";
export const njColorTextInformationPrimaryPressedLight = "#004478";
export const njColorTextInformationPrimaryPressedDark = "#a9c4ee";
export const njColorTextInformationSecondaryLight = "#004478";
export const njColorTextInformationSecondaryDark = "#a9c4ee";
export const njColorTextInformationHighContrastLight = "#005aa0";
export const njColorTextInformationHighContrastDark = "#77a6e1";
export const njColorTextInformationHighContrastHoveredLight = "#004478";
export const njColorTextInformationHighContrastHoveredDark = "#a9c4ee";
export const njColorTextInformationHighContrastFocusedLight = "#004478";
export const njColorTextInformationHighContrastFocusedDark = "#a9c4ee";
export const njColorTextInformationHighContrastPressedLight = "#0b2d4e";
export const njColorTextInformationHighContrastPressedDark = "#dbe5f8";
export const njColorTextDiscoveryPrimaryLight = "#9261b7";
export const njColorTextDiscoveryPrimaryDark = "#9971b8";
export const njColorTextDiscoveryPrimaryHoveredLight = "#744299";
export const njColorTextDiscoveryPrimaryHoveredDark = "#b697cf";
export const njColorTextDiscoveryPrimaryFocusedLight = "#744299";
export const njColorTextDiscoveryPrimaryFocusedDark = "#b697cf";
export const njColorTextDiscoveryPrimaryPressedLight = "#533669";
export const njColorTextDiscoveryPrimaryPressedDark = "#d0bbe1";
export const njColorTextDiscoverySecondaryLight = "#533669";
export const njColorTextDiscoverySecondaryDark = "#d0bbe1";
export const njColorTextDiscoveryHighContrastLight = "#744299";
export const njColorTextDiscoveryHighContrastDark = "#b697cf";
export const njColorTextDiscoveryHighContrastHoveredLight = "#533669";
export const njColorTextDiscoveryHighContrastHoveredDark = "#d0bbe1";
export const njColorTextDiscoveryHighContrastFocusedLight = "#533669";
export const njColorTextDiscoveryHighContrastFocusedDark = "#d0bbe1";
export const njColorTextDiscoveryHighContrastPressedLight = "#312838";
export const njColorTextDiscoveryHighContrastPressedDark = "#ebe1f2";
export const njColorTextPinkPrimaryLight = "#e01b78";
export const njColorTextPinkPrimaryDark = "#e44284";
export const njColorTextPinkPrimaryHoveredLight = "#963563";
export const njColorTextPinkPrimaryHoveredDark = "#f779ad";
export const njColorTextPinkPrimaryFocusedLight = "#963563";
export const njColorTextPinkPrimaryFocusedDark = "#f779ad";
export const njColorTextPinkPrimaryPressedLight = "#5c3749";
export const njColorTextPinkPrimaryPressedDark = "#fbaccc";
export const njColorTextRedPrimaryLight = "#db3735";
export const njColorTextRedPrimaryDark = "#dd534b";
export const njColorTextRedPrimaryHoveredLight = "#aa2424";
export const njColorTextRedPrimaryHoveredDark = "#fa7e72";
export const njColorTextRedPrimaryFocusedLight = "#aa2424";
export const njColorTextRedPrimaryFocusedDark = "#fa7e72";
export const njColorTextRedPrimaryPressedLight = "#7f1d1b";
export const njColorTextRedPrimaryPressedDark = "#ffaea3";
export const njColorTextOrangePrimaryLight = "#bc5921";
export const njColorTextOrangePrimaryDark = "#c3693a";
export const njColorTextOrangePrimaryHoveredLight = "#8e441a";
export const njColorTextOrangePrimaryHoveredDark = "#f08750";
export const njColorTextOrangePrimaryFocusedLight = "#8e441a";
export const njColorTextOrangePrimaryFocusedDark = "#f08750";
export const njColorTextOrangePrimaryPressedLight = "#693315";
export const njColorTextOrangePrimaryPressedDark = "#ffb182";
export const njColorTextYellowPrimaryLight = "#947023";
export const njColorTextYellowPrimaryDark = "#9d7d39";
export const njColorTextYellowPrimaryHoveredLight = "#6f551d";
export const njColorTextYellowPrimaryHoveredDark = "#c59d4a";
export const njColorTextYellowPrimaryFocusedLight = "#6f551d";
export const njColorTextYellowPrimaryFocusedDark = "#c59d4a";
export const njColorTextYellowPrimaryPressedLight = "#533f18";
export const njColorTextYellowPrimaryPressedDark = "#eabc5f";
export const njColorTextGreenPrimaryLight = "#008836";
export const njColorTextGreenPrimaryDark = "#30934b";
export const njColorTextGreenPrimaryHoveredLight = "#006725";
export const njColorTextGreenPrimaryHoveredDark = "#63b473";
export const njColorTextGreenPrimaryFocusedLight = "#006725";
export const njColorTextGreenPrimaryFocusedDark = "#63b473";
export const njColorTextGreenPrimaryPressedLight = "#004d1c";
export const njColorTextGreenPrimaryPressedDark = "#99d0a0";
export const njColorTextGreyPrimaryLight = "#60798b";
export const njColorTextGreyPrimaryDark = "#718492";
export const njColorTextGreyPrimaryHoveredLight = "#435c6e";
export const njColorTextGreyPrimaryHoveredDark = "#97a5af";
export const njColorTextGreyPrimaryFocusedLight = "#435c6e";
export const njColorTextGreyPrimaryFocusedDark = "#97a5af";
export const njColorTextGreyPrimaryPressedLight = "#334551";
export const njColorTextGreyPrimaryPressedDark = "#bcc4cb";
export const njColorTextBluePrimaryLight = "#007acd";
export const njColorTextBluePrimaryDark = "#3d85ca";
export const njColorTextBluePrimaryHoveredLight = "#005aa0";
export const njColorTextBluePrimaryHoveredDark = "#77a6e1";
export const njColorTextBluePrimaryFocusedLight = "#005aa0";
export const njColorTextBluePrimaryFocusedDark = "#77a6e1";
export const njColorTextBluePrimaryPressedLight = "#004478";
export const njColorTextBluePrimaryPressedDark = "#a9c4ee";
export const njColorTextUltramarinePrimaryLight = "#6372b2";
export const njColorTextUltramarinePrimaryDark = "#717fb5";
export const njColorTextUltramarinePrimaryHoveredLight = "#44549b";
export const njColorTextUltramarinePrimaryHoveredDark = "#96a2cc";
export const njColorTextUltramarinePrimaryFocusedLight = "#44549b";
export const njColorTextUltramarinePrimaryFocusedDark = "#96a2cc";
export const njColorTextUltramarinePrimaryPressedLight = "#2b3d83";
export const njColorTextUltramarinePrimaryPressedDark = "#bbc2df";
export const njColorTextPurplePrimaryLight = "#9261b7";
export const njColorTextPurplePrimaryDark = "#9971b8";
export const njColorTextPurplePrimaryHoveredLight = "#744299";
export const njColorTextPurplePrimaryHoveredDark = "#b697cf";
export const njColorTextPurplePrimaryFocusedLight = "#744299";
export const njColorTextPurplePrimaryFocusedDark = "#b697cf";
export const njColorTextPurplePrimaryPressedLight = "#533669";
export const njColorTextPurplePrimaryPressedDark = "#d0bbe1";
export const njColorTextTealPrimaryLight = "#008480";
export const njColorTextTealPrimaryDark = "#328e8a";
export const njColorTextTealPrimaryHoveredLight = "#0a6361";
export const njColorTextTealPrimaryHoveredDark = "#58b1ad";
export const njColorTextTealPrimaryFocusedLight = "#0a6361";
export const njColorTextTealPrimaryFocusedDark = "#58b1ad";
export const njColorTextTealPrimaryPressedLight = "#154948";
export const njColorTextTealPrimaryPressedDark = "#8bcfcb";
export const njColorTextLimePrimaryLight = "#6c7d02";
export const njColorTextLimePrimaryDark = "#78892a";
export const njColorTextLimePrimaryHoveredLight = "#525f0a";
export const njColorTextLimePrimaryHoveredDark = "#97ac37";
export const njColorTextLimePrimaryFocusedLight = "#525f0a";
export const njColorTextLimePrimaryFocusedDark = "#97ac37";
export const njColorTextLimePrimaryPressedLight = "#3e460e";
export const njColorTextLimePrimaryPressedDark = "#b7cd4f";
export const njOpacityDisabled = "0.35";
export const njComponentAvatarSizeSm = "2rem";
export const njComponentAvatarSizeBase = "3rem";
export const njComponentAvatarSizeLg = "4rem";
export const njComponentAvatarSizeXl = "6rem";
export const njComponentSkeletonColorBackgroundGradientLight = "linear-gradient(to right, transparent 0%, rgba(23, 29, 33, .05) 50%, transparent 100%)";
export const njComponentSkeletonColorBackgroundGradientDark = "linear-gradient(to right, transparent 0%, rgba(250, 250, 251, .05) 50%, transparent 100%)";
export const njElevationDropdown = "1000";
export const njElevationSticky = "1020";
export const njElevationFixed = "1030";
export const njElevationModalBackdrop = "1040";
export const njElevationModal = "1050";
export const njElevationPopover = "1060";
export const njElevationTooltip = "1070";
export const njElevationToastContainer = "1080";
export const njElevationToast = "1080";
export const njFontFamilySansSerif = "\"Lato\", -apple-system, \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\", \"Helvetica Neue\", \"Arial\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"";
export const njFontFamilyMonospace = "\"SFMono-Regular\", \"Menlo\", \"Monaco\", \"Consolas\", \"Liberation Mono\", \"Courier New\", monospace";
export const njFontFamilyDefault = "\"Lato\", -apple-system, \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\", \"Helvetica Neue\", \"Arial\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"";
export const njFontWeightLight = "300";
export const njFontWeightNormal = "400";
export const njFontWeightBold = "700";
export const njFontWeightBlack = "900";
export const njFontLineHeightDefault = "1.5";
export const njFontLineHeightSansSerif = "1.5";
export const njMotionBezierFastOutSlowIn = "cubic-bezier(.4, 0, .2, 1)";
export const njMotionBezierLinearOutSlowIn = "cubic-bezier(0, 0, .2, 1)";
export const njMotionBezierFastOutLinearIn = "cubic-bezier(.4, 0, 1, 1)";
export const njShadowLevel2DpLight = "0 1px 5px 0 rgba(0, 0, 0, .1), 0 2px 2px 0 rgba(0, 0, 0, .06), 0 3px 1px rgba(0, 0, 0, .06)";
export const njShadowLevel2DpDark = "0 3px 0 rgba(0, 0, 0, .06),  0 2px 2px rgba(0, 0, 0, .3),  0 1px 5px rgba(0, 0, 0, .3)";
export const njShadowLevel4DpLight = "0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 5px rgba(0, 0, 0, 0.06), 0 1px 10px rgba(0, 0, 0, 0.06)";
export const njShadowLevel4DpDark = "0 1px 10px rgba(0, 0, 0, .3),  0 4px 5px rgba(0, 0, 0, .3),  0 2px 4px rgba(0, 0, 0, .35)";
export const njShadowLevel6DpLight = "0 3px 5px rgba(0, 0, 0, 0.1), 0 6px 10px rgba(0, 0, 0, 0.06), 0 1px 18px rgba(0, 0, 0, 0.06)";
export const njShadowLevel6DpDark = "0 1px 18px rgba(0, 0, 0, .3),  0 6px 10px rgba(0, 0, 0, .3),  0 3px 5px rgba(0, 0, 0, .3)";
export const njShadowLevel8DpLight = "0 5px 5px rgba(0, 0, 0, 0.1), 0 8px 10px rgba(0, 0, 0, 0.06), 0 3px 14px rgba(0, 0, 0, 0.06)";
export const njShadowLevel8DpDark = "0 3px 14px rgba(0, 0, 0, .3),  0 8px 10px rgba(0, 0, 0, .3),  0 5px 5px rgba(0, 0, 0, .3)";
export const njShadowLevel16DpLight = "0 8px 10px rgba(0, 0, 0, 0.1), 0 16px 24px rgba(0, 0, 0, 0.06), 0 6px 30px rgba(0, 0, 0, 0.06)";
export const njShadowLevel16DpDark = "0 6px 30px rgba(0, 0, 0, .3),  0 16px 24px rgba(0, 0, 0, .3),  0 8px 10px rgba(0, 0, 0, .35)";
export const njShadowLevel24DpLight = "0 11px 15px rgba(0, 0, 0, 0.1), 0 24px 38px rgba(0, 0, 0, 0.06), 0 9px 46px rgba(0, 0, 0, 0.06)";
export const njShadowLevel24DpDark = "0 9px 46px rgba(0, 0, 0, .3),  0 24px 38px rgba(0, 0, 0, .3),  0 11px 15px rgba(0, 0, 0, .3)";
export const njSizeBorderWidth = "1px";
export const njSizeBorderRadiusMd = "0";
export const njSizeBorderRadiusSm = "0";
export const njSizeBorderFocusWidth = "2px";
export const njSizeBorderFocusOffset = "2px";
export const njSizeFontPeta = "4.75rem";
export const njSizeFontTera = "3.375rem";
export const njSizeFontGiga = "2.25rem";
export const njSizeFontMega = "1.75rem";
export const njSizeFontKilo = "1.5rem";
export const njSizeFontHecto = "1.25rem";
export const njSizeFontDeca = "1.125rem";
export const njSizeFontBase = "1rem";
export const njSizeFontDeci = ".875rem";
export const njSizeFontCenti = ".75rem";
export const njSizeIconSm = "16px";
export const njSizeIconMd = "24px";
export const njSizeIconLg = "32px";
export const njSizeIconXl = "48px";
export const njSizeIconXxl = "64px";
export const njSizeIcon3xl = "80px";
export const njSizeIcon4xl = "96px";
export const njSizeIcon5xl = "112px";
export const njSizeIcon6xl = "128px";
export const njSizeSpace2 = "0.125rem";
export const njSizeSpace4 = "0.25rem";
export const njSizeSpace8 = "0.5rem";
export const njSizeSpace12 = "0.75rem";
export const njSizeSpace16 = "1rem";
export const njSizeSpace20 = "1.25rem";
export const njSizeSpace24 = "1.5rem";
export const njSizeSpace32 = "2rem";
export const njSizeSpace48 = "3rem";
export const njSizeSpace64 = "4rem";
export const njSizeSpace80 = "5rem";
export const njSizeSpace96 = "6rem";
export const njSizeSpace112 = "7rem";