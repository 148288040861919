import './dsc-list-challenges.scss'
import { type FC, useEffect, useState } from 'react'
import { type ListChallenge, type ListChallenges } from 'types/datascience-challenge'
import { DSCBadge } from 'components/atoms/badge/dsc-badge'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import { checkDateChallenge } from 'utils/date'

interface DSCListChallengesProps {
  className?: string
  challenges: ListChallenges
}

const DSCListChallenges: FC<DSCListChallengesProps> = ({
  challenges
}: DSCListChallengesProps) => {
  return (
    <div className="dsc-container">
      <table className="dsc-list-challenges">
        <thead>
          <tr className="dsc-row">
            <th>Title</th>
            <th>Status</th>
            <th>start Date</th>
            <th>end Date</th>
            <th>link</th>
          </tr>
        </thead>
        <tbody>
          {challenges.data.map((challenge: ListChallenge, index: number) => {
            const [challengeStatus, setChallengeStatus] = useState<string>('')
            const [variant, setVariant] = useState<
              'danger' | 'warning' | 'success' | 'information' | 'discovery'
            >('success')
            useEffect(() => {
              setChallengeStatus(
                checkDateChallenge(
                  challenge.attributes.startDate,
                  challenge.attributes.endDate
                )
              )
            }, [])

            useEffect(() => {
              if (challengeStatus === 'Draft') {
                setVariant('discovery')
              } else if (challengeStatus === 'In Progress') {
                setVariant('information')
              }
            }, [challengeStatus])
            const pathToChallenge = `/datascience-challenge/${challenge.attributes.slug}`
            const linkClickable = (
              <Link to={pathToChallenge}>{challenge.attributes.slug}</Link>
            )

            const startDateFormatted = DateTime.fromJSDate(challenge.attributes.startDate)
              .setLocale('en-GB')
              .toFormat('MMMM dd, yyyy')

            const endDateFormatted = DateTime.fromJSDate(challenge.attributes.endDate)
              .setLocale('en-GB')
              .toFormat('MMMM dd, yyyy')

            return (
              <tr
                className="dsc-row"
                key={index}
                aria-disabled={challengeStatus !== 'In Progress'}
              >
                <td>{challenge.attributes.Title}</td>
                <td>
                  <DSCBadge
                    size="md"
                    emphasis="subtle"
                    label={challengeStatus === 'Draft' ? 'Incoming' : challengeStatus}
                    variant={variant}
                  ></DSCBadge>
                </td>
                <td>{startDateFormatted}</td>
                <td>{endDateFormatted}</td>
                <td>{linkClickable}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export { DSCListChallenges }
